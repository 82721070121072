import {
  CButtonClose,
  CCol,
  CRow,
  CToast,
  CToastBody,
  CToaster,
  CToastHeader,
} from "@coreui/react";
import { Button } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import usePushNotificationHandler from "../handler/PushNotificationHandler";
/** Utils */
export const PushNotificationContext = React.createContext({
  message: {},
  getListScheduleNotification:()=>{},
  listScheduleNotification:[],
  showToast: false,
  setShowToast: status => {},
  setListToast: listToast => {},
  listToast: [],
  createPush:(time,title,body,targetGroup,listDestinationUser)=>{},
  scheduleDetail:{},
  getScheduleNotificationDetail:id=>{},
  deleteScheduleNotification:id=>{}
});

const { Provider } = PushNotificationContext;

const PushNotificationProvider = ({ children }) => {
  const {
    listToast,
    message,
    showToast,
    setShowToast,
    setListToast,
    createPush,
    getListScheduleNotification,
    listScheduleNotification,
    getScheduleNotificationDetail,
    scheduleDetail,
    deleteScheduleNotification
  } = usePushNotificationHandler();

  const history = useHistory();
  return (
    <Provider
      value={{
        getScheduleNotificationDetail,
        scheduleDetail,
        getListScheduleNotification,
        listScheduleNotification,
        listToast,
        message,
        showToast,
        setShowToast,
        setListToast,
        createPush,
        deleteScheduleNotification
      }}
    >
      <CToaster position="top-right" key={"toaster"}>
        {listToast.map((e, i) => {
          let body = e.body.split(",");
          return (
            <Button
              onClick={event => {
                console.log(e.type);
                if (e.type == "adminbooking") {
                  try {
                    history.push(`/booking/`);
                  } catch (error) {
                    console.log(error);
                  }
                }
              }}
            >
              <CToast show={true} fade={true}>
                <CToastHeader closeButton={false}>
                  {e.title}
                  <CButtonClose
                    className="mfs-auto"
                    onClick={e => {
                      //remove array by index
                      let currentListToast = listToast.filter(function(
                        el,
                        index
                      ) {
                        return index !== i;
                      });
                      setListToast(currentListToast);
                    }}
                  />
                </CToastHeader>
                <CToastBody>
                  <CRow>
                    {body.map(e =>
                      <CCol xs="6" sm="6" md="6">
                        <p>
                          {e}
                        </p>
                      </CCol>
                    )}
                  </CRow>
                </CToastBody>
              </CToast>
            </Button>
          );
        })}
      </CToaster>

      {children}
    </Provider>
  );
};

export default PushNotificationProvider;
