import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
// sidebar nav config
import { UserContext } from "../logic/context/UserContext";

const TheSidebar = () => {
  const dispatch = useDispatch();
  const userCtx = React.useContext(UserContext);
  let listRoleData = userCtx.listRole;
  const show = useSelector((state) => state.sidebarShow);
  const [listRole, setListRole] = React.useState([]);
  const [navItemList, setnavItemList] = useState([]);

  //////Mock coupon tab
  useEffect(() => {
    if (listRoleData.role != null) {
      console.log(listRoleData);
      let roleData = [];
      let data = listRoleData.role.map((e) => {
        let test = {
          _tag: "CSidebarNavItem",
          name: e.name,
          to: "/usersrole/" + e.id + "/" + e.name,
        };
        roleData.push(test);
      });
      setListRole(roleData);
    }
  }, [listRoleData]);
  useEffect(() => {
    if (userCtx.isEnableDashBoardManagementLocal) {
      setnavItemList([
        {
          _tag: "CSidebarNavItem",
          name: "Dashboard",
          to: "/dashboard",
          icon: "cil-speedometer",
        },
      ]);
    }

    if (userCtx.isEnableUserManagementLocal) {
      setnavItemList([
        ...navItemList,
        {
          _tag: "CSidebarNavDropdown",
          name: "User Management",
          to: "/users",
          icon: "cil-user",
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "Role",
              to: "/role",
              icon: "cil-tags",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Create New User",
              to: "/adduser",
              icon: "cil-plus",
            },
          ],
        },

        {
          _tag: "CSidebarNavItem",
          name: "Customer",
          to: "/usersrole/5ff40883784493127aa71ef4/customer",
          icon: "cil-people",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Rider",
          to: "/usersrole/5ff53ab8edf90b05ae3f9520/rider",
          icon: "cil-truck",
        },
      ]);
    }
    if (userCtx.isEnableBookingManagementLocal) {
      setnavItemList([
        ...navItemList,
        {
          _tag: "CSidebarNavDropdown",
          name: "Booking",
          to: "/users",
          icon: "cil-book",
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "Pending",
              to: "/booking",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Assigned",
              to: "/booking/assigned",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Detail",
              to: "/booking/detail",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Customer Transaction",
              to: "/booking/history",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Rider Transaction",
              to: "/booking/ridertransaction",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Summary Transaction",
              to: "/booking/summarytransaction",
            },
          ],
        },
      ]);
    }
    if (userCtx.isEnableNotificationManagementLocal) {
      setnavItemList([
        ...navItemList,
        {
          _tag: "CSidebarNavItem",
          name: "Notification",
          to: "/notification",
          icon: "cil-alarm",
        },
      ]);
    }
    if (userCtx.isEnableAnnouncementManagementLocal) {
      setnavItemList([
        ...navItemList,
        {
          _tag: "CSidebarNavItem",
          name: "Announcement",
          to: "/announcements",
          icon: "cil-paperclip",
        },
      ]);
    }
    if (userCtx.isEnablePricingManagementLocal) {
      setnavItemList([
        ...navItemList,
        {
          _tag: "CSidebarNavItem",
          name: "Pricing",
          to: "/oils",
          icon: "cil-drop",
        },
      ]);
    }
    if (userCtx.isEnableSettingManagementLocal) {
      setnavItemList([
        ...navItemList,
        {
          _tag: "CSidebarNavItem",
          name: "Setting",
          to: "/settings",
          icon: "cil-settings",
        },
      ]);
    }

    if (userCtx.isEnableStationManagementLocal) {
      setnavItemList([
        ...navItemList,
        {
          _tag: "CSidebarNavItem",
          name: "Station",
          to: "/stations",
          icon: "cil-scrubber",
        },
      ]);
    }

    if (userCtx.isEnableStockManagementLocal) {
      setnavItemList([
        ...navItemList,
        {
          _tag: "CSidebarNavItem",
          name: "Stock",
          to: "/stocks",
          icon: "cil-truck",
        },
      ]);
    } 

    if (userCtx.isEnableCouponManagementLocal) {
      setnavItemList([
        ...navItemList,
        {
          _tag: "CSidebarNavDropdown",
          name: "Coupon",
          to: "/coupons",
          icon: "cil-book",
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "User Group",
              to: "/coupons/userGroup",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Coupon Condition",
              to: "/coupons/couponCondition",
            },

            {
              _tag: "CSidebarNavItem",
              name: "Template",
              to: "/coupons/template",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Coupons",
              to: "/coupons/coupons",
            },
           
             
            {
              _tag: "CSidebarNavItem",
              name: "Trigger",
              to: "/coupon/trigger",
            },
          
            {
              _tag: "CSidebarNavItem",
              name: "Campaign",
              to: "/coupon/campaign",
            },
          
            {
              _tag: "CSidebarNavItem",
              name: "Static",
              to: "/coupon/static",
            }
          ],
        },
      ]);
    }

    //////////////COUPON
  }, [
    userCtx.isEnableBookingManagementLocal,
    userCtx.isEnablePricingManagementLocal,
    userCtx.isEnableNotificationManagementLocal,
    userCtx.isEnableAnnouncementManagementLocal,
    userCtx.isEnableUserManagementLocal,
    userCtx.isEnableDashBoardManagementLocal,
    userCtx.isEnableCouponManagementLocal,
    userCtx.isEnableSettingManagementLocal,
    userCtx.isEnableStationManagementLocal,
    userCtx.isEnableStockManagementLocal,
  ]);

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
    >
      <CSidebarBrand style={{}} className="d-md-down-none" to="/">
        <CIcon
          className="c-sidebar-brand-full"
          name="logo-white"
          height={35}
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="mini-logo-white"
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navItemList}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"></CSidebarMinimizer>
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
