import firebase from "firebase/app";
import 'firebase/database';
import React, { useEffect } from "react"
import { DataStatusContext } from "../context/DataStatusContext";
import { DeleteMethod, GetMethodWithToken, PostMethodWithToken, PutMethod,  GetMethod} from "../service/NetworkCall"
const useBookingHandler = () => {
    const [marker, setMarker] = React.useState([]);
    const [trackingPendingOrder, setTrackingPendingOrder] = React.useState([]);
    const [pendingOrder, setPendingOrder] = React.useState([]);
    const [waitingOrder, setWaitingOrder] = React.useState([]);
    const [deliveringOrder, setDeliveringOrder] = React.useState([]);
    const [arrivedOrder, setArrivedOrder] = React.useState([]);
    const [paymentOrder, setPaymentOrder] = React.useState([]);
    const [completedOrder, setCompletedOrder] = React.useState([]);
    const [walkingOrder, setWalkingOrder] = React.useState([]);
    const [cancelOrder, setCancelOrder] = React.useState([]);
    const [rider, setrider] = React.useState([]);
    const [station, setstation] = React.useState([]);
    const [riderOffline, setRiderOffline] = React.useState([]);
    const [text, setText] = React.useState("");
    const [shouldOpenPopover, setShouldOpenPopover] = React.useState(false);
    const database = firebase.database();
    const dataStatusCtx = React.useContext(DataStatusContext);
    const [bookingDetail, setBookingDetailData] = React.useState("");
    const [provinces, setProvinces] = React.useState([]);
    const [gasTypeList, setGasTypeList] = React.useState([]);
    const [paymentTypeList, setPaymentList] = React.useState([]);
    const [amountTypeList, setAmountTypeList] = React.useState([]);
    const [booking, setBooking] = React.useState({});
    const [riders, setRiders] = React.useState([]);

    const status = ["pending",
        "waitingdriver",
        "delivering",
        "arrived",
        "payment",
        "complete",
        "cancel"
    ]
    useEffect(() => {
        database.ref("601c9c8525d7f057e8389a0f/601ca2d525d7f057e8389a12/status").on('value', function (snapshot) {
            setText(snapshot.val());
        });
        database.ref("rider/").on('value', function (snapshot) {
            let array = []
            snapshot.forEach(snap => {
                console.log(snap.val());
                array.push({
                    id: snap.key,
                    name: snap.val().name,
                    lat: snap.val().lat,
                    lng: snap.val().lng
                })
            })
            setrider(array);

        });

        database.ref("station/").on('value', function (snapshot) {
            let array = []
            snapshot.forEach(snap => {
                console.log(snap.val());
                array.push({
                    id: snap.key,
                    name: snap.val().name,
                    lat: snap.val().lat,
                    lng: snap.val().lng
                })
            })
            setstation(array);

        });
        // Jo1: i don't know this line use for
        // status.forEach((status) => fetchBookingBystatus(status));

        database.ref("pending/").on('value', function (snapshot) {
            let array = []
            snapshot.forEach(snap => {
                array.push({
                    id: snap.key,
                    name: snap.val().name,
                    lat: snap.val().lat,
                    lng: snap.val().lng
                })
            })
            setTrackingPendingOrder(array);
        });
        // setPendingOrder(data.pending);
    }, [])

    const fetchBookingBystatus = async (status, param = "") => {
        let bookingData = await apiCallHandler(GetMethodWithToken.bind(this, '/bookings/getBookingByStatus/' + status + param +"&_limit=-1"));
        switch (status) {
            case 'pending':
                setPendingOrder(bookingData.data);
                break;
            case 'waitingdriver':
                setWaitingOrder(bookingData.data);
                break;
            case 'delivering':
                setDeliveringOrder(bookingData.data);
                break;
            case 'arrived':
                setArrivedOrder(bookingData.data);
                break;
            case 'payment':
                setPaymentOrder(bookingData.data);
                break;
            case 'complete':
                setCompletedOrder(bookingData.data);
                break;
            case 'cancel':
                setCancelOrder(bookingData.data);
                break;
            case 'walking':
                setWalkingOrder(bookingData.data);
                break;
            default:
                break;
        }
    }

    const fetchRider = async () => {
        let userData = await apiCallHandler(GetMethodWithToken.bind(this, '/users?_sort=createdAt:ASC&role.id=5ff53ab8edf90b05ae3f9520&_limit=-1'));
        let user = userData.data.user.filter(user => user != null);
        setRiderOffline(user);
    }

    async function apiCallHandler(apicall) {
        try {
            dataStatusCtx.setLoading(true);
            let apiResponse = await apicall();
            dataStatusCtx.setLoading(false);
            return apiResponse;
        } catch (e) {
            console.log(e);
            // dataStatusCtx.setPopupText(e.response.statusText == undefined ? "cant connect to server" : e.response.statusText);
            dataStatusCtx.setLoading(false);
            dataStatusCtx.setShouldShowPopup(true);
            throw e;
        }
    }

    const assignRider = async (bookingId, riderId) => {
        let body = {
            rider: riderId
        }
        let bookingData = await apiCallHandler(PutMethod.bind(this, '/assignRider/' + bookingId, body));
        setShouldOpenPopover(false)
    }

    const cancelBooking = async (bookingId, reason) => {
        let body = {
            reason: reason
        }
        let cancelOrder = await apiCallHandler(PutMethod.bind(this, '/cancelBooking/' + bookingId, body));
    }

    const notifyLateBooking = async (bookingId, userId) => {
        let body = {
            bookingId: bookingId,
            user: userId
        }
        let bookingData = await apiCallHandler(PostMethodWithToken.bind(this, '/bookings/notifyLateBooking', body));
    }

    const updateBookingDetail = async (booking) => {
        let body = {
            booking,
        }
        return await apiCallHandler(PutMethod.bind(this, '/bookings/' + booking.id, body))
        .then(() => {
            dataStatusCtx.setShouldShowPopup(true);
        })
    }

    const fetchBookingDetail = async (id) => {
        let bookingData = await apiCallHandler(
          GetMethodWithToken.bind(this, "/bookings/" + id)
        );
        setBookingDetailData(bookingData.data);
    };
    
    async function fetchProvinces() {
        let provinceData = await apiCallHandler(GetMethod.bind(this, "/provinces"));
        let data = provinceData.data;
        setProvinces(data);
    }
    async function fetchGasTypeList() {
        let gastypeData = await apiCallHandler(GetMethod.bind(this, "/oiltypes"));
        let data = gastypeData.data;
        setGasTypeList(data);
    }

    async function fetchPaymentTypeList() {
        let paymentData = await apiCallHandler(GetMethod.bind(this, "/payments"));
        let data = paymentData.data;
        setPaymentList(data);
    }

    async function fetchAmountTypeList() {
        let amountTypeData = await apiCallHandler(GetMethod.bind(this, "/amount-types"));
        let data = amountTypeData.data;
        setAmountTypeList(data);
    }

    const getBooking = async (booking_id) => {
        let response = await apiCallHandler(GetMethodWithToken.bind(this, '/bookings/' + booking_id ));
        setBooking(response.data);
    }

    const getRiders = async () => {
        let response = await apiCallHandler(GetMethodWithToken.bind(this, '/users?_sort=createdAt:ASC&role.id=5ff53ab8edf90b05ae3f9520&_limit=-1'));
        var riders = []
        response.data.user.forEach((value) => {
            riders.push({
                id: value.id,
                username: value.username
            });
        });
        setRiders(riders);
        
    }

    return {
        marker,
        text,
        rider,
        station,
        assignRider,
        cancelBooking,
        riderOffline,
        setRiderOffline,
        trackingPendingOrder,
        pendingOrder,
        waitingOrder,
        deliveringOrder,
        arrivedOrder,
        paymentOrder,
        completedOrder,
        cancelOrder,
        shouldOpenPopover,
        fetchBookingBystatus,
        fetchRider,
        notifyLateBooking,
        setShouldOpenPopover,
        walkingOrder,
        updateBookingDetail,
        bookingDetail,
        fetchBookingDetail,
        fetchProvinces,
        provinces,
        fetchGasTypeList,
        gasTypeList,
        fetchPaymentTypeList,
        paymentTypeList,
        fetchAmountTypeList,
        amountTypeList,
        booking,
        getBooking,
        setBooking,

        riders,
        getRiders,
        setRiders,
    };
};

export default useBookingHandler;
