import { CButton } from "@coreui/react";
import React, { useState } from "react";
import OTPField from 'react-simple-otp'


function Otp() {
    const [otp, setotp] = useState("");
    return (
        <div>
            <br></br>
            <OTPField
                inputFieldClassName="mr-3 h1"
                value={otp}
                length={6}
                inputStyle={{
                    outline: 'none',
                    width: "50%",
                    height: "50%",
                    textAlign: "center"
                }}
                onSubmit={(value) => {
                    console.clear()
                    console.log(value)
                }}
                clearAllButton={'Clear'}
                clearAllButtonStyle={{ border: '2px solid' }}
                autoFocus={true}
                onChange={(value) => {
                    console.log(value)
                    setotp(value)
                }}
            />
            <br></br>

            <div style={{
                margin: "auto",
                width: "50%",
            }}>
                <CButton
                    style={{
                        width: "90%",
                        padding: "10px",
                    }}
                    type="button"
                    color="info"
                    onClick={(event) => {
                        console.log(`otp: ${otp}`);
                    }}
                >
                    Confirm
                </CButton>
            </div>
        </div >

    );
}

export default Otp