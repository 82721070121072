// @ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import { version } from "../../../../package.json";
import Countdown from "react-countdown";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardImg,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CLabel,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { AuthenticatorContext } from "../../../logic/context/AuthenticatorContext";
import { useHistory } from "react-router-dom";
import CustomDialog from "src/utils/custom_dialog";
import Otp from "./confirm_otp";
import MobileVerify from "./mobile_verify";
import OTPField from "react-simple-otp";
import { bool } from "prop-types";

const Login = () => {
  const authenticatorCtx = React.useContext(AuthenticatorContext);
  const history = useHistory();
  const [mobile, setMobile] = useState("");
  const [otp, setotp] = useState("");
  const [password, setPassword] = useState("");
  const [statusReset, setStatusReset] = useState(false);
  const [selectedRole, setSelectedRole] = useState({ id: null, name: null });
  const [warningString, setWarningString] = useState("");
  const childRef = useRef();
  useEffect(() => {
  }, [selectedRole]);
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>

              <CCard className="p-4" style={{borderRight:'0px'}}>
                <CCardBody>
                  <CRow style={{paddingBottom:'15px'}} className="justify-content-center">
                    <CIcon
                      className="c-sidebar-brand-full"
                      name="logo-negative"
                      height={75}
                    />
                  </CRow>

                  <CForm>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        onChange={(e) => {
                          authenticatorCtx.setUsername(e.target.value);
                        }}
                        type="text"
                        placeholder="Email or phone number"
                        autoComplete="Email"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        onChange={(e) => {
                          authenticatorCtx.setPassword(e.target.value);
                        }}
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <div>
                        <CDropdown className="mt-2">
                          <CDropdownToggle caret color="info">
                            {authenticatorCtx.selectedRole.name != null
                              ? authenticatorCtx.selectedRole.name
                              : "Select Role"}
                          </CDropdownToggle>
                          <CDropdownMenu>
                            {authenticatorCtx.listRole
                              .filter(
                                (data) => data.id !== "5ff53ab8edf90b05ae3f9520"
                              )
                              .filter(
                                (data) => data.id !== "5ff40883784493127aa71ef5"
                              )
                              .filter(
                                (data) => data.id !== "5ff40883784493127aa71ef4"
                            )
                              .filter(
                                (data) => data.enable == true
                              )
                              .map((e) => (
                                <CDropdownItem
                                  onClick={(event) =>
                                    authenticatorCtx.setSelectedRole({
                                      name: e.name,
                                      id: e.id,
                                    })
                                  }
                                >
                                  {e.name}
                                </CDropdownItem>
                              ))}
                          </CDropdownMenu>
                        </CDropdown>
                      </div>
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton
                          color="primary"
                          onClick={(e) => {
                            authenticatorCtx.setTwiloOpt({
                              otp: "",
                              ref: "",
                              cooldown: "",
                            });
                            setPassword("");
                            setStatusReset(false);
                            setMobile("");
                            authenticatorCtx.onSubmitLogin();
                          }}
                          className="px-4"
                        >
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CustomDialog
                          modalClassName="px-0"
                          ref={childRef}
                          modalButtonColor="link"
                          dialogTitle={
                            statusReset
                              ? "Set new password"
                              : authenticatorCtx.TwiloOpt.otp == ""
                              ? `mobile verify`
                              : "OTP - Ref by: " + authenticatorCtx.TwiloOpt.ref
                          }
                          modalButtonText="Forgot password?"
                          dialogContent={
                            <div className="text-left">
                              {statusReset ? (
                                <>
                                  <CFormGroup>
                                    <CInput
                                      value={password}
                                      placeholder="Enter new password"
                                      onChange={(value) => {
                                        setPassword(value.currentTarget.value);
                                      }}
                                      required
                                    />
                                  </CFormGroup>
                                  <CRow>
                                    <CCol>
                                      <CButton
                                        style={{
                                          width: "100%",
                                          padding: "8px",
                                        }}
                                        type="button"
                                        color="info"
                                        onClick={async (event) => {
                                          let response =
                                          await authenticatorCtx.changePassword(
                                            mobile,
                                            password,
                                            authenticatorCtx.role.id,
                                          );
                                        if (response?.data.ok) {
                                          childRef.current.dismiss();
                                          setPassword("");
                                          setStatusReset(false);
                                          alert(
                                            "you just change password's " +
                                              mobile
                                          );
                                          setMobile("");
                                        }
                                        
                                        }}
                                      >
                                        Confirm
                                      </CButton>
                                    </CCol>
                                    <CCol>
                                      <CButton
                                        style={{
                                          width: "100%",
                                          padding: "8px",
                                        }}
                                        type="button"
                                        color="danger"
                                        onClick={async (event) => {
                                          setMobile("");
                                          setPassword("");
                                          setStatusReset(false);
                                          childRef.current.dismiss();
                                        }}
                                      >
                                        Cancel
                                      </CButton>
                                    </CCol>
                                  </CRow>
                                </>
                              ) : authenticatorCtx.TwiloOpt.otp == "" ? (
                                <>
                                  <CFormGroup>
                                    <CInput
                                      value={mobile}
                                      placeholder="Enter your phone number"
                                      onChange={(value) => {
                                        setMobile(value.currentTarget.value);
                                      }}
                                    />
                                    <span className="text-danger ml-1">
                                      Example: 77777777{" "}
                                    </span>
                                  </CFormGroup>
                                  <CInputGroup className="mb-4">
                      <div>
                       <CFormGroup className="mb-0">
                       <CDropdown className="mt-2">
                          <CDropdownToggle caret color="info">
                            {authenticatorCtx.role.name != null
                              ? authenticatorCtx.role.name
                              : "Select Role"}
                          </CDropdownToggle>
                          <CDropdownMenu>
                            {authenticatorCtx.listRole
                              .filter(
                                (data) => data.id !== "5ff53ab8edf90b05ae3f9520"
                              )
                              .filter(
                                (data) => data.id !== "5ff40883784493127aa71ef5"
                              )
                              .filter(
                                (data) => data.id !== "5ff40883784493127aa71ef4"
                            )
                              .filter(
                                (data) => data.enable == true
                              )
                              .map((e) => (
                                <CDropdownItem
                                  onClick={(event) =>
                                    authenticatorCtx.setRole({
                                      name: e.name,
                                      id: e.id,
                                    })
                                  }
                                >
                                  {e.name}
                                </CDropdownItem>
                              ))}
                          </CDropdownMenu>
                        </CDropdown>
                        <CLabel className="text-danger ml-1">Select role</CLabel>
                       </CFormGroup>
                      </div>
                    </CInputGroup>
                                  <CButton
                                    style={{
                                      width: "100%",
                                      padding: "8px",
                                    }}
                                    type="button"
                                    color="info"
                                    onClick={(event) => {
                                      if (authenticatorCtx.role.id != null && mobile != ""){
                                        authenticatorCtx.vertifyOtp(mobile);
                                      }
                                    }}
                                  >
                                    Confirm
                                  </CButton>
                                </>
                              ) : (
                                <div>
                                  <p className="text-danger">
                                    {"expired time: "}
                                    <Countdown
                                      date={authenticatorCtx.TwiloOpt.cooldown}
                                      onComplete={() => {
                                        window.location.reload();
                                      }}
                                    />
                                  </p>

                                  <OTPField
                                    inputFieldClassName="mr-3 h1"
                                    value={otp}
                                    length={6}
                                    inputStyle={{
                                      outline: "none",
                                      width: "50%",
                                      height: "50%",
                                      textAlign: "center",
                                    }}
                                    onSubmit={(value) => {
                                    }}
                                    clearAllButton={"Clear"}
                                    clearAllButtonStyle={{
                                      border: "2px solid",
                                    }}
                                    autoFocus={true}
                                    onChange={(value) => {
                                      setotp(value);
                                    }}
                                  />
                                  <br></br>

                                  <div
                                    style={{
                                      margin: "auto",
                                      width: "50%",
                                    }}
                                  >
                                    <CButton
                                      style={{
                                        width: "90%",
                                        padding: "10px",
                                      }}
                                      type="button"
                                      color="info"
                                      onClick={(event) => {
                                        if (
                                          authenticatorCtx.TwiloOpt.otp == otp
                                        ) {
                                          setStatusReset(true);
                                          authenticatorCtx.setTwiloOpt({
                                            otp: "",
                                            ref: "",
                                            cooldown: "",
                                          });
                                        } else {
                                          setotp("");
                                          alert("error");
                                        }
                                      }}
                                    >
                                      Confirm
                                    </CButton>
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                          enableActionFooter={false}
                        />
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <br></br>
        <CRow className="justify-content-center">
          {authenticatorCtx.TwiloOpt.cooldown != "" ? (
            <>
              <p className="text-danger h5">
                Expired OTP:{" "}
                <Countdown
                  date={authenticatorCtx.TwiloOpt.cooldown}
                  onComplete={() => {
                    window.location.reload();
                  }}
                />
              </p>
            </>
          ) : (
            <></>
          )}
        </CRow>
        <CRow className="justify-content-center">
          {authenticatorCtx.TwiloOpt.cooldown != "" ? (
            <>
              <h6>if you refresh page, you have to request for OTP again</h6>
            </>
          ) : (
            <></>
          )}
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
