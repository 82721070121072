import React from "react";
import UserProvider from "./logic/context/UserContext";
import CouponStatic from "./views/coupons/couponStatic";
import OilDetail from "./views/oils/OilDetail";
const Toaster = React.lazy(() =>
  import("./views/notifications/toaster/Toaster")
);
const Tables = React.lazy(() => import("./views/base/tables/Tables"));

const Breadcrumbs = React.lazy(() =>
  import("./views/base/breadcrumbs/Breadcrumbs")
);
const Cards = React.lazy(() => import("./views/base/cards/Cards"));
const Carousels = React.lazy(() => import("./views/base/carousels/Carousels"));
const Collapses = React.lazy(() => import("./views/base/collapses/Collapses"));
const BasicForms = React.lazy(() => import("./views/base/forms/BasicForms"));

const Jumbotrons = React.lazy(() =>
  import("./views/base/jumbotrons/Jumbotrons")
);
const ListGroups = React.lazy(() =>
  import("./views/base/list-groups/ListGroups")
);
const Navbars = React.lazy(() => import("./views/base/navbars/Navbars"));
const Navs = React.lazy(() => import("./views/base/navs/Navs"));
const Paginations = React.lazy(() =>
  import("./views/base/paginations/Pagnations")
);
const Popovers = React.lazy(() => import("./views/base/popovers/Popovers"));
const ProgressBar = React.lazy(() =>
  import("./views/base/progress-bar/ProgressBar")
);
const Switches = React.lazy(() => import("./views/base/switches/Switches"));

const Tabs = React.lazy(() => import("./views/base/tabs/Tabs"));
const Tooltips = React.lazy(() => import("./views/base/tooltips/Tooltips"));
const BrandButtons = React.lazy(() =>
  import("./views/buttons/brand-buttons/BrandButtons")
);
const ButtonDropdowns = React.lazy(() =>
  import("./views/buttons/button-dropdowns/ButtonDropdowns")
);
const ButtonGroups = React.lazy(() =>
  import("./views/buttons/button-groups/ButtonGroups")
);
const Buttons = React.lazy(() => import("./views/buttons/buttons/Buttons"));
const Charts = React.lazy(() => import("./views/charts/Charts"));
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const CoreUIIcons = React.lazy(() =>
  import("./views/icons/coreui-icons/CoreUIIcons")
);
const Flags = React.lazy(() => import("./views/icons/flags/Flags"));
const Brands = React.lazy(() => import("./views/icons/brands/Brands"));
const Alerts = React.lazy(() => import("./views/notifications/alerts/Alerts"));
const Badges = React.lazy(() => import("./views/notifications/badges/Badges"));
const Oils = React.lazy(() => import("./views/oils/Oils"));
const Settings = React.lazy(() => import("./views/settings/Settings"));
const CreateSetting = React.lazy(() => import("./views/settings/create"));
const EditSetting = React.lazy(() => import("./views/settings/edit"));


const Stations = React.lazy(() => import("./views/stations/Stations"));
const CreateStation = React.lazy(() => import("./views/stations/create"));
const EditStation = React.lazy(() => import("./views/stations/edit"));

const Stocks = React.lazy(() => import("./views/stocks/Stocks"));
const CreateStock = React.lazy(() => import("./views/stocks/create"));
const EditStock = React.lazy(() => import("./views/stocks/edit"));

const OilDetails = React.lazy(() => import("./views/oils/OilDetail"));
const ProvinceDetail = React.lazy(() => import("./views/oils/ProvinceDetail"));
const AddNewUser = React.lazy(() => import("./views/users/AddNewUser"));
const Modals = React.lazy(() => import("./views/notifications/modals/Modals"));
const Announcement = React.lazy(() =>import("./views/announcements/Announcement"));
const CreateAnnouncement = React.lazy(() => import("./views/announcements/create"));
const EditAnnouncement = React.lazy(() => import("./views/announcements/edit"));

const Colors = React.lazy(() => import("./views/theme/colors/Colors"));
const Typography = React.lazy(() =>
  import("./views/theme/typography/Typography")
);
const Widgets = React.lazy(() => import("./views/widgets/Widgets"));
const UserRole = React.lazy(() => import("./views/users/UserRole"));
const UserRoleHistory = React.lazy(() =>
  import("./views/users/UserRoleHistory")
);
//////ADD
const UserRoleCarDetail = React.lazy(() =>
  import("./views/users/UserRoleCarDetail")
);
/////////
const User = React.lazy(() => import("./views/users/User"));
const Role = React.lazy(() => import("./views/users/Role"));
const RoleDetail = React.lazy(() => import("./views/users/RoleDetail"));
const Booking = React.lazy(() => import("./views/booking/booking"));
const BookingDetail = React.lazy(() => import("./views/booking/bookingDetail"));
const BookingHistory = React.lazy(() =>
  import("./views/booking/bookingHistory")
);
const BookingAssigned = React.lazy(() => import("./views/booking/bookingAssigned"));

const Notification = React.lazy(() =>
  import("./views/base/notification/notification")
);
const NotificationDetail = React.lazy(() =>
  import("./views/base/notification/NotificationDetail")
);
const otp = React.lazy(() => import("./views/pages/login/confirm_otp"));
const BookingTransactionDetail = React.lazy(() =>
  import("./views/booking/BookingTransactionDetail")
);
const EditBookingTransactionDetail = React.lazy(() =>
  import("./views/booking/EditBookingTransactionDetail")
);
const BookingRiderTransaction = React.lazy(() =>
  import("./views/booking/bookingRiderTransaction")
);
const SummaryBookingTransaction = React.lazy(() =>
  import("./views/booking/SummaryBookingTransaction")
);

const UserGroup = React.lazy(() => import("./views/coupons/userGroup"));
const Template = React.lazy(() => import("./views/coupons/template"));
const Coupon = React.lazy(() => import("./views/coupons/coupons"));
const CouponCondition = React.lazy(() =>
  import("./views/coupons/couponCondition")
);
const UserGroupDetail = React.lazy(() =>
  import("./views/coupons/userGroupDetail")
);
const CouponConditionDetail = React.lazy(() =>
  import("./views/coupons/couponConditionDetail")
);

const TemplateDetail = React.lazy(() =>
  import("./views/coupons/templateDetail")
);

const CouponDetail = React.lazy(() => import("./views/coupons/couponDetail"));

const JoinGroup = React.lazy(() => import("./views/coupons/joinGroup"));

const Campaign = React.lazy(() => import("./views/coupons/campaign"));
const CampaignDetail = React.lazy(() =>
  import("./views/coupons/campaignDetail")
);
const Trigger = React.lazy(() => import("./views/coupons/trigger"));
const TriggerDetail = React.lazy(() => import("./views/coupons/triggerDetail"));

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/role", exact: true, name: "Role", component: Role },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/theme", name: "Theme", component: Colors, exact: true },
  { path: "/theme/colors", name: "Colors", component: Colors },
  { path: "/theme/typography", name: "Typography", component: Typography },
  { path: "/base", name: "Base", component: Cards, exact: true },
  { path: "/base/breadcrumbs", name: "Breadcrumbs", component: Breadcrumbs },
  { path: "/base/cards", name: "Cards", component: Cards },
  { path: "/base/carousels", name: "Carousel", component: Carousels },
  { path: "/base/collapses", name: "Collapse", component: Collapses },
  { path: "/base/forms", name: "Forms", component: BasicForms },
  { path: "/base/jumbotrons", name: "Jumbotrons", component: Jumbotrons },
  { path: "/base/list-groups", name: "List Groups", component: ListGroups },
  { path: "/base/navbars", name: "Navbars", component: Navbars },
  { path: "/base/navs", name: "Navs", component: Navs },
  { path: "/base/paginations", name: "Paginations", component: Paginations },
  { path: "/base/popovers", name: "Popovers", component: Popovers },
  { path: "/base/progress-bar", name: "Progress Bar", component: ProgressBar },
  { path: "/base/switches", name: "Switches", component: Switches },
  { path: "/base/tables", name: "Tables", component: Tables },
  { path: "/base/tabs", name: "Tabs", component: Tabs },
  { path: "/base/tooltips", name: "Tooltips", component: Tooltips },
  { path: "/buttons", name: "Buttons", component: Buttons, exact: true },
  { path: "/buttons/buttons", name: "Buttons", component: Buttons },
  {
    path: "/buttons/button-dropdowns",
    name: "Dropdowns",
    component: ButtonDropdowns,
  },
  {
    path: "/buttons/button-groups",
    name: "Button Groups",
    component: ButtonGroups,
  },
  {
    path: "/buttons/brand-buttons",
    name: "Brand Buttons",
    component: BrandButtons,
  },
  { path: "/charts", name: "Charts", component: Charts },
  { path: "/icons", exact: true, name: "Icons", component: CoreUIIcons },
  { path: "/icons/coreui-icons", name: "CoreUI Icons", component: CoreUIIcons },
  { path: "/icons/flags", name: "Flags", component: Flags },
  { path: "/icons/brands", name: "Brands", component: Brands },
  {
    path: "/notifications",
    name: "Notifications",
    component: Alerts,
    exact: true,
  },
  { path: "/notifications/alerts", name: "Alerts", component: Alerts },
  { path: "/notifications/badges", name: "Badges", component: Badges },
  { path: "/notifications/modals", name: "Modals", component: Modals },
  { path: "/notifications/toaster", name: "Toaster", component: Toaster },
  { path: "/widgets", name: "Widgets", component: Widgets },
  {
    path: "/usersrole/:id/:name",
    exact: true,
    name: "Users Role",
    component: UserRole,
  },
  {
    path: "/users/:id/:nid",
    exact: true,
    name: "User Details",
    component: User,
  },
  {
    path: "/usershistory/:role/:id/:nid",
    exact: true,
    name: "History",
    component: UserRoleHistory,
  },

  {
    path: "/userscardetail/:role/:id",
    exact: true,
    name: "Car Detail",
    component: UserRoleCarDetail,
  },

  {
    path: "/role/:id",
    exact: true,
    name: "Role Details",
    component: RoleDetail,
  },
  { path: "/booking", exact: true, name: "Booking", component: Booking },
  {
    path: "/booking/detail",
    exact: true,
    name: "Booking Details",
    component: BookingDetail,
  },
  {
    path: "/booking/assigned",
    exact: true,
    name: "Booking Assigned",
    component: BookingAssigned,
  },
  {
    path: "/booking/history",
    exact: true,
    name: "Customer Transaction",
    component: BookingHistory,
  },
  {
    path: "/booking/ridertransaction",
    exact: true,
    name: "Rider Transaction",
    component: BookingRiderTransaction,
  },
  {
    path: "/booking/summarytransaction",
    exact: true,
    name: "Summary Transaction",
    component: SummaryBookingTransaction,
  },
  {
    path: "/bookingtransactiondetail/:id",
    exact: true,
    name: "Booking Transaction Detail",
    component: BookingTransactionDetail,
  },
  {
    path: "/bookingtransactiondetail/:id/edit",
    exact: true,
    name: "Edit Booking Transaction Detail",
    component: EditBookingTransactionDetail,
  },
  {
    path: "/adduser",
    exact: true,
    name: "Add New User",
    component: AddNewUser,
  },
  {
    path: "/notification",
    exact: true,
    name: "Notification",
    component: Notification,
  },
  {
    path: "/notification/detail/:id",
    exact: true,
    name: "Notification Detail",
    component: NotificationDetail,
  },
  { path: "/oils", exact: true, name: "Price of gas", component: Oils },
  { path: "/settings", exact: true, name: "Setting", component: Settings },
  { path: "/settings/create", exact: true, name: "Create Setting", component: CreateSetting },
  {
    path: "/settings/:id/edit",
    exact: true,
    name: "Edit Setting",
    component: EditSetting,
  },
  { path: "/oils", exact: true, name: "Price of gas", component: Oils },
  { path: "/stations", exact: true, name: "Station", component: Stations },
  { path: "/stations/create", exact: true, name: "Create Station", component: CreateStation },
  {
    path: "/stations/:id/edit",
    exact: true,
    name: "Edit Station",
    component: EditStation,
  },
  { path: "/stocks", exact: true, name: "Stock", component: Stocks },
  { path: "/stocks/create", exact: true, name: "Create Stock", component: CreateStock },
  {
    path: "/stocks/:id/edit",
    exact: true,
    name: "Edit Stock",
    component: EditStock,
  },
  { path: "/announcements", exact: true, name: "Announcement", component: Announcement },
  { path: "/announcements/create", exact: true, name: "Create Announcement", component: CreateAnnouncement },
  {
    path: "/announcements/:id/edit",
    exact: true,
    name: "Edit Announcement",
    component: EditAnnouncement,
  },
  {
    path: "/oils/province/:id",
    exact: true,
    name: "List of gas in province",
    component: ProvinceDetail,
  },
  // { path: '/oils/:id', exact: true, name: 'Edit price of gas', component: OilDetails },
  { path: "/otp", exact: true, name: "confirm otp", component: otp },

  {
    path: "/coupons/coupons",
    exact: true,
    name: "Coupon",
    component: Coupon,
  },
  {
    path: "/coupons/userGroup",
    exact: true,
    name: "User Group",
    component: UserGroup,
  },
  {
    path: "/coupons/template",
    exact: true,
    name: "Template",
    component: Template,
  },
  {
    path: "/coupons/couponCondition",
    exact: true,
    name: "Coupon Condition",
    component: CouponCondition,
  },
  {
    path: "/coupons/userGroupDetail/:id",
    exact: true,
    name: "User Group Detail",
    component: UserGroupDetail,
  },
  {
    path: "/coupons/couponConditionDetail/:id",
    exact: true,
    name: "Coupon Condition Detail",
    component: CouponConditionDetail,
  },

  {
    path: "/coupons/templateDetail/:id",
    exact: true,
    name: "Template Detail",
    component: TemplateDetail,
  },

  {
    path: "/coupons/couponDetail/:id",
    exact: true,
    name: "Coupon Detail",
    component: CouponDetail,
  },

  {
    path: "/coupon/joingroup/:id",
    exact: true,
    name: "Join Group",
    component: JoinGroup,
  },

  {
    path: "/coupon/campaign",
    exact: true,
    name: "Campaign",
    component: Campaign,
  },

  {
    path: "/coupon/campaign/:id",
    exact: true,
    name: "Campaign Detail",
    component: CampaignDetail,
  },

  {
    path: "/coupon/trigger",
    exact: true,
    name: "Trigger",
    component: Trigger,
  },

  {
    path: "/coupon/trigger/:id",
    exact: true,
    name: "Trigger Detail",
    component: TriggerDetail,
  },

  {
    path: "/coupon/static",
    exact: true,
    name: "Static",
    component: CouponStatic,
  },
];

export default routes;
