export const BASE_URL = process.env.NODE_ENV === 'production' ? 
  (process.env.REACT_APP_BASE_URL || "https://ntp-service.com") 
  : 'http://localhost:1337';

const axios = require('axios');
export const postMethod = async (endpoint, body) => {
    try {
        let data = await axios.post(BASE_URL + endpoint, body, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return data;

    } catch (e) {
        throw e;
    }
}

export async function GetMethodWithToken(endpoint) {
    try {
        let token = await localStorage.getItem('token');
        let data = await axios.get(BASE_URL + endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        return data;
    } catch (e) {
        throw e;
    }
}

export async function GetMethod(endpoint) {
    try {
        let data = await axios.get(BASE_URL + endpoint)
        return data;
    } catch (e) {
        throw e;
    }
}
export async function PutMethod(endpoint, body) {
    try {
        let token = await localStorage.getItem('token');
        let data = await axios.put(BASE_URL + endpoint, body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        return data;
    } catch (e) {
        throw e;
    }
}
export const PostMethodWithToken = async (endpoint, body) => {
    try {
        let token = await localStorage.getItem('token');
        let data = await axios.post(BASE_URL + endpoint, body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        return data;

    } catch (e) {
        throw e;
    }
}
export const DeleteMethod = async (endpoint) => {
    try {
        let token = await localStorage.getItem('token');
        let data = await axios.delete(BASE_URL + endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        return data;

    } catch (e) {
        throw e;
    }
}
export const PostStreamMethodWithToken = async (endpoint, body) => {
    try {
        let token = await localStorage.getItem('token');
        let data = await axios.post(BASE_URL + endpoint, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            }
        })
        return data;
    } catch (e) {
        throw e;
    }
}
