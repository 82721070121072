import React, { useEffect } from "react";
import {
  DeleteMethod,
  GetMethodWithToken,
  PostMethodWithToken,
  PutMethod,
} from "../service/NetworkCall";
import { DataStatusContext } from "../../logic/context/DataStatusContext";
import { USER_ITEM_PER_PAGE } from "../../constants";
import UserPermissionsFormatter from "./UserPermissionFormatter";
import useBookingHandler from "./BookingHandler";
const customerRoleId = "5ff53ab8edf90b05ae3f9520";
const useUserHandler = () => {
  const [listUser, setListUser] = React.useState([]);
  const [listUserExcel, setListUserExcel] = React.useState([]);
  const [listRole, setListRole] = React.useState([]);
  const [listAllUser, setListAllUser] = React.useState([]);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
  const [email, setemail] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [phone, setphone] = React.useState("");
  const [username, setusername] = React.useState("");
  const [brand, setbrand] = React.useState("");
  const [plate, setplate] = React.useState("");
  const [color, setcolor] = React.useState("");
  const [nameRole, setNameRole] = React.useState("");
  const [descriptionRole, setDescriptionRole] = React.useState("");
  const [shouldShowRegisterModal, setshouldShowRegisterModal] =
    React.useState(false);
  const [shouldShowAddroleModal, setshouldShowAddroleModal] =
    React.useState(false);
  const [totalListCount, setTotalListCount] = React.useState(0);
  const [isEditUserData, setIsEditUserData] = React.useState(false);
  const dataStatusCtx = React.useContext(DataStatusContext);
  const [userDetail, setUserDetailData] = React.useState("");
  const [isEditUserCar, setIsEditUserCar] = React.useState({});
  const [roleDetail, setRoleDetail] = React.useState({});
  const [isEnableUserManagement, setIsEnableUserManagement] =
    React.useState(false);
  const [isEnableBookingManagement, setIsEnableBookingManagement] =
    React.useState(false);
  const [subBookingManagement, setSubBookingManagement] = React.useState({
    create: false,
    read: false,
    update: false,
  });
  const [isEnableNotificationManagement, setIsEnableNotificationManagement] =
    React.useState(false);
  const [subNotificationManagement, setSubNotificationManagement] =
    React.useState({
      create: false,
      read: false,
      delete: false,
    });
  const [isEnablePricingManagement, setIsEnablePricingManagement] =
    React.useState(false);
  const [isEnableCouponManagement, setIsEnableCouponManagement] =
    React.useState(false);
  const [isEnableTemplateManagement, setIsEnableTemplateManagement] =
    React.useState(false);
  const [
    isEnableCouponConditionManagement,
    setIsEnableCouponConditionManagement,
  ] = React.useState(false);
  const [
    isEnableCouponDiscountTypeManagement,
    setIsEnableCouponDiscountTypeManagement,
  ] = React.useState(false);
  const [isEnableUserGroupManagement, setIsEnableUserGroupManagement] =
    React.useState(false);

  const [isEnableOilTypeManagement, setIsEnableOilTypeManagement] =
    React.useState(false);
  const [isEnableProvinceManagement, setIsEnableProvinceManagement] =
    React.useState(false);

  const [isEnableJoinGroupManagement, setIsEnableJoinGroupManagement] =
    React.useState(false);

  const [isEnableCampaignManagement, setIsEnableCampaignManagement] =
    React.useState(false);
  const [isEnableTriggerManagement, setIsEnableTriggerManagement] =
    React.useState(false);

  const [subSettingManagement, setSubSettingManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [isEnableSettingManagement, setIsEnableSettingManagement] =
    React.useState(false);

  const [isEnableStationManagement, setIsEnableStationManagement] =
    React.useState(false);
  
  const [subStationManagement, setSubStationManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [subStockManagement, setSubStockManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [isEnableStockManagement, setIsEnableStockManagement] =
    React.useState(false);

  const [subAnnouncementManagement, setSubAnnouncementManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [isEnableAnnouncementManagement, setIsEnableAnnouncementManagement] =
    React.useState(false);
    
  const [subPricingManagement, setSubPricingManagement] = React.useState({
    read: false,
    update: false,
  });
  const [subCouponManagement, setSubCouponManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [listDeleteUser, setListDeleteUser] = React.useState([]);

  const [subCouponConditionManagement, setSubCouponConditionManagement] =
    React.useState({
      create: false,
      read: false,
      update: false,
      delete: false,
    });

  const [subCouponDiscountTypeManagement, setSubCouponDiscountTypeManagement] =
    React.useState({
      create: false,
      read: false,
      update: false,
      delete: false,
    });

  const [subUserGroupManagement, setSubUserGroupManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [subOilTypeManagement, setSubOilTypeManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [subProvinceManagement, setSubProvinceManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [subJoinGroupManagement, setSubJoinGroupManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [subTemplateManagement, setSubTemplateManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [subCampaignManagement, setSubCampaignManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [subTriggerManagement, setSubTriggerManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const [isEnableDashBoardManagement, setIsEnableDashBoardManagement] =
    React.useState(false);
  const [subDashBoardManagement, setSubDashBoardManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });
  //////////////////////////////
  const [subUserManagement, setSubUserManagement] = React.useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });
  //////////////////////////
  const [isEnableUserManagementLocal, setIsEnableUserManagementLocal] =
    React.useState(false);
  const [isEnableBookingManagementLocal, setIsEnableBookingManagementLocal] =
    React.useState(false);
  const [isEnableNotificationManagementLocal, setIsEnableNotificationManagementLocal] =
    React.useState(false);
  const [isEnableAnnouncementManagementLocal, setIsEnableAnnouncementManagementLocal] =
    React.useState(false);
  const [isEnablePricingManagementLocal, setIsEnablePricingManagementLocal] =
    React.useState(false);
  const [isEnableSettingManagementLocal, setIsEnableSettingManagementLocal] =
    React.useState(false);
  const [isEnableStationManagementLocal, setIsEnableStationManagementLocal] =
    React.useState(false);
  const [isEnableStockManagementLocal, setIsEnableStockManagementLocal] =
    React.useState(false);
  const [isEnableCouponManagementLocal, setIsEnableCouponManagementLocal] =
    React.useState(false);
  const [isEnableTemplateManagementLocal, setIsEnableTemplateManagementLocal] =
    React.useState(false);

  const [
    isEnableDashBoardManagementLocal,
    setIsEnableDashBoardManagementLocal,
  ] = React.useState(false);
  const [isZeroUserResponse, setIsZeroUserResponse] = React.useState(false);
  const [dailyUserStatic, setDailyUserStatic] = React.useState([]);
  const [dailyRiderStatic, setDailyRiderStatic] = React.useState([]);
  const [totalRider, setTotalRider] = React.useState(0);
  const [totalUser, setTotalUser] = React.useState(0);
  const [dailyBookingStatic, setDailyBookingStatic] = React.useState([]);
  let enableBody = {
    dashboard: {
      enabled: true,
      policy: "",
    },
    deleterole: {
      enabled: true,
      policy: "",
    },
    createrole: {
      enabled: true,
      policy: "",
    },
    getpolicies: {
      enabled: false,
      policy: "",
    },
    getrole: {
      enabled: true,
      policy: "",
    },
    updaterole: {
      enabled: true,
      policy: "",
    },
    getroutes: {
      enabled: false,
      policy: "",
    },
    getroles: {
      enabled: true,
      policy: "",
    },
    index: {
      enabled: false,
      policy: "",
    },
    updateemailtemplate: {
      enabled: false,
      policy: "",
    },
    getemailtemplate: {
      enabled: true,
      policy: "",
    },
    searchusers: {
      enabled: true,
      policy: "",
    },
    getadvancedsettings: {
      enabled: false,
      policy: "",
    },
    updateadvancedsettings: {
      enabled: false,
      policy: "",
    },
    getproviders: {
      enabled: false,
      policy: "",
    },
    updateproviders: {
      enabled: false,
      policy: "",
    },
  };
  let disableBody = {
    dashboard: {
      enabled: false,
      policy: "",
    },
    createrole: {
      enabled: false,
      policy: "",
    },
    deleterole: {
      enabled: false,
      policy: "",
    },
    getpolicies: {
      enabled: false,
      policy: "",
    },
    getrole: {
      enabled: true,
      policy: "",
    },
    updaterole: {
      enabled: false,
      policy: "",
    },
    getroutes: {
      enabled: false,
      policy: "",
    },
    getroles: {
      enabled: true,
      policy: "",
    },
    index: {
      enabled: false,
      policy: "",
    },
    updateemailtemplate: {
      enabled: false,
      policy: "",
    },
    getemailtemplate: {
      enabled: false,
      policy: "",
    },
    searchusers: {
      enabled: false,
      policy: "",
    },
    getadvancedsettings: {
      enabled: false,
      policy: "",
    },
    updateadvancedsettings: {
      enabled: false,
      policy: "",
    },
    getproviders: {
      enabled: false,
      policy: "",
    },
    updateproviders: {
      enabled: false,
      policy: "",
    },
  };

  async function apiCallHandler(apicall) {
    try {
      dataStatusCtx.setLoading(true);
      let apiResponse = await apicall();
      dataStatusCtx.setLoading(false);
      return apiResponse;
    } catch (e) {
      dataStatusCtx.setIsError(true);
      if (e.response?.data.error != undefined) {
        dataStatusCtx.setPopupText(
          e.response == undefined
            ? "cant connect to server"
            : e.response?.data.error
        );
      } else {
        dataStatusCtx.setPopupText(
          e.response == undefined
            ? "cant connect to server"
            : e.response?.data?.data[0]?.messages[0]?.message
        );
      }
      dataStatusCtx.setLoading(false);
      dataStatusCtx.setShouldShowPopup(true);
      throw e;
    }
  }

  const fetchCurrentUserRoleDetail = async () => {
    let roleId = localStorage.getItem("role");
    let roleData = await apiCallHandler(
      GetMethodWithToken.bind(this, "/users-permissions/roles/" + roleId)
    );
    let dashBoardStatus =
      roleData.data.role.permissions["application"].controllers.booking?.getmain
        ?.enabled;
    let miniadminId = "6006a4bfe72e2ec2a510515b";
    if (dashBoardStatus || roleId === miniadminId) {
      setIsEnableDashBoardManagementLocal(true);
    } else {
      setIsEnableDashBoardManagementLocal(false);
    }

    setIsEnableUserManagementLocal(
      roleData.data.role.permissions["users-permissions"].controllers.user.count
    );
    setIsEnableBookingManagementLocal(
      roleData.data.role.permissions["application"].controllers.booking.find
        .enabled
    );
    setIsEnableNotificationManagementLocal(
      roleData.data.role.permissions["application"].controllers
        .schedulenotification.find.enabled
    );
    setIsEnableAnnouncementManagementLocal(
      roleData.data.role.permissions["application"].controllers.announcement.find.enabled
    );
    setIsEnablePricingManagementLocal(
      roleData.data.role.permissions["application"].controllers.oil.find.enabled
    );
    setIsEnableSettingManagementLocal(
      roleData.data.role.permissions["application"].controllers.setting.find
    );
    setIsEnableStationManagementLocal(
      roleData.data.role.permissions["application"].controllers.station.find
    );
    setIsEnableStockManagementLocal(
      roleData.data.role.permissions["application"].controllers.stock.find.enabled
    );
    setIsEnableCouponManagementLocal(
      roleData.data.role.permissions["application"].controllers.coupon.find
        .enabled
    );

    setIsEnableTemplateManagementLocal(
      roleData.data.role.permissions["application"].controllers.template.find
        .enabled
    );
  };

  const fetchUser = async (start, role) => {
    let userData = await apiCallHandler(
      GetMethodWithToken.bind(
        this,
        "/users?_sort=createdAt:ASC&role.id=" +
          role +
          "&_start=" +
          start +
          "&_limit=" +
          USER_ITEM_PER_PAGE
      )
    );

    let userDataExcel = await apiCallHandler(
      GetMethodWithToken.bind(
        this,
        "/users?_sort=createdAt:ASC&role.id=" +
          role
      )
    );

    let userExcel = userDataExcel.data.user.filter(
      (user) => user != null && user.enable == true
    );

    let user = userData.data.user.filter(
      (user) => user != null && user.enable == true
    );

    setListUser(user);
    setListUserExcel(userExcel);
    
    if (userData.data.user == null) {
      setIsZeroUserResponse(true);
    } else {
      setIsZeroUserResponse(false);
    }
    setTotalListCount(userData.data.count);
    // setTotalListCount(user.length);
  };

  const fetchAllUser = async (role) => {
    let userData = await apiCallHandler(
      GetMethodWithToken.bind(
        this,
        "/users?_sort=createdAt:ASC&role.id=" + role + "&enable=true&_limit=-1"
      )
    );
    let user = userData.data.user.filter((user) => user != null);
    setListAllUser(user);
  };
  const toggleBlockThisUser = async (roleId, id, block) => {
    let body = {
      blocked: block,
    };
    let userData = await apiCallHandler(
      PutMethod.bind(this, "/users/" + id, body)
    );
    await fetchUser(0, roleId);
  };
  const fetchUserDetail = async (id) => {
    let userData = await apiCallHandler(
      GetMethodWithToken.bind(this, "/users/" + id)
    );
    setUserDetailData(userData.data);
  };
  const fetchRoleDetail = async (id) => {
    let roleData = await apiCallHandler(
      GetMethodWithToken.bind(this, "/users-permissions/roles/" + id)
    );
    setIsEnableUserManagement(
      roleData.data.role.permissions["users-permissions"]?.controllers?.user
        ?.count?.enabled
    );
    setIsEnableBookingManagement(
      roleData.data.role.permissions["application"]?.controllers?.booking
        ?.getriderbookinglist?.enabled
    );

    setIsEnableNotificationManagement(
      roleData.data.role.permissions["application"]?.controllers
        ?.schedulenotification?.find?.enabled
    );
    setIsEnablePricingManagement(
      roleData.data.role.permissions["application"]?.controllers?.oil?.find
        ?.enabled
    );
    setIsEnableCouponManagement(
      roleData.data.role.permissions["application"]?.controllers?.coupon?.find
        ?.enabled
    );
    setIsEnableSettingManagement(
      roleData.data.role.permissions["application"]?.controllers?.setting?.find
        ?.enabled
    );
    setIsEnableStationManagement(
      roleData.data.role.permissions["application"]?.controllers?.station?.find
        ?.enabled
    );
    setIsEnableStockManagement(
      roleData.data.role.permissions["application"]?.controllers?.stock?.find
        ?.enabled
    );
    setIsEnableAnnouncementManagement(
      roleData.data.role.permissions["application"]?.controllers?.announcement?.find
        ?.enabled
    );

    setIsEnableTemplateManagement(
      roleData.data.role.permissions["application"]?.controllers?.template?.find
        ?.enabled
    );

    setIsEnableCouponConditionManagement(
      roleData.data.role.permissions["application"]?.controllers
        ?.couponcondition?.find?.enabled
    );

    setIsEnableCouponDiscountTypeManagement(
      roleData.data.role.permissions["application"]?.controllers
        ?.coupondiscounttype?.find?.enabled
    );

    setIsEnableUserGroupManagement(
      roleData.data.role.permissions["application"]?.controllers?.usersgroup
        ?.find?.enabled
    );

    setIsEnableOilTypeManagement(
      roleData.data.role.permissions["application"]?.controllers?.oiltype?.find
        ?.enabled
    );
    setIsEnableProvinceManagement(
      roleData.data.role.permissions["application"]?.controllers?.province?.find
        ?.enabled
    );

    setIsEnableJoinGroupManagement(
      roleData.data.role.permissions["application"]?.controllers?.joingroup
        ?.find?.enabled
    );

    setIsEnableCampaignManagement(
      roleData.data.role.permissions["application"]?.controllers?.campaign?.find
        ?.enabled
    );

    setIsEnableTriggerManagement(
      roleData.data.role.permissions["application"]?.controllers?.triggers.find
        ?.enabled
    );

    if (
      roleData.data.role.permissions["application"]?.controllers?.booking
        ?.getmain?.enabled
    ) {
      setIsEnableDashBoardManagement(true);
    } else {
      setIsEnableDashBoardManagement(false);
    }
    setSubUserManagement({
      create:
        roleData.data.role.permissions["users-permissions"]?.controllers
          ?.userspermissions?.createrole?.enabled,
      delete:
        roleData.data.role.permissions["users-permissions"]?.controllers
          ?.userspermissions?.deleterole?.enabled,
      read: roleData.data.role.permissions["users-permissions"]?.controllers
        ?.user?.count?.enabled,
      update:
        roleData.data.role.permissions["users-permissions"]?.controllers
          ?.userspermissions?.updaterole?.enabled,
    });
    setSubBookingManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers?.booking
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.booking
        ?.getriderbookinglist?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.booking
          ?.assignrider?.enabled,
    });
    setSubPricingManagement({
      read: roleData.data.role.permissions["application"]?.controllers?.oil
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.oil?.update
          ?.enabled,
    });
    setSubCouponManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers?.coupon
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.coupon
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.coupon
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.coupon
          ?.delete?.enabled,
    });

    setSubSettingManagement({
      create: 
        roleData.data.role.permissions["application"]?.controllers?.setting
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.setting
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.setting
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.setting
          ?.delete?.enabled,
    });

    setSubStockManagement({
      create: 
        roleData.data.role.permissions["application"]?.controllers?.station
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.station
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.station
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.station
          ?.delete?.enabled,
    });

    setSubStockManagement({
      create: 
        roleData.data.role.permissions["application"]?.controllers?.stock
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.stock
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.stock
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.stock
          ?.delete?.enabled,
    });

    setSubAnnouncementManagement({
      create: 
        roleData.data.role.permissions["application"]?.controllers?.announcement
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.announcement
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.announcement
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.announcement
          ?.delete?.enabled,
    });

    setSubTemplateManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers?.template
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.template
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.template
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.template
          ?.delete?.enabled,
    });

    setSubCouponConditionManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers
          ?.couponcondition?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers
        ?.couponcondition?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers
          ?.couponcondition?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers
          ?.couponcondition?.delete?.enabled,
    });

    setSubCouponDiscountTypeManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers
          ?.coupondiscounttype?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers
        ?.coupondiscounttype?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers
          ?.coupondiscounttype?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers
          ?.coupondiscounttype?.delete?.enabled,
    });

    setSubUserGroupManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers?.usersgroup
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers
        ?.usersgroup?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.usersgroup
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.usersgroup
          ?.delete?.enabled,
    });

    setSubOilTypeManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers?.oiltype
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.oiltype
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.oiltype
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.oiltype
          ?.delete?.enabled,
    });

    setSubProvinceManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers?.province
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.province
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.province
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.province
          ?.delete?.enabled,
    });

    setSubJoinGroupManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers?.joingroup
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers
        ?.joingroup?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.joingroup
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.joingroup
          ?.delete?.enabled,
    });

    setSubCampaignManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers?.campaign
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.campaign
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.campaign
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.campaign
          ?.delete?.enabled,
    });

    setSubTriggerManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers?.triggers
          ?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers?.triggers
        ?.find?.enabled,
      update:
        roleData.data.role.permissions["application"]?.controllers?.triggers
          ?.update?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers?.triggers
          ?.delete?.enabled,
    });

    setSubNotificationManagement({
      create:
        roleData.data.role.permissions["application"]?.controllers
          ?.schedulenotification?.create?.enabled,
      read: roleData.data.role.permissions["application"]?.controllers
        ?.schedulenotification?.find?.enabled,
      delete:
        roleData.data.role.permissions["application"]?.controllers
          ?.schedulenotification?.delete?.enabled,
    });
    setRoleDetail(roleData.data.role);
  };

  function userManagementPermissionToggle() {
    let permissionUserBody = {
      auth: {
        register: {
          enabled: subUserManagement.create ? true : false,
        },
      },
      user: {
        create: {
          enabled: subUserManagement.create ? true : false,
          policy: "",
        },
        update: {
          enabled: subUserManagement.update ? true : false,
          policy: "",
        },

        find: {
          enabled: true,
          policy: "",
        },
        findOne: {
          enabled: true,
          policy: "",
        },
        count: {
          enabled: true,
          policy: "",
        },

        destroy: {
          enabled: subUserManagement.delete ? true : false,
          policy: "",
        },
      },
      userspermissions: {
        dashboard: {
          enabled: isEnableUserManagement ? true : false,
          policy: "",
        },
        deleterole: {
          enabled: subUserManagement.delete ? true : false,
          policy: "",
        },
        createrole: {
          enabled: subUserManagement.create ? true : false,
          policy: "",
        },

        getpolicies: {
          enabled: false,
          policy: "",
        },

        getrole: {
          enabled: true,
          policy: "",
        },

        updaterole: {
          enabled: subUserManagement.update ? true : false,
          policy: "",
        },

        getroutes: {
          enabled: false,
          policy: "",
        },

        getroles: {
          enabled: true,
          policy: "",
        },

        index: {
          enabled: false,
          policy: "",
        },

        updateemailtemplate: {
          enabled: false,
          policy: "",
        },

        // getemailtemplate: {
        //   enabled: subUserManagement.read
        //     ? true
        //     : isEnableCouponManagement
        //     ? true
        //     : false,
        //   policy: "",
        // },

        getemailtemplate: {
          enabled: true,
          policy: "",
        },

        // searchusers: {
        //   enabled: subUserManagement.read
        //     ? true
        //     : isEnableCouponManagement
        //     ? true
        //     : false,
        //   policy: "",
        // },

        searchusers: {
          enabled: true,
          policy: "",
        },

        // getadvancedsettings: {
        //   enabled: isEnableUserManagement
        //     ? false
        //     : isEnableCouponManagement
        //     ? false
        //     : true,
        //   policy: "",
        // },

        getadvancedsettings: {
          enabled: false,
          policy: "",
        },

        // updateadvancedsettings: {
        //   enabled: isEnableUserManagement
        //     ? false
        //     : isEnableCouponManagement
        //     ? false
        //     : true,
        //   policy: "",
        // },

        updateadvancedsettings: {
          enabled: false,
          policy: "",
        },

        // getproviders: {
        //   enabled: isEnableUserManagement
        //     ? false
        //     : isEnableCouponManagement
        //     ? false
        //     : true,
        //   policy: "",
        // },
        // updateproviders: {
        //   enabled: isEnableUserManagement
        //     ? false
        //     : isEnableCouponManagement
        //     ? false
        //     : true,
        //   policy: "",
        // },

        getproviders: {
          enabled: false,
          policy: "",
        },
        updateproviders: {
          enabled: false,
          policy: "",
        },
      },
    };
    return permissionUserBody;
  }

  function bookingPermissionToggle(status) {
    let bookingBody = {
      find: {
        enabled: subBookingManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subBookingManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subBookingManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: subBookingManagement.create ? true : false,
        policy: "",
      },
      update: {
        enabled: subBookingManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
      getmain: {
        enabled: isEnableDashBoardManagement ? true : false,
        policy: "",
      },
      getriderbookinglist: {
        enabled: subBookingManagement.read ? true : false,
        policy: "",
      },
      getBookingByStatus: {
        enabled: subBookingManagement.read ? true : false,
        policy: "",
      },
      getmybookinglist: {
        enabled: subBookingManagement.read ? true : false,
        policy: "",
      },
      getmyrunningbookinglist: {
        enabled: subBookingManagement.read ? true : false,
        policy: "",
      },
      notifyLateBooking: {
        enabled: subBookingManagement.create ? true : false,
        policy: "",
      },
      assignRider: {
        enabled: subBookingManagement.update ? true : false,
        policy: "",
      },
      riderConfirmOrder: {
        enabled: subBookingManagement.update ? true : false,
        policy: "",
      },
      riderOrderArrived: {
        enabled: subBookingManagement.update ? true : false,
        policy: "",
      },
      changeBookingStatusToPayment: {
        enabled: subBookingManagement.update ? true : false,
        policy: "",
      },
      cancelBooking: {
        enabled: subBookingManagement.update ? true : false,
        policy: "",
      },
      customerComplete: {
        enabled: subBookingManagement.update ? true : false,
        policy: "",
      },
      createWalking: {
        enabled: subBookingManagement.create ? true : false,
        policy: "",
      },
      getMyNewOrder: {
        enabled: subBookingManagement.read ? true : false,
        policy: "",
      },
      calculateDuration: {
        enabled: subBookingManagement.create ? true : false,
        policy: "",
      },
    };
    return bookingBody;
  }
  function notificationPermissionToggle(status) {
    let notificationBody = {
      find: {
        enabled: subNotificationManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subNotificationManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subNotificationManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: subNotificationManagement.create ? true : false,
        policy: "",
      },
      update: {
        enabled: status ? true : false,
        policy: "",
      },
      delete: {
        enabled: subNotificationManagement.delete ? true : false,
        policy: "",
      },
      scheduleNotification: {
        enabled: subNotificationManagement.create ? true : false,
        policy: "",
      },
      scheduleNotificationToAllCustomer: {
        enabled: subNotificationManagement.create ? true : false,
        policy: "",
      },
      scheduleNotificationToSelectedCustomer: {
        enabled: subNotificationManagement.create ? true : false,
        policy: "",
      },
      sendToAllCustomerNow: {
        enabled: subNotificationManagement.create ? true : false,
        policy: "",
      },
    };
    return notificationBody;
  }
  function pricingPermissionToggle(status) {
    let pricingBody = {
      find: {
        enabled: subPricingManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subPricingManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subPricingManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subPricingManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
    };
    return pricingBody;
  }

  function couponPermissionToggled(status) {
    let counponBody = {
      find: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
    };
    return counponBody;
  }

  function settingPermissionToggled(status) {
    let settingBody = {
      find: {
        enabled: subSettingManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subSettingManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subSettingManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: subSettingManagement.create ? true : false,
        policy: "",
      },
      update: {
        enabled: subSettingManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: subSettingManagement.delete ? true : false,
        policy: "",
      },
    };
    return settingBody;
  }

  function stationPermissionToggled(status) {
    let stationBody = {
      find: {
        enabled: subStationManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subStationManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subStationManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: subStationManagement.create ? true : false,
        policy: "",
      },
      update: {
        enabled: subStationManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: subStationManagement.delete ? true : false,
        policy: "",
      },
    };
    return stationBody;
  }


  function stockPermissionToggled(status) {
    let stockBody = {
      find: {
        enabled: subStockManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subStockManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subStockManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: subStockManagement.create ? true : false,
        policy: "",
      },
      update: {
        enabled: subStockManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: subStockManagement.delete ? true : false,
        policy: "",
      },
    };
    return stockBody;
  }
  
  function announcementPermissionToggled(status) {
    let stockBody = {
      find: {
        enabled: subAnnouncementManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subAnnouncementManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subAnnouncementManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: subAnnouncementManagement.create ? true : false,
        policy: "",
      },
      update: {
        enabled: subAnnouncementManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: subAnnouncementManagement.delete ? true : false,
        policy: "",
      },
    };
    return stockBody;
  }

  function templatePermissionToggled(status) {
    let templateBody = {
      find: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
    };
    return templateBody;
  }

  function couponConditionPermissionToggled(status) {
    let couponConditionBody = {
      find: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
    };
    return couponConditionBody;
  }

  function couponDiscountTypePermissionToggled(status) {
    let couponDiscountTypeBody = {
      find: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
    };
    return couponDiscountTypeBody;
  }

  function usersGroupPermissionToggled(status) {
    let usersgroupBody = {
      find: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
    };
    return usersgroupBody;
  }

  function oilTypePermissionToggled(status) {
    let oilTypeBody = {
      find: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
    };
    return oilTypeBody;
  }

  function provincePermissionToggled(status) {
    let provinceBody = {
      find: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
    };
    return provinceBody;
  }

  function joinGroupPermissionToggled(status) {
    let joinGroupBody = {
      find: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
    };
    return joinGroupBody;
  }

  function campaignPermissionToggled(status) {
    let joinGroupBody = {
      find: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
      deletecampaigntimer: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      getcampaigntimerwithid: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      postcampaignwithtimer: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
    };
    return joinGroupBody;
  }

  function triggerPermissionToggled(status) {
    let joinGroupBody = {
      find: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      findOne: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      count: {
        enabled: subCouponManagement.read ? true : false,
        policy: "",
      },
      create: {
        enabled: status ? true : false,
        policy: "",
      },
      update: {
        enabled: subCouponManagement.update ? true : false,
        policy: "",
      },
      delete: {
        enabled: status ? true : false,
        policy: "",
      },
    };
    return joinGroupBody;
  }

  const onSaveRoleManager = async (id) => {
    let permissionUserBody = userManagementPermissionToggle();
    let permissionApplicationBody = {
      booking: bookingPermissionToggle(isEnableBookingManagement),
      schedulenotification: notificationPermissionToggle(
        isEnableNotificationManagement
      ),
      oil: pricingPermissionToggle(isEnablePricingManagement),
      setting: settingPermissionToggled(isEnableSettingManagement),
      station: stationPermissionToggled(isEnableStationManagement),
      stock: stockPermissionToggled(isEnableStockManagement),
      announcement: announcementPermissionToggled(isEnableAnnouncementManagement),
      coupon: couponPermissionToggled(isEnableCouponManagement),
      template: templatePermissionToggled(isEnableCouponManagement),
      couponcondition: couponConditionPermissionToggled(
        isEnableCouponManagement
      ),
      coupondiscounttype: couponDiscountTypePermissionToggled(
        isEnableCouponManagement
      ),
      usersgroup: usersGroupPermissionToggled(isEnableCouponManagement),

      oiltype: oilTypePermissionToggled(isEnableCouponManagement),
      province: provincePermissionToggled(isEnableCouponManagement),
      joingroup: joinGroupPermissionToggled(isEnableCouponManagement),
      campaign: campaignPermissionToggled(isEnableCouponManagement),
      triggers: triggerPermissionToggled(isEnableCouponManagement),
    };

    //TODO dup code need to clean

    if (isEnableDashBoardManagement) {
      permissionApplicationBody.booking.getBookingByStatus.enabled = true;
      permissionUserBody.user.find.enabled = true;
      permissionUserBody.userspermissions.dashboard.enabled = true;
      permissionApplicationBody.booking.find.enabled = true;
      if (isEnableBookingManagement) {
        permissionUserBody.user.find.enabled = true;
      }
      let permission = UserPermissionsFormatter(
        permissionApplicationBody,
        permissionUserBody
      );

      let roleData = await apiCallHandler(
        PutMethod.bind(this, "/users-permissions/roles/" + id, permission)
      );

      fetchRoleDetail(id);
    } else {
      if (isEnableBookingManagement) {
        permissionUserBody.user.find.enabled = true;
      }

      permissionApplicationBody.booking.getBookingByStatus.enabled =
        isEnableBookingManagement ? true : false;
      permissionUserBody.user.find.enabled =
        isEnableUserManagement ||
        isEnableNotificationManagement ||
        isEnableCouponManagement
          ? true
          : false;
      permissionUserBody.userspermissions.dashboard.enabled = false;
      permissionApplicationBody.booking.find.enabled = isEnableBookingManagement
        ? true
        : false;
      let permission = UserPermissionsFormatter(
        permissionApplicationBody,
        permissionUserBody
      );
      let roleData = await apiCallHandler(
        PutMethod.bind(this, "/users-permissions/roles/" + id, permission)
      );

      fetchRoleDetail(id);
    }
  };

  const createNewRole = async () => {
    let permissionUserBody = userManagementPermissionToggle();
    let permissionApplicationBody = {
      booking: bookingPermissionToggle(isEnableBookingManagement),
      schedulenotification: notificationPermissionToggle(
        isEnableNotificationManagement
      ),
      oil: pricingPermissionToggle(isEnablePricingManagement),
      setting: settingPermissionToggled(isEnableSettingManagement),
      station: stationPermissionToggled(isEnableStationManagement),
      stock: stockPermissionToggled(isEnableStockManagement),
      announcement: announcementPermissionToggled(isEnableAnnouncementManagement),
      coupon: couponPermissionToggled(isEnableCouponManagement),
      template: templatePermissionToggled(isEnableCouponManagement),
      couponcondition: couponConditionPermissionToggled(
        isEnableCouponManagement
      ),
      coupondiscounttype: couponDiscountTypePermissionToggled(
        isEnableCouponManagement
      ),
      usersgroup: usersGroupPermissionToggled(isEnableCouponManagement),
      oiltype: oilTypePermissionToggled(isEnableCouponManagement),
      province: provincePermissionToggled(isEnableCouponManagement),
      joingroup: joinGroupPermissionToggled(isEnableCouponManagement),
      campaign: campaignPermissionToggled(isEnableCouponManagement),
      triggers: triggerPermissionToggled(isEnableCouponManagement),
    };
    if (isEnableDashBoardManagement) {
      permissionApplicationBody.booking.find.enabled = true;
      permissionApplicationBody.booking.getBookingByStatus.enabled = true;
      permissionUserBody.userspermissions.dashboard.enabled = true;
      permissionUserBody.user.find.enabled = true;
    } else {
      permissionApplicationBody.booking.getBookingByStatus.enabled =
        isEnableBookingManagement ? true : false;
      permissionUserBody.user.find.enabled =
        isEnableUserManagement ||
        isEnableNotificationManagement ||
        isEnableCouponManagement
          ? true
          : false;
      permissionUserBody.userspermissions.dashboard.enabled = false;
      permissionApplicationBody.booking.find.enabled = isEnableBookingManagement
        ? true
        : false;
    }
    if (isEnableBookingManagement) {
      permissionUserBody.user.find.enabled = true;
    }

    let permission = UserPermissionsFormatter(
      permissionApplicationBody,
      permissionUserBody
    );

    let body = {
      name: nameRole,
      description: descriptionRole,
      permissions: permission.permissions,
    };

    try {
      dataStatusCtx.setLoading(true);
      setshouldShowAddroleModal(false);
      // let roleData = await apiCallHandler(PostMethodWithToken.bind(this, '/users-permissions/roles', body));
      let roleData = await PostMethodWithToken(
        "/users-permissions/roles",
        body
      );
      dataStatusCtx.setLoading(false);
      await fetchRole();
      setNameRole("");
      setDescriptionRole("");
      setIsEnableBookingManagement(false);
      setIsEnableSettingManagement(false);
      setIsEnableStationManagement(false);
      setIsEnableStockManagement(false);
      setIsEnableAnnouncementManagement(false);
      setIsEnableDashBoardManagement(false);
      setIsEnableNotificationManagement(false);
      setIsEnablePricingManagement(false);
      setIsEnableUserManagement(false);
      //////////////////
      setIsEnableCouponManagement(false);
      setIsEnableTemplateManagement(false);
      setIsEnableCouponConditionManagement(false);
      setIsEnableCouponDiscountTypeManagement(false);
      setIsEnableUserGroupManagement(false);
      setIsEnableOilTypeManagement(false);
      setIsEnableProvinceManagement(false);
      setIsEnableJoinGroupManagement(false);
      setIsEnableCampaignManagement(false);
      setIsEnableTriggerManagement(false);
      /////////////
      dataStatusCtx.setPopupText(
        `You successfully created a ${body.name} role`
      );
      dataStatusCtx.setShouldShowPopup(true);
    } catch (e) {
      dataStatusCtx.setIsError(true);
      if (e.response?.data.error != undefined) {
        dataStatusCtx.setPopupText(
          e.response == undefined
            ? "cant connect to server"
            : e.response?.data.error
        );
      } else {
        dataStatusCtx.setPopupText(
          e.response == undefined
            ? "cant connect to server"
            : e.response?.data?.data[0]?.messages[0]?.message
        );
      }
      dataStatusCtx.setLoading(false);
      dataStatusCtx.setShouldShowPopup(true);
      // window.location.reload();
      setNameRole("");
      setDescriptionRole("");
      setIsEnableBookingManagement(false);
      setIsEnableSettingManagement(false);
      setIsEnableStationManagement(false);
      setIsEnableStockManagement(false);
      setIsEnableAnnouncementManagement(false);
      setIsEnableDashBoardManagement(false);
      setIsEnableNotificationManagement(false);
      setIsEnablePricingManagement(false);
      setIsEnableUserManagement(false);
      //////////////////
      setIsEnableCouponManagement(false);
      setIsEnableTemplateManagement(false);
      setIsEnableCouponConditionManagement(false);
      setIsEnableCouponDiscountTypeManagement(false);
      setIsEnableUserGroupManagement(false);
      setIsEnableOilTypeManagement(false);
      setIsEnableProvinceManagement(false);
      setIsEnableJoinGroupManagement(false);
      setIsEnableCampaignManagement(false);
      setIsEnableTriggerManagement(false);
      /////////////
    }
  };
  useEffect(() => {
  }, [nameRole]);
  // const deleteUser = async (id, role) => {
  //   let userData = await apiCallHandler(
  //     DeleteMethod.bind(this, "/users/" + id)
  //   );
  //   await fetchUser(0, role);
  // };

  const deleteUser = async (id, role) => {
    let body = {
      enable: false,
    };
    let userData = await apiCallHandler(
      PutMethod.bind(this, "/users/" + id, body)
    );
    await fetchUser(0, role);
  };

  // const deleteRole = async (id) => {
  //   let roleData = await apiCallHandler(
  //     DeleteMethod.bind(this, "/users-permissions/roles/" + id)
  //   );
  //   await fetchRole();
  // };

  const deleteRole = async (id) => {
    try {
      let body = {
        enable: false,
      };

      await apiCallHandler(
        PutMethod.bind(this, "/users-permissions/roles/" + id, body)
      );
      await fetchRole();

      let userData = await apiCallHandler(
        GetMethodWithToken.bind(
          this,
          "/users?_sort=createdAt:ASC&role.id=" + id
        )
      );
      let user = userData.data.user.filter(
        (user) => user != null && user.enable != false
      );

      setListDeleteUser(user);

      for (const req of user) {
        await deleteUser(req.id, id);
      }
    } catch (e) {}
  };

  const editUserDetail = async (id) => {
    let body = {
      email: email,
      password: password,
      phone: phone,
      username: username,
    };
    let userData = await apiCallHandler(
      PutMethod.bind(this, "/users/" + id, cleanData(body))
    );
    await fetchUserDetail(id);
    setIsEditUserData(false);
  };
  const editCarDetail = async (id) => {
    let body = {
      brand: brand,
      plate: plate,
      color: color,
    };
    let carData = await apiCallHandler(
      PutMethod.bind(this, "/cars/" + id, cleanData(body))
    );
    await fetchUserDetail(carData.data.user.id);
    setIsEditUserCar(false);
  };
  function cleanData(obj) {
    //mostly use for put data because we need to clear all null key in map before send request
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  const createUser = async (role) => {
    setshouldShowRegisterModal(false);
    //disable car because customer want it
    // let carData = await createCar();
    let body = {
      email: email,
      password: password,
      phone: phone,
      username: username,
      // car: carData.id,
      role: role,
    };
    let userData = await apiCallHandler(
      PostMethodWithToken.bind(this, "/auth/local/register", body)
    );
    setemail("");
    setpassword("");
    setphone("");
    setusername("");
    dataStatusCtx.setTitle("You successfully created a new rider");
    dataStatusCtx.setIsError(false);
    dataStatusCtx.setPopupText("added user :" + userData.data.user.username);
    dataStatusCtx.setShouldShowPopup(true);
    fetchUser(0, role);
  };
  const createCar = async () => {
    let body = {
      brand: brand,
      plate: plate,
      color: color,
    };
    let carData = await apiCallHandler(
      PostMethodWithToken.bind(this, "/cars", body)
    );
    return carData.data;
  };

  async function fetchRole() {
    let roleData = await apiCallHandler(
      GetMethodWithToken.bind(this, "/users-permissions/roles")
    );
    let data = roleData.data.roles;

    setListRole(data);
  }

  useEffect(() => {
    fetchRole();
    fetchCurrentUserRoleDetail();
  }, []);

  useEffect(() => {
    setSubBookingManagement({
      create: isEnableBookingManagement,
      read: isEnableBookingManagement,
      update: isEnableBookingManagement,
    });
  }, [isEnableBookingManagement]);
  useEffect(() => {
    if (isEnableDashBoardManagement) {
      setSubBookingManagement({
        ...subBookingManagement,
        read: isEnableDashBoardManagement,
      });
    }
  }, [isEnableDashBoardManagement]);
  useEffect(() => {
    setSubNotificationManagement({
      create: isEnableNotificationManagement,
      read: isEnableNotificationManagement,
      delete: isEnableNotificationManagement,
    });
  }, [isEnableNotificationManagement]);
  useEffect(() => {
    setSubUserManagement({
      read: isEnableUserManagement,
      create: isEnableUserManagement,
      delete: isEnableUserManagement,
      update: isEnableUserManagement,
    });
  }, [isEnableUserManagement]);

  useEffect(() => {
    setSubPricingManagement({
      read: isEnablePricingManagement,
      update: isEnablePricingManagement,
    });
  }, [isEnablePricingManagement]);

  ///////////
  useEffect(() => {
    setSubCouponManagement({
      read: isEnableCouponManagement,
      create: isEnableCouponManagement,
      delete: isEnableCouponManagement,
      update: isEnableCouponManagement,
    });
  }, [isEnableCouponManagement]);

  useEffect(() => {
    setSubSettingManagement({
      read: isEnableSettingManagement,
      create: isEnableSettingManagement,
      delete: isEnableSettingManagement,
      update: isEnableSettingManagement,
    });
  }, [isEnableSettingManagement]);

  useEffect(() => {
    setSubStationManagement({
      read: isEnableStationManagement,
      create: isEnableStationManagement,
      delete: isEnableStationManagement,
      update: isEnableStationManagement,
    });
  }, [isEnableStationManagement]);

  useEffect(() => {
    setSubStockManagement({
      read: isEnableStockManagement,
      create: isEnableStockManagement,
      delete: isEnableStockManagement,
      update: isEnableStockManagement,
    });
  }, [isEnableStockManagement]);

  useEffect(() => {
    setSubAnnouncementManagement({
      read: isEnableAnnouncementManagement,
      create: isEnableAnnouncementManagement,
      delete: isEnableAnnouncementManagement,
      update: isEnableAnnouncementManagement,
    });
  }, [isEnableAnnouncementManagement]);

  useEffect(() => {
    setSubTemplateManagement({
      read: isEnableTemplateManagement,
      create: isEnableTemplateManagement,
      delete: isEnableTemplateManagement,
      update: isEnableTemplateManagement,
    });
  }, [isEnableTemplateManagement]);

  useEffect(() => {
    setSubCouponConditionManagement({
      read: isEnableCouponConditionManagement,
      create: isEnableCouponConditionManagement,
      delete: isEnableCouponConditionManagement,
      update: isEnableCouponConditionManagement,
    });
  }, [isEnableCouponConditionManagement]);

  useEffect(() => {
    setSubCouponDiscountTypeManagement({
      read: isEnableCouponDiscountTypeManagement,
      create: isEnableCouponDiscountTypeManagement,
      delete: isEnableCouponDiscountTypeManagement,
      update: isEnableCouponDiscountTypeManagement,
    });
  }, [isEnableCouponDiscountTypeManagement]);

  useEffect(() => {
    setSubUserGroupManagement({
      read: isEnableUserGroupManagement,
      create: isEnableUserGroupManagement,
      delete: isEnableUserGroupManagement,
      update: isEnableUserGroupManagement,
    });
  }, [isEnableUserGroupManagement]);

  useEffect(() => {
    setSubOilTypeManagement({
      read: isEnableOilTypeManagement,
      create: isEnableOilTypeManagement,
      delete: isEnableOilTypeManagement,
      update: isEnableOilTypeManagement,
    });
  }, [isEnableOilTypeManagement]);

  useEffect(() => {
    setSubProvinceManagement({
      read: isEnableProvinceManagement,
      create: isEnableProvinceManagement,
      delete: isEnableProvinceManagement,
      update: isEnableProvinceManagement,
    });
  }, [isEnableProvinceManagement]);

  useEffect(() => {
    setSubJoinGroupManagement({
      read: isEnableJoinGroupManagement,
      create: isEnableJoinGroupManagement,
      delete: isEnableJoinGroupManagement,
      update: isEnableJoinGroupManagement,
    });
  }, [isEnableJoinGroupManagement]);

  useEffect(() => {
    setSubCampaignManagement({
      read: isEnableCampaignManagement,
      create: isEnableCampaignManagement,
      delete: isEnableCampaignManagement,
      update: isEnableCampaignManagement,
    });
  }, [isEnableCampaignManagement]);

  useEffect(() => {
    setSubTriggerManagement({
      read: isEnableTriggerManagement,
      create: isEnableTriggerManagement,
      delete: isEnableTriggerManagement,
      update: isEnableTriggerManagement,
    });
  }, [isEnableTriggerManagement]);
  ///////////

  const onPageChange = (roleId, page) => {
    if (!isZeroUserResponse) {
      fetchUser(page * USER_ITEM_PER_PAGE, roleId);
      setCurrentPageNumber(page);
    }
  };

  return {
    listUser,
    listUserExcel,
    listRole,
    onPageChange,
    setListUser,
    setListUserExcel,
    currentPageNumber,
    fetchUser,
    setCurrentPageNumber,
    totalListCount,
    email,
    password,
    phone,
    username,
    setemail,
    setpassword,
    setphone,
    setusername,
    setbrand,
    setplate,
    setcolor,
    createUser,
    setshouldShowRegisterModal,
    shouldShowRegisterModal,
    fetchUserDetail,
    userDetail,
    editUserDetail,
    isEditUserData,
    setIsEditUserData,
    setIsEditUserCar,
    isEditUserCar,
    editCarDetail,
    deleteUser,
    setshouldShowAddroleModal,
    shouldShowAddroleModal,
    createNewRole,
    setNameRole,
    nameRole,
    descriptionRole,
    setDescriptionRole,
    deleteRole,
    fetchRoleDetail,
    roleDetail,
    isEnableUserManagement,
    setIsEnableUserManagement,
    onSaveRoleManager,
    toggleBlockThisUser,
    dailyUserStatic,
    dailyBookingStatic,
    totalUser,
    dailyRiderStatic,
    totalRider,
    isEnableBookingManagement,
    setIsEnableBookingManagement,
    setIsEnablePricingManagement,
    isEnablePricingManagement,
    setIsEnableNotificationManagement,
    isEnableCouponManagement,
    setIsEnableSettingManagement,
    isEnableSettingManagement,
    setIsEnableStationManagement,
    isEnableStationManagement,
    setIsEnableStockManagement,
    isEnableStockManagement,
    setIsEnableAnnouncementManagement,
    isEnableAnnouncementManagement,
    setIsEnableCouponManagement,
    isEnableTemplateManagement,
    setIsEnableTemplateManagement,
    isEnableNotificationManagement,
    isEnableBookingManagementLocal,
    isEnableNotificationManagementLocal,
    isEnableAnnouncementManagementLocal,
    isEnablePricingManagementLocal,
    isEnableSettingManagementLocal,
    isEnableStationManagementLocal,
    isEnableStockManagementLocal,
    isEnableCouponManagementLocal,
    isEnableTemplateManagementLocal,
    isEnableUserManagementLocal,
    isEnableDashBoardManagement,
    setIsEnableDashBoardManagement,
    isEnableDashBoardManagementLocal,
    listAllUser,
    setListAllUser,
    fetchAllUser,
    setSubBookingManagement,
    setSubPricingManagement,
    setSubDashBoardManagement,
    setSubNotificationManagement,
    setSubUserManagement,
    subUserManagement,
    subPricingManagement,
    subBookingManagement,
    subNotificationManagement,
    subDashBoardManagement,
    subCouponManagement,
    subTemplateManagement,
    setSubCouponManagement,
    setSubTemplateManagement,
    subSettingManagement,
    subStationManagement,
    setSubSettingManagement,
    setSubStationManagement,
    subStockManagement,
    setSubStockManagement,
    subAnnouncementManagement,
    setSubAnnouncementManagement,
    isEnableCouponConditionManagement,
    setIsEnableCouponConditionManagement,
    subCouponConditionManagement,
    setSubCouponConditionManagement,

    isEnableCouponDiscountTypeManagement,
    setIsEnableCouponDiscountTypeManagement,
    subCouponDiscountTypeManagement,
    setSubCouponDiscountTypeManagement,

    isEnableUserGroupManagement,
    setIsEnableUserGroupManagement,
    subUserGroupManagement,
    setSubUserGroupManagement,

    isEnableOilTypeManagement,
    setIsEnableOilTypeManagement,
    subOilTypeManagement,
    setSubOilTypeManagement,

    isEnableProvinceManagement,
    setIsEnableProvinceManagement,
    subProvinceManagement,
    setSubProvinceManagement,

    isEnableJoinGroupManagement,
    setIsEnableJoinGroupManagement,
    subJoinGroupManagement,
    setSubJoinGroupManagement,

    isEnableCampaignManagement,
    setIsEnableCampaignManagement,
    subCampaignManagement,
    setSubCampaignManagement,

    isEnableTriggerManagement,
    setIsEnableTriggerManagement,
    subTriggerManagement,
    setSubTriggerManagement,
  };
};

export default useUserHandler;
