import * as React from "react";
/** Utils */
import useAuthenticatorHandler from "../handler/AuthenticatorHandler";
export const AuthenticatorContext = React.createContext({
  username: "",
  password: "",
  isSigned: false,
  TwiloOpt: {
    otp: "",
    ref: "",
    cooldown: ""
  },
  setSignStatus: () => { },
  setTwiloOpt: () => {

  },
  setUsername: () => { },
  setPassword: () => { },
  onSubmitLogin: () => { },
  vertifyOtp: () => { },
  changePassword: async (number, newPassword, roleId) => { },
  listRole: [],
  setSelectedRole: (data) => { },
  selectedRole: {},
  setRole: (data) => { },
  role: {},
});

const { Provider } = AuthenticatorContext;

const AuthenticatorProvider = ({
  children,
}) => {
  const {
    listRole,
    isSigned,
    setSignStatus,
    username,
    setUsername,
    password,
    setPassword,
    TwiloOpt,
    setTwiloOpt,
    onSubmitLogin,
    vertifyOtp,
    changePassword,
    setSelectedRole,
    selectedRole,
    role,
    setRole
  } = useAuthenticatorHandler();
  return (
    <Provider
      value={{

        setSelectedRole,
        selectedRole,
        role,
        setRole,
        listRole,
        isSigned,
        setSignStatus,
        username,
        setUsername,
        password,
        setPassword,
        TwiloOpt,
        setTwiloOpt,
        onSubmitLogin,
        vertifyOtp,
        changePassword,
      }}
    >
      {children}
    </Provider>
  );
};

export default AuthenticatorProvider;
