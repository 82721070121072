import React, { useEffect } from "react";
import { postMethod, GetMethod } from "../service/NetworkCall";
import { DataStatusContext } from "../../logic/context/DataStatusContext";
import { getToken } from "../service/firebase";
const useAuthenticatorHandler = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [TwiloOpt, setTwiloOpt] = React.useState({
    otp: "",
    ref: "",
    cooldown: ""
  });
  const [selectedRole, setSelectedRole] = React.useState({ id: null, name: null });
  const [role, setRole] = React.useState({ id: null, name: null });
  const [listRole, setListRole] = React.useState([]);
  const dataStatusCtx = React.useContext(DataStatusContext);
  const [isSigned, setSignStatus] = React.useState(false);
  useEffect(() => {
    getToken();
    getListRole();
    if (localStorage.token != undefined) {
      setSignStatus(true);
    }
  }, []);
  const getListRole = async () => {
    dataStatusCtx.setLoading(true);
    try {
      var roleData = await GetMethod("/users-permissions/roles");
      setListRole(roleData.data.roles);
      dataStatusCtx.setLoading(false);
    } catch (e) {
      dataStatusCtx.setIsError(true)
      dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data?.data[0]?.messages[0]?.message);
      dataStatusCtx.setLoading(false);
      dataStatusCtx.setShouldShowPopup(true);
    }
  }
  const onSubmitLogin = async () => {
    let token = await localStorage.getItem("fcmtoken");
    let body = {
      identifier: username,
      password: password,
      token: token,
      roleId:selectedRole.id
    };
    dataStatusCtx.setLoading(true);
    try {
      var loginData = await postMethod("/auth/local", body);
      localStorage.setItem("token", loginData.data.jwt);
      localStorage.setItem("username", loginData.data.user.username);
      localStorage.setItem("role", loginData.data.user.role.id);
      dataStatusCtx.setLoading(false);
      setSignStatus(true);
    } catch (e) {
      dataStatusCtx.setIsError(true)
      dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data?.data[0]?.messages[0]?.message);
      dataStatusCtx.setLoading(false);
      dataStatusCtx.setShouldShowPopup(true);
    }
  };
  const vertifyOtp = async (number) => {
    try {
      dataStatusCtx.setLoading(true);
      var response = await GetMethod(`/auth/getuserotp/${number}`);
      setTwiloOpt({
        otp: response.data.otp,
        ref: response.data.ref,
        cooldown: response.data.cooldown
      })
      dataStatusCtx.setLoading(false);
    } catch (e) {
      dataStatusCtx.setIsError(true);
      if (e.response?.data.error != undefined) {
        dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data.error);
      } else {
        dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data?.data[0]?.messages[0]?.message);
      }
      dataStatusCtx.setLoading(false);
      dataStatusCtx.setShouldShowPopup(true);
    }
  }
  const changePassword = async (number, newPassword, roleId) => {
    try {
      dataStatusCtx.setLoading(true);
      var changePassword = await postMethod("/auth/changepassword", {
        "phonenumber": number,
        "newpassword": newPassword,
        "roleId": roleId,
      });
      dataStatusCtx.setLoading(false);
      return changePassword;
    } catch (e) {
      dataStatusCtx.setIsError(true);
      if (e.response?.data.error != undefined) {
        dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data.error);
      } else {
        dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data?.data[0]?.messages[0]?.message);
      }
      dataStatusCtx.setLoading(false);
      dataStatusCtx.setShouldShowPopup(true);
    }
  }
  return {
    isSigned,
    setSignStatus,
    username,
    password,
    TwiloOpt,
    setTwiloOpt,
    setUsername,
    setPassword,
    onSubmitLogin,
    vertifyOtp,
    changePassword,
    listRole,
    setSelectedRole,
    selectedRole,
    role,
    setRole
  };
};

export default useAuthenticatorHandler;
