export const logo = ['608 134', `
  <title>coreui react pro</title>
  
<g transform="translate(0.000000,334.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M1129 3307 c-49 -26 -84 -71 -99 -128 -7 -25 -10 -234 -8 -591 l3
-553 27 -47 c15 -25 45 -57 65 -70 l38 -23 565 -3 c391 -2 577 0 603 8 47 14
106 65 123 107 8 18 14 57 14 87 l0 55 -47 -47 c-178 -176 -452 -175 -629 2
-144 145 -173 340 -78 529 112 220 318 404 616 547 l118 56 -38 36 c-20 19
-52 40 -71 47 -22 7 -141 11 -347 11 l-314 0 0 -270 c0 -257 -1 -271 -20 -290
-27 -27 -76 -26 -122 3 -20 12 -93 73 -162 135 -68 61 -127 112 -130 112 -3 0
-6 -81 -6 -180 0 -179 0 -181 -25 -205 -18 -18 -35 -25 -65 -25 l-40 0 0 278
c0 266 1 279 20 300 26 27 94 30 130 5 14 -9 82 -70 152 -135 70 -65 130 -118
133 -118 3 0 5 88 5 195 l0 195 -183 0 c-173 0 -186 -1 -228 -23z m189 -614
c-11 -3 -19 -15 -19 -31 l-2 -27 -4 27 c-2 15 -12 29 -21 31 -9 3 1 5 23 5 22
0 32 -2 23 -5z m92 -8 c0 -9 -9 -15 -22 -15 -22 -1 -23 -1 -4 -15 23 -17 14
-20 -15 -5 -27 15 -24 30 9 31 l27 1 -30 8 -30 8 33 1 c22 1 32 -4 32 -14z
m77 -15 c11 -30 11 -30 -23 -30 l-35 0 16 30 c9 17 19 30 23 30 4 0 13 -13 19
-30z m98 20 c3 -6 3 -19 0 -30 -5 -15 -15 -20 -38 -19 l-32 1 30 7 c17 3 30
13 30 21 0 19 -35 26 -46 8 -7 -10 -9 -9 -9 5 0 12 8 17 29 17 17 0 33 -5 36
-10z m73 3 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m0 -20
c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m0 -30 c-10 -2 -28
-2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z"/>
<path d="M1456 2675 c-9 -23 -8 -25 9 -25 17 0 19 9 6 28 -7 11 -10 10 -15 -3z"/>
<path d="M2833 3055 c-12 -8 -26 -30 -32 -48 -15 -46 -201 -1026 -201 -1058 0
-40 18 -48 115 -50 69 -1 91 2 100 14 6 8 40 146 74 306 35 160 67 291 70 291
3 0 143 -137 311 -305 l306 -305 126 0 c171 0 157 -17 209 239 36 176 35 175
7 160 -28 -15 -115 -6 -163 17 -23 11 -168 153 -387 380 -192 199 -356 365
-365 368 -31 12 -148 6 -170 -9z"/>
<path d="M4206 3013 c-9 -29 -36 -163 -36 -179 0 -12 71 -14 512 -12 l512 3
23 95 c12 52 22 98 23 103 0 4 -231 7 -514 7 -481 0 -515 -1 -520 -17z"/>
<path d="M5386 2958 c-9 -40 -19 -87 -22 -105 l-6 -32 274 -3 275 -3 36 -28
c70 -53 70 -152 -1 -219 -67 -62 -111 -70 -402 -75 l-255 -5 -63 -287 c-34
-157 -62 -292 -62 -299 0 -10 37 -12 167 -10 l167 3 43 195 c23 107 43 196 43
196 0 1 69 4 153 7 174 6 245 21 362 77 92 44 172 116 207 189 31 63 37 184
13 249 -38 98 -129 166 -273 203 -66 17 -111 19 -358 19 l-282 0 -16 -72z"/>
<path d="M4064 2999 c-31 -34 -158 -113 -222 -139 l-60 -24 -31 -145 c-45
-209 -46 -213 -26 -250 42 -82 216 -95 241 -19 9 29 122 592 119 596 -1 1 -10
-7 -21 -19z"/>
<path d="M4495 2748 c-2 -7 -43 -193 -90 -413 -48 -220 -89 -410 -92 -422 l-5
-23 171 0 c94 0 171 4 171 8 0 4 41 194 90 421 50 227 90 419 90 427 0 19
-328 21 -335 2z"/>
<path d="M3755 1286 c-19 -14 -19 -15 -2 -34 13 -14 15 -21 6 -24 -29 -10 6
-28 52 -28 67 0 108 22 109 58 0 20 -44 14 -58 -8 -16 -26 -46 -27 -37 -1 6
20 -17 51 -39 51 -7 0 -21 -6 -31 -14z"/>
<path d="M4773 1284 c-4 -11 1 -22 12 -30 10 -8 13 -14 7 -14 -7 0 -12 -7 -12
-15 0 -25 124 -21 150 5 27 27 26 50 -4 50 -15 0 -26 -7 -29 -20 -3 -12 -14
-20 -27 -20 -13 0 -20 5 -17 13 2 6 0 20 -5 30 -11 22 -66 23 -75 1z"/>
<path d="M5197 1219 c-18 -10 -18 -12 2 -25 12 -9 21 -28 23 -52 3 -36 4 -37
46 -40 l43 -3 -31 -29 -30 -28 0 -159 c0 -170 8 -206 48 -211 45 -7 71 49 37
78 -12 10 -15 40 -15 155 0 153 1 157 54 171 l29 7 -31 29 c-20 17 -43 28 -62
28 -25 0 -30 4 -30 23 0 30 -27 67 -48 67 -9 0 -25 -5 -35 -11z"/>
<path d="M303 1189 c-35 -10 -58 -41 -49 -65 6 -16 65 -44 91 -44 22 0 18 28
-6 35 -12 4 -19 14 -17 23 2 13 14 17 48 17 34 0 46 -4 48 -17 2 -9 -5 -19
-17 -23 -12 -3 -21 -13 -21 -22 0 -12 7 -14 32 -9 74 15 101 61 54 91 -30 20
-120 28 -163 14z"/>
<path d="M1942 1189 c-36 -11 -55 -44 -41 -71 8 -14 64 -38 92 -38 16 0 6 28
-15 42 -30 20 -15 38 31 38 48 0 72 -23 42 -40 -12 -6 -21 -17 -21 -25 0 -12
15 -15 65 -15 51 0 65 3 65 15 0 8 -8 15 -19 15 -14 0 -17 6 -14 24 4 18 -2
29 -20 41 -31 20 -120 28 -165 14z"/>
<path d="M2280 1188 c-29 -11 -36 -19 -38 -45 -3 -35 9 -45 66 -58 39 -8 45
14 10 37 -30 20 -15 38 31 38 48 0 72 -23 42 -40 -12 -6 -21 -17 -21 -25 0
-12 15 -15 65 -15 51 0 65 3 65 15 0 8 -8 15 -19 15 -14 0 -17 6 -14 24 4 18
-2 29 -20 41 -33 21 -123 28 -167 13z"/>
<path d="M4740 1188 c-29 -11 -36 -19 -38 -45 -3 -35 9 -45 66 -58 39 -8 45
14 10 37 -25 17 -17 38 15 37 12 0 16 -3 10 -6 -23 -9 -14 -33 12 -33 26 0 35
24 13 33 -8 4 -7 6 3 6 9 1 22 -5 28 -13 10 -12 8 -17 -8 -26 -12 -6 -21 -17
-21 -25 0 -12 15 -15 65 -15 51 0 65 3 65 15 0 8 -8 15 -19 15 -14 0 -17 6
-14 24 4 18 -2 29 -20 41 -33 21 -123 28 -167 13z"/>
<path d="M3373 1183 c-15 -6 -17 -43 -3 -43 6 0 8 -11 4 -25 -6 -24 -4 -25 40
-25 62 0 116 23 116 49 0 16 -6 21 -25 21 -15 0 -25 -6 -25 -14 0 -13 -20 -26
-41 -26 -5 0 -7 11 -3 24 8 32 -27 54 -63 39z"/>
<path d="M113 1160 c-50 -20 -4 -70 52 -56 28 7 43 32 29 46 -15 14 -58 20
-81 10z"/>
<path d="M602 1158 c-16 -16 -15 -23 4 -42 11 -12 36 -16 97 -16 89 0 125 13
138 48 8 20 6 22 -30 22 -25 0 -41 -5 -45 -15 -3 -10 -19 -15 -45 -15 -25 0
-41 5 -45 15 -7 18 -57 20 -74 3z"/>
<path d="M1367 1163 c-4 -3 -7 -17 -7 -29 0 -31 27 -37 131 -32 70 3 83 6 100
27 27 34 24 41 -20 41 -25 0 -41 -5 -45 -15 -3 -10 -19 -15 -45 -15 -25 0 -41
5 -45 15 -5 15 -57 21 -69 8z"/>
<path d="M4153 1154 c-14 -37 10 -49 104 -52 78 -2 93 0 116 18 15 12 27 28
27 36 0 19 -64 19 -80 -1 -7 -8 -27 -15 -45 -15 -20 0 -35 6 -39 15 -8 21 -75
20 -83 -1z"/>
<path d="M5627 1163 c-4 -3 -7 -104 -7 -224 0 -219 -4 -245 -35 -219 -12 10
-15 44 -15 171 l0 159 -30 0 -30 0 0 -164 c0 -142 -2 -165 -16 -170 -33 -13
-46 13 -40 78 17 194 18 227 6 241 -15 18 -51 20 -68 3 -17 -17 -15 -55 4 -66
13 -7 15 -18 10 -53 -4 -24 -9 -80 -12 -124 -5 -73 -4 -82 16 -100 14 -12 34
-19 58 -18 47 2 108 2 150 1 64 -3 66 6 72 262 l5 230 -31 0 c-17 0 -34 -3
-37 -7z"/>
<path d="M6922 1158 c-7 -7 -12 -22 -12 -35 0 -20 5 -23 35 -23 22 0 38 6 41
15 4 9 19 15 39 15 19 0 40 -7 49 -15 8 -8 30 -15 49 -15 30 0 34 3 30 20 -9
34 -54 50 -141 50 -47 0 -83 -5 -90 -12z"/>
<path d="M3760 1140 c-21 -14 0 -44 33 -48 49 -6 91 37 51 52 -23 9 -67 7 -84
-4z"/>
<path d="M686 1064 c-4 -11 -14 -14 -35 -9 -41 9 -95 -3 -120 -26 -26 -25 -27
-69 -2 -100 17 -21 28 -24 90 -24 l71 0 0 -93 c0 -101 -7 -108 -39 -37 -22 51
-50 69 -92 60 -34 -8 -49 -31 -49 -77 0 -65 41 -106 75 -78 20 16 19 69 0 76
-17 7 -20 30 -6 39 6 3 23 -20 38 -52 33 -67 67 -86 108 -59 25 16 25 19 25
157 0 82 -5 149 -11 161 -8 14 -8 23 0 31 6 6 11 19 11 29 0 23 -55 25 -64 2z
m-12 -60 c9 -8 16 -19 16 -24 0 -14 -42 -40 -64 -40 -23 0 -56 27 -56 46 0 32
77 46 104 18z"/>
<path d="M303 1046 c-34 -16 -53 -42 -53 -75 0 -17 6 -21 30 -21 25 0 30 4 30
25 0 27 25 45 62 45 44 0 48 -15 48 -164 0 -141 -6 -158 -30 -81 -18 56 -27
65 -71 65 -51 0 -69 -21 -69 -78 0 -77 53 -123 80 -71 13 23 6 69 -10 69 -6 0
-10 9 -10 21 0 32 23 17 40 -27 8 -21 19 -45 23 -52 14 -22 55 -32 82 -22 l25
10 0 165 c0 162 0 165 -24 180 -34 23 -114 28 -153 11z"/>
<path d="M836 1050 c-16 -5 -33 -15 -37 -22 -5 -7 -9 -86 -9 -175 0 -155 1
-163 21 -174 50 -26 93 35 54 75 -13 12 -15 38 -13 138 3 109 5 123 21 126 42
8 47 -12 47 -184 l0 -164 30 0 30 0 0 169 c0 146 -2 172 -17 190 -21 24 -84
35 -127 21z"/>
<path d="M1353 1046 l-33 -15 0 -80 c0 -71 2 -82 22 -95 12 -9 42 -16 65 -16
l43 0 0 -53 c0 -55 -12 -77 -42 -77 -20 0 -23 11 -7 27 6 6 9 23 7 39 -2 23
-9 30 -31 32 -39 5 -57 -14 -57 -58 0 -54 28 -75 97 -75 86 0 93 14 93 190 0
122 -3 146 -18 165 -23 28 -94 36 -139 16z m86 -40 c7 -8 11 -37 9 -68 -3 -52
-4 -53 -33 -53 -29 0 -30 1 -33 54 -2 29 -1 60 2 67 7 18 40 18 55 0z"/>
<path d="M5020 1053 c-41 -16 -50 -35 -50 -105 0 -88 19 -112 86 -106 l44 3 0
-55 c0 -58 -11 -80 -42 -80 -22 0 -23 8 -1 32 25 28 4 68 -36 68 -34 0 -51
-22 -51 -64 0 -67 122 -102 174 -50 13 13 16 43 16 168 0 139 -2 154 -20 171
-20 18 -93 29 -120 18z m69 -47 c7 -8 11 -37 9 -68 -3 -52 -4 -53 -33 -53 -29
0 -30 1 -33 53 -3 54 8 82 33 82 7 0 18 -6 24 -14z"/>
<path d="M10 1031 c-11 -22 -3 -51 19 -68 14 -10 14 -17 1 -60 -7 -26 -16 -77
-18 -113 -3 -54 0 -70 16 -90 17 -22 28 -25 87 -25 102 0 105 6 105 211 l0
164 -30 0 -30 0 0 -158 c0 -110 -4 -162 -12 -170 -15 -15 -51 -15 -66 0 -17
17 -15 97 3 148 17 49 20 132 5 161 -14 26 -66 26 -80 0z"/>
<path d="M1190 870 c0 -173 1 -180 21 -191 34 -18 69 3 69 41 0 18 -6 33 -15
36 -12 5 -15 31 -15 150 l0 144 -30 0 -30 0 0 -180z"/>
<path d="M1552 1038 c-17 -17 -15 -57 3 -64 12 -5 15 -29 15 -128 0 -82 -4
-126 -12 -134 -19 -19 16 -50 40 -36 9 6 42 9 72 7 93 -8 94 -5 98 196 l4 171
-36 0 -36 0 0 -160 c0 -140 -2 -161 -16 -167 -9 -3 -24 -2 -33 4 -13 8 -16 35
-19 159 -2 103 -6 151 -15 156 -18 12 -51 10 -65 -4z"/>
<path d="M1812 1038 c-17 -17 -15 -53 4 -69 12 -10 14 -23 9 -53 -3 -23 -9
-77 -11 -121 -7 -101 6 -117 90 -119 90 -1 96 9 96 174 0 110 3 140 15 150 8
7 21 10 30 7 12 -5 15 -36 17 -169 l3 -163 28 -3 27 -3 0 174 c0 198 0 197
-78 204 -38 4 -53 1 -74 -17 l-28 -21 0 -139 c0 -151 -7 -173 -50 -150 -21 11
-22 17 -16 88 17 196 18 213 6 227 -15 18 -52 19 -68 3z"/>
<path d="M2172 1038 c-17 -17 -15 -53 4 -69 12 -10 14 -23 9 -53 -11 -72 -17
-177 -11 -202 8 -31 71 -51 114 -36 21 7 43 7 70 0 30 -8 47 -7 69 4 l28 13 3
178 2 177 -30 0 -30 0 0 -164 c0 -166 -5 -191 -35 -166 -12 10 -15 44 -15 171
l0 159 -30 0 -30 0 0 -159 c0 -129 -3 -161 -15 -171 -12 -10 -18 -10 -31 1
-13 11 -15 26 -9 89 16 193 17 211 5 225 -15 18 -52 19 -68 3z"/>
<path d="M2680 1035 c-10 -12 -10 -19 -2 -27 8 -8 12 -53 12 -127 0 -91 -3
-118 -16 -132 -38 -43 24 -105 64 -65 14 14 18 45 22 169 l5 152 25 0 25 0 5
-165 5 -165 30 0 30 0 3 159 c2 129 0 163 -13 182 -18 28 -76 41 -110 26 -15
-7 -26 -8 -30 -2 -9 15 -41 12 -55 -5z"/>
<path d="M2930 1030 c-13 -24 -5 -56 15 -64 12 -4 15 -33 15 -151 l0 -146 28
3 27 3 3 168 c1 104 -2 175 -8 187 -15 27 -66 27 -80 0z"/>
<path d="M3067 1043 c-13 -12 -7 -60 8 -73 22 -18 22 -232 0 -250 -14 -12 -14
-15 2 -32 10 -11 23 -16 32 -12 9 4 42 6 74 5 96 -4 97 -1 97 201 l0 168 -30
0 -30 0 -2 -162 -3 -163 -30 0 -30 0 -5 150 c-3 83 -9 156 -13 163 -9 13 -59
17 -70 5z"/>
<path d="M3340 1030 c-16 -16 -20 -33 -20 -83 0 -36 5 -68 12 -75 9 -9 9 -15
0 -24 -18 -18 -16 -143 4 -162 23 -23 51 -20 69 8 13 19 14 29 5 45 -6 12 -15
21 -20 21 -11 0 -13 40 -4 64 3 9 12 16 20 16 22 0 16 37 -6 43 -17 4 -20 14
-20 61 0 46 4 57 21 66 43 23 49 2 49 -176 l0 -164 30 0 30 0 0 174 c0 169 -1
175 -22 190 -34 24 -123 21 -148 -4z"/>
<path d="M3562 1038 c-17 -17 -15 -53 3 -68 11 -9 15 -44 17 -154 l3 -141 28
-3 27 -3 0 178 c0 188 -3 203 -45 203 -12 0 -26 -5 -33 -12z"/>
<path d="M3690 1030 c-13 -24 -5 -56 15 -64 21 -8 21 -212 1 -242 -12 -17 -12
-22 0 -39 11 -15 18 -17 32 -9 9 6 42 9 73 6 48 -4 60 -1 78 17 20 20 21 31
21 186 l0 165 -30 0 -30 0 0 -152 c0 -84 -4 -159 -9 -166 -4 -8 -19 -12 -32
-10 -24 3 -24 4 -29 153 -3 83 -9 156 -13 163 -13 20 -65 15 -77 -8z"/>
<path d="M3951 1028 c-10 -26 -4 -49 16 -61 10 -6 13 -43 13 -152 l0 -145 30
0 30 0 0 171 c0 98 -4 178 -10 190 -16 28 -68 26 -79 -3z"/>
<path d="M4110 905 c0 -86 -4 -145 -10 -145 -5 0 -14 -9 -20 -21 -22 -40 35
-90 67 -58 7 7 22 8 48 0 39 -10 83 -1 97 22 4 7 8 87 8 180 l0 167 -30 0 -30
0 0 -154 c0 -128 -3 -156 -16 -167 -11 -9 -20 -10 -32 -2 -15 9 -17 33 -20
167 l-3 156 -29 0 -30 0 0 -145z"/>
<path d="M4340 1030 c-13 -24 -5 -56 15 -64 21 -8 21 -212 0 -243 -13 -18 -12
-22 5 -38 11 -10 24 -14 32 -9 7 5 39 7 70 6 46 -3 61 1 77 17 20 20 21 33 21
186 l0 165 -30 0 -30 0 -2 -162 -3 -163 -30 0 -30 0 -5 150 c-3 83 -9 156 -13
163 -13 20 -65 15 -77 -8z"/>
<path d="M4610 876 c0 -145 3 -177 16 -190 18 -19 28 -20 55 -6 23 13 26 68 4
76 -12 5 -15 31 -15 150 l0 144 -30 0 -30 0 0 -174z"/>
<path d="M4762 1034 c-19 -13 -22 -24 -22 -90 0 -69 2 -75 24 -85 35 -16 66 3
66 42 0 17 -6 32 -15 35 -8 4 -15 19 -15 35 0 33 26 52 52 38 15 -8 18 -23 18
-83 0 -83 -15 -106 -68 -106 -43 0 -62 -23 -62 -74 0 -62 33 -82 98 -61 21 7
22 5 22 -59 l0 -66 42 0 c33 0 39 3 30 12 -7 7 -12 40 -12 77 0 37 -4 72 -9
80 -8 12 -45 13 -91 0 -10 -3 -15 4 -15 21 0 21 6 26 42 33 66 13 83 42 83
143 0 71 -3 87 -20 104 -25 25 -114 28 -148 4z"/>
<path d="M5910 909 c0 -111 -3 -144 -15 -155 -40 -40 5 -101 54 -74 17 8 31 8
56 0 27 -9 41 -8 62 2 l28 13 3 178 2 177 -30 0 -30 0 -2 -162 c-3 -141 -5
-163 -19 -166 -9 -2 -24 4 -33 13 -13 14 -16 41 -16 166 l0 149 -30 0 -30 0 0
-141z"/>
<path d="M6382 1038 c-17 -17 -15 -67 3 -74 19 -8 19 -36 0 -98 -22 -74 -20
-130 8 -162 20 -23 31 -27 79 -28 93 -1 98 9 98 174 0 110 3 140 15 150 8 7
22 10 30 6 13 -4 15 -32 15 -171 l0 -165 30 0 30 0 -2 177 -3 177 -27 15 c-21
12 -35 13 -66 5 -25 -7 -44 -7 -54 -1 -25 17 -52 1 -42 -26 4 -12 9 -81 11
-153 4 -141 -1 -159 -46 -147 -25 6 -25 -14 -5 234 4 53 2 69 -11 83 -19 19
-46 21 -63 4z"/>
<path d="M6740 1030 c-12 -22 -5 -60 10 -60 5 0 11 -66 12 -147 l3 -148 30 0
30 0 3 161 c3 186 -3 214 -47 214 -20 0 -34 -7 -41 -20z"/>
<path d="M6895 1036 c-14 -10 -20 -30 -23 -79 -4 -56 -1 -68 17 -86 25 -25 44
-27 65 -5 20 20 20 48 1 64 -8 7 -15 27 -15 45 0 34 18 49 45 39 12 -5 15 -25
15 -90 0 -91 -8 -104 -64 -104 -62 0 -87 -79 -40 -126 24 -25 35 -26 76 -9 15
6 17 -1 20 -57 l3 -63 39 -3 c33 -3 38 -1 28 10 -6 8 -12 47 -14 88 l-3 75
-35 -1 c-19 0 -43 -4 -52 -8 -15 -7 -18 -3 -18 22 0 27 4 30 39 36 67 10 85
33 89 115 7 125 -9 151 -97 151 -33 0 -65 -6 -76 -14z"/>
<path d="M7112 1038 c-17 -17 -15 -53 3 -68 12 -10 15 -37 15 -122 0 -60 -5
-118 -11 -129 -13 -25 6 -53 30 -43 9 4 42 6 74 5 96 -4 97 -1 97 201 l0 168
-30 0 -30 0 -2 -162 -3 -163 -24 -3 c-13 -2 -28 2 -32 10 -5 7 -9 76 -9 154 0
147 -5 164 -45 164 -12 0 -26 -5 -33 -12z"/>
<path d="M6153 994 c-12 -33 11 -49 75 -52 51 -2 65 1 82 18 33 33 26 50 -21
50 -22 0 -38 -4 -34 -9 3 -5 0 -13 -6 -17 -6 -4 -16 1 -21 10 -12 21 -67 21
-75 0z"/>
<path d="M6157 783 c-18 -17 -6 -51 23 -63 60 -25 150 6 150 52 0 14 -8 18
-40 18 -31 0 -38 -3 -34 -15 4 -9 0 -15 -9 -15 -8 0 -20 7 -27 15 -13 15 -51
20 -63 8z"/>
<path d="M1570 370 l0 -50 -39 0 c-75 0 -125 -74 -102 -150 6 -20 20 -45 31
-55 22 -20 74 -23 96 -4 10 8 14 8 14 0 0 -6 9 -11 20 -11 19 0 20 7 20 160 0
153 -1 160 -20 160 -17 0 -20 -7 -20 -50z m-2 -152 c3 -59 1 -68 -18 -78 -32
-17 -63 -8 -78 23 -29 60 11 131 68 125 24 -3 25 -7 28 -70z"/>
<path d="M3350 272 c0 -160 4 -172 52 -172 33 0 39 25 8 33 -19 5 -20 14 -20
146 0 134 -1 141 -20 141 -19 0 -20 -7 -20 -148z"/>
<path d="M4862 263 l3 -158 35 -5 c84 -14 140 29 140 107 0 84 -40 123 -110
109 l-30 -5 0 54 c0 49 -2 55 -20 55 -20 0 -20 -5 -18 -157z m116 11 c17 -12
22 -25 22 -61 0 -57 -21 -83 -67 -83 l-33 0 0 68 c0 90 25 114 78 76z"/>
<path d="M5090 279 c0 -77 5 -149 10 -160 13 -23 83 -28 74 -5 -3 8 -14 16
-25 19 -18 5 -19 15 -19 146 0 134 -1 141 -20 141 -19 0 -20 -7 -20 -141z"/>
<path d="M1680 395 c-15 -18 -5 -35 21 -35 12 0 19 7 19 19 0 24 -25 34 -40
16z"/>
<path d="M5221 396 c-14 -17 -7 -36 14 -36 22 0 29 20 13 36 -13 13 -16 13
-27 0z"/>
<path d="M5697 396 c-20 -8 -45 -20 -54 -27 -24 -21 -43 -73 -43 -121 0 -93
62 -153 156 -152 58 1 76 8 68 28 -4 10 -18 12 -49 8 -86 -9 -135 34 -135 118
0 84 49 127 135 118 31 -4 45 -2 49 8 6 17 0 20 -49 28 -24 3 -55 0 -78 -8z"/>
<path d="M20 250 c0 -143 1 -150 20 -150 19 0 20 7 20 110 0 61 3 110 8 110 4
0 41 -50 82 -110 58 -85 80 -110 98 -110 22 0 22 1 22 150 0 143 -1 150 -20
150 -19 0 -20 -7 -20 -110 0 -60 -3 -110 -7 -110 -5 0 -42 50 -83 110 -58 85
-80 110 -97 110 -23 0 -23 -1 -23 -150z"/>
<path d="M310 386 c0 -10 14 -16 43 -18 l42 -3 3 -132 c2 -125 4 -133 22 -133
19 0 20 7 20 135 l0 135 40 0 c29 0 40 4 40 15 0 12 -18 15 -105 15 -85 0
-105 -3 -105 -14z"/>
<path d="M570 251 c0 -144 1 -151 20 -151 18 0 20 7 20 60 l0 60 30 0 c92 0
141 83 86 146 -20 24 -32 28 -90 32 l-66 4 0 -151z m126 98 c36 -30 -8 -99
-62 -99 -23 0 -24 4 -24 61 l0 62 36 -6 c20 -3 42 -11 50 -18z"/>
<path d="M840 385 c0 -11 12 -15 45 -15 l45 0 0 -135 c0 -128 1 -135 20 -135
19 0 20 7 20 135 l0 135 40 0 c29 0 40 4 40 15 0 12 -18 15 -105 15 -87 0
-105 -3 -105 -15z"/>
<path d="M2350 251 c0 -144 1 -151 20 -151 18 0 20 7 20 60 l0 60 30 0 c93 0
145 93 85 150 -20 19 -40 25 -90 28 l-65 4 0 -151z m126 98 c36 -30 -8 -99
-62 -99 -23 0 -24 4 -24 61 l0 62 36 -6 c20 -3 42 -11 50 -18z"/>
<path d="M4400 250 c0 -143 1 -150 20 -150 18 0 20 7 20 60 l0 60 33 0 c78 0
131 77 93 135 -21 33 -54 45 -117 45 l-49 0 0 -150z m103 109 c44 -24 48 -49
12 -84 -13 -14 -36 -25 -50 -25 -24 0 -25 2 -25 60 0 54 2 60 21 60 11 0 30
-5 42 -11z"/>
<path d="M2800 345 c0 -18 -5 -25 -20 -25 -13 0 -20 -7 -20 -20 0 -13 7 -20
20 -20 18 0 20 -7 20 -73 0 -95 12 -112 78 -108 32 2 28 29 -5 33 -27 3 -28 5
-31 76 l-3 72 31 0 c23 0 30 4 30 20 0 16 -7 20 -30 20 -25 0 -30 4 -30 25 0
18 -5 25 -20 25 -15 0 -20 -7 -20 -25z"/>
<path d="M1067 313 c-4 -3 -7 -53 -7 -110 0 -96 1 -103 20 -103 18 0 20 7 20
73 0 80 15 107 58 107 15 0 22 6 22 20 0 24 -26 26 -58 4 -20 -14 -22 -14 -22
0 0 16 -21 22 -33 9z"/>
<path d="M1238 309 c-24 -14 -12 -42 12 -29 30 16 58 12 74 -12 26 -36 19 -45
-34 -50 -27 -3 -56 -11 -65 -18 -18 -15 -20 -71 -3 -88 15 -15 72 -16 99 -2
13 7 19 7 19 0 0 -5 9 -10 20 -10 18 0 20 7 20 69 0 78 -14 127 -40 141 -24
13 -80 12 -102 -1z m102 -139 c0 -23 -51 -53 -74 -44 -19 7 -21 50 -3 57 6 3
27 6 45 6 25 1 32 -3 32 -19z"/>
<path d="M1680 211 c0 -105 1 -111 20 -111 20 0 21 5 18 107 -3 93 -5 108 -20
111 -16 3 -18 -7 -18 -107z"/>
<path d="M1787 313 c-4 -3 -7 -53 -7 -110 0 -96 1 -103 20 -103 17 0 19 8 22
82 3 78 5 83 30 97 24 14 28 14 47 -3 18 -16 21 -31 21 -98 0 -71 2 -78 20
-78 18 0 20 7 20 84 0 69 -4 88 -21 110 -24 30 -63 34 -97 10 -20 -14 -22 -14
-22 0 0 16 -21 22 -33 9z"/>
<path d="M2046 299 c-33 -26 -36 -77 -6 -104 16 -14 17 -21 8 -32 -9 -11 -9
-19 0 -33 10 -16 8 -20 -8 -25 -32 -10 -44 -42 -26 -70 42 -65 196 -29 196 45
0 35 -20 48 -85 56 -52 8 -60 29 -13 38 47 9 68 33 68 78 0 27 4 38 15 38 8 0
15 7 15 15 0 25 -130 21 -164 -6z m94 -24 c7 -8 10 -25 6 -39 -11 -46 -81 -46
-92 0 -8 31 11 54 46 54 15 0 33 -7 40 -15z m23 -182 c4 -2 7 -12 7 -22 0 -40
-102 -54 -124 -18 -18 29 26 59 73 51 20 -4 39 -9 44 -11z"/>
<path d="M2597 300 c-54 -43 -54 -152 -1 -186 24 -16 104 -21 125 -8 23 14 0
28 -43 25 -49 -3 -78 15 -78 50 0 17 7 19 65 19 l65 0 0 35 c0 72 -77 109
-133 65z m81 -22 c7 -7 12 -20 12 -30 0 -15 -8 -18 -45 -18 -49 0 -59 15 -29
44 18 19 45 21 62 4z"/>
<path d="M2957 313 c-4 -3 -7 -53 -7 -110 0 -96 1 -103 20 -103 18 0 20 7 20
73 0 80 15 107 58 107 15 0 22 6 22 20 0 24 -26 26 -58 4 -20 -14 -22 -14 -22
0 0 16 -21 22 -33 9z"/>
<path d="M3135 298 c-34 -30 -46 -84 -29 -133 19 -55 64 -78 122 -63 82 22 97
164 21 203 -40 21 -84 18 -114 -7z m104 -34 c41 -52 17 -134 -38 -134 -58 0
-85 77 -48 134 23 34 59 34 86 0z"/>
<path d="M3497 300 c-55 -44 -56 -151 -1 -186 24 -16 104 -21 125 -8 23 14 0
28 -42 26 -24 -1 -49 1 -55 5 -16 12 -34 49 -27 57 3 3 35 6 70 6 l63 0 0 35
c0 72 -77 109 -133 65z m81 -22 c7 -7 12 -20 12 -30 0 -15 -8 -18 -45 -18 -49
0 -59 15 -29 44 18 19 45 21 62 4z"/>
<path d="M3680 237 c0 -70 3 -89 21 -111 23 -29 74 -36 98 -14 11 10 14 10 18
1 3 -7 13 -13 24 -13 18 0 20 7 17 107 -3 93 -5 108 -20 111 -16 3 -18 -6 -18
-76 0 -85 -14 -112 -57 -112 -33 0 -42 21 -45 105 -3 67 -6 80 -20 83 -16 3
-18 -6 -18 -81z"/>
<path d="M3920 210 c0 -103 1 -110 20 -110 18 0 20 7 20 78 0 64 4 82 18 95
24 22 38 21 62 -3 16 -16 20 -33 20 -95 0 -68 2 -75 20 -75 18 0 20 7 20 78 0
65 3 82 19 96 25 23 59 13 72 -21 5 -13 9 -53 9 -89 0 -59 2 -64 21 -64 21 0
22 3 16 86 -4 71 -9 90 -28 110 -27 29 -68 31 -99 6 -23 -18 -24 -18 -42 0
-22 22 -66 23 -90 1 -17 -15 -18 -15 -18 0 0 10 -8 17 -20 17 -19 0 -20 -7
-20 -110z"/>
<path d="M4613 304 c-10 -26 3 -162 18 -180 22 -27 51 -33 87 -18 17 7 32 9
32 3 0 -5 9 -9 20 -9 19 0 20 7 20 110 0 103 -1 110 -20 110 -17 0 -19 -8 -22
-87 -3 -86 -3 -88 -32 -98 -24 -8 -31 -6 -47 14 -15 18 -19 39 -19 97 0 60 -3
74 -15 74 -9 0 -18 -7 -22 -16z"/>
<path d="M5220 211 c0 -105 1 -111 20 -111 20 0 21 5 18 107 -3 93 -5 108 -20
111 -16 3 -18 -7 -18 -107z"/>
<path d="M5347 300 c-55 -44 -56 -151 -2 -186 27 -18 102 -21 118 -5 15 15 0
29 -28 24 -45 -9 -73 5 -85 41 -22 67 19 125 80 113 32 -7 51 10 29 24 -25 16
-85 10 -112 -11z"/>
<path d="M5889 291 c-23 -24 -29 -38 -29 -75 0 -79 34 -120 99 -118 41 1 68
17 86 52 58 112 -69 227 -156 141z m110 -27 c41 -52 17 -134 -38 -134 -57 0
-84 78 -48 134 23 34 59 34 86 0z"/>
<path d="M6110 210 c0 -103 1 -110 20 -110 18 0 20 7 20 78 0 65 3 82 19 96
25 23 59 13 72 -21 5 -13 9 -53 9 -89 0 -58 2 -64 21 -64 19 0 20 5 18 76 -2
65 1 78 19 96 18 18 25 20 45 11 26 -12 37 -51 37 -135 0 -36 4 -48 15 -48 12
0 15 16 15 84 0 69 -4 88 -21 110 -25 31 -66 35 -99 8 -23 -18 -24 -18 -42 0
-22 22 -66 23 -90 1 -17 -15 -18 -15 -18 0 0 10 -8 17 -20 17 -19 0 -20 -7
-20 -110z"/>
<path d="M6490 165 c0 -148 1 -155 20 -155 17 0 20 7 20 44 l0 45 40 -1 c51
-1 85 22 100 67 32 96 -43 186 -120 145 -13 -7 -21 -7 -25 0 -3 5 -13 10 -21
10 -12 0 -14 -27 -14 -155z m125 100 c50 -49 17 -135 -51 -135 l-34 0 0 62 c0
92 36 123 85 73z"/>
<path d="M6748 309 c-24 -14 -12 -42 12 -29 41 22 70 9 84 -36 6 -19 2 -22
-43 -26 -28 -3 -57 -11 -66 -18 -18 -15 -20 -71 -3 -88 16 -16 80 -15 97 0 11
10 14 10 18 1 3 -7 11 -13 19 -13 11 0 14 19 14 90 0 79 -3 92 -22 110 -23 22
-80 27 -110 9z m102 -132 c0 -26 -41 -52 -76 -48 -20 2 -24 29 -8 46 19 19 84
20 84 2z"/>
<path d="M6950 210 c0 -103 1 -110 20 -110 18 0 20 7 20 83 0 72 2 84 21 95
27 17 58 6 70 -25 5 -13 9 -53 9 -89 0 -59 2 -64 21 -64 21 0 21 4 17 85 -4
72 -8 89 -28 110 -25 26 -58 32 -90 15 -13 -7 -21 -7 -25 0 -3 5 -13 10 -21
10 -11 0 -14 -21 -14 -110z"/>
<path d="M7170 307 c0 -8 16 -59 35 -112 36 -100 36 -102 10 -167 -5 -14 -2
-18 13 -18 16 0 28 24 71 147 28 82 51 151 51 156 0 4 -8 7 -19 7 -14 0 -22
-14 -36 -62 -34 -123 -35 -123 -64 -28 -19 58 -32 86 -44 88 -10 2 -17 -3 -17
-11z"/>
</g>
`]
