import React, { useEffect } from "react"
/** Utils */
import useOilsHandler from "../handler/OilHandler";
export const OilContext = React.createContext({
    listOils: [],
    listLocations: [],
    provinces:[],
    setShouldShowAddGasModal: (status) => { },
    shouldShowAddGasModal: false,
    editOilDetail: (id) => { },
    isEditingOilData: false,
    setIsEditingOilData: (status) => { },
    fetchOilDetail: (id) => { },
    setPrice: (price) => { },
    price: 0,
    oilDetail: {},
    setGasName: (name) => { },
    gasName: ""
});

const { Provider } = OilContext;


const OilProvider = ({
    children,
}) => {
    const {
        listOils,
        listLocations,
        provinces,
        setShouldShowAddGasModal,
        shouldShowAddGasModal,
        editOilDetail,
        isEditingOilData,
        setIsEditingOilData,
        fetchOilDetail,
        setPrice,
        price,
        oilDetail,
        setGasName,
        gasName
    } = useOilsHandler();
    return (
        <Provider
            value={{
                listOils,
                listLocations,
                provinces,
                setShouldShowAddGasModal,
                shouldShowAddGasModal,
                editOilDetail,
                isEditingOilData,
                setIsEditingOilData,
                fetchOilDetail,
                setPrice,
                price,
                oilDetail,
                setGasName,
                gasName
            }}
        >
            {children}
        </Provider>
    );
};

export default OilProvider;
