import React from "react";
/** Utils */
import useUserHandler from "../handler/UserHandler";
export const UserContext = React.createContext({
  listUser: [],
  listUserExcel: [],
  listRole: [],
  dailyUserStatic: [],
  dailyBookingStatic: [],
  totalUser: 0,
  dailyRiderStatic: [],
  totalRider: 0,
  onPageChange: (id, event) => {},
  currentPageNumber: 0,
  fetchUser: (start, role) => {},
  setCurrentPageNumber: (pageNumber) => {},
  totalListCount: 0,
  email: "",
  password: "",
  phone: "",
  username: "",

  setemail: (text) => {},
  setpassword: (text) => {},
  setphone: (text) => {},
  setusername: (text) => {},
  setbrand: (text) => {},
  setplate: (text) => {},
  setcolor: (text) => {},
  createUser: (role) => {},
  setListUser: (list) => {},
  setListUserExcel: (list) => {},
  setshouldShowRegisterModal: (status) => {},
  shouldShowRegisterModal: false,
  fetchUserDetail: (id) => {},
  userDetail: {},
  editUserDetail: (id) => {},
  isEditUserData: false,
  setIsEditUserData: (status) => {},
  setIsEditUserCar: (id) => {},
  isEditUserCar: {},
  editCarDetail: (id) => {},
  deleteUser: (id, role) => {},
  setshouldShowAddroleModal: (status) => {},
  shouldShowAddroleModal: false,
  createNewRole: () => {},
  setNameRole: (text) => {},
  setDescriptionRole: (text) => {},
  deleteRole: (id) => {},
  fetchRoleDetail: (id) => {},
  roleDetail: {},
  isEnableUserManagement: false,
  setIsEnableUserManagement: (status) => {},
  onSaveRoleManager: (status) => {},
  toggleBlockThisUser: (roleId, id, bloc) => {},
  setIsEnableBookingManagement: (status) => {},
  isEnableBookingManagement: false,
  setIsEnableNotificationManagement: (status) => {},
  isEnableNotificationManagement: false,
  setIsEnablePricingManagement: (status) => {},
  isEnablePricingManagement: false,
  isEnableCouponManagement: false,
  setIsEnableSettingManagement: (status) => {},
  setIsEnableStationManagement: (status) => {},
  isEnableSettingManagement: false,
  isEnableStationManagement: false,
  setIsEnableStockManagement: (status) => {},
  isEnableStockManagement: false,
  setIsEnableAnnouncementManagement: (status) => {},
  isEnableAnnouncementManagement: false,
  isEnableTemplateManagement: false,
  setIsEnableCouponManagement: (status) => {},
  setIsEnableTemplateManagement: (status) => {},
  isEnableBookingManagementLocal: false,
  isEnableNotificationManagementLocal: false,
  isEnableAnnouncementManagementLocal: false,
  isEnablePricingManagementLocal: false,
  isEnableSettingManagementLocal: false,
  isEnableStationManagementLocal: true,
  isEnableStockManagementLocal: false,
  isEnableCouponManagementLocal: false,
  isEnableUserManagementLocal: false,
  isEnableTemplateManagementLocal: false,
  isEnableDashBoardManagement: false,
  setIsEnableDashBoardManagement: (status) => {},
  isEnableDashBoardManagementLocal: false,
  nameRole: "",
  descriptionRole: "",
  setListAllUser: (list) => {},
  listAllUser: [],
  fetchAllUser: (role) => {},
  setSubBookingManagement: (status) => {},
  setSubPricingManagement: (status) => {},
  setSubCouponManagement: (status) => {},
  setSubSettingManagement: (status) => {},
  setSubStationManagement: (status) => {},
  setSubStockManagement: (status) => {},
  setSubAnnouncementManagement: (status) => {},
  setSubTemplateManagement: (status) => {},
  setSubDashBoardManagement: (status) => {},
  setSubNotificationManagement: (status) => {},
  setSubUserManagement: (status) => {},
  subTemplateManagement: {},
  subCouponManagement: {},
  subSettingManagement: {},
  subStationManagement: {},
  subStockManagement: {},
  subAnnouncementManagement: {},
  subUserManagement: {},
  subPricingManagement: {},
  subNotificationManagement: {},
  subDashBoardManagement: {},
  subBookingManagement: {},

  isEnableCouponConditionManagement: false,
  setIsEnableCouponConditionManagement: (status) => {},
  isEnableCouponConditionManagementLocal: false,
  subCouponConditionManagement: {},
  setSubCouponConditionManagement: (status) => {},

  isEnableCouponDiscountTypeManagement: false,
  setIsEnableCouponDiscountTypeManagement: (status) => {},
  isEnableCouponDiscountTypeManagementLocal: false,
  subCouponDiscountTypeManagement: {},
  setSubCouponDiscountTypeManagement: (status) => {},

  isEnableUserGroupManagement: false,
  setIsEnableUserGroupManagement: (status) => {},
  isEnableUserGroupManagementLocal: false,
  subUserGroupManagement: {},
  setSubUserGroupManagement: (status) => {},

  isEnableOilTypeManagement: false,
  setIsEnableOilTypeManagement: (status) => {},
  isEnableOilTypeManagementLocal: false,
  subOilTypeManagement: {},
  setSubOilTypeManagement: (status) => {},

  isEnableProvinceManagement: false,
  setIsEnableProvinceManagement: (status) => {},
  isEnableProvinceManagementLocal: false,
  subProvinceManagement: {},
  setSubProvinceManagement: (status) => {},

  isEnableJoinGroupManagement: false,
  setIsEnableJoinGroupManagement: (status) => {},
  isEnableJoinGroupManagementLocal: false,
  subJoinGroupManagement: {},
  setSubJoinGroupManagement: (status) => {},

  isEnableCampaignManagement: false,
  setIsEnableCampaignManagement: (status) => {},
  // isEnableJoinGroupManagementLocal,
  subCampaignManagement: {},
  setSubCampaignManagement: (status) => {},

  isEnableTriggerManagement: false,
  setIsEnableTriggerManagement: (status) => {},
  // isEnableJoinGroupManagementLocal,
  subTriggerManagement: {},
  setSubTriggerManagement: (status) => {},
});

const { Provider } = UserContext;

const UserProvider = ({ children }) => {
  const {
    setListAllUser,
    listAllUser,
    fetchAllUser,
    nameRole,
    setListUser,
    setListUserExcel,
    descriptionRole,
    isEnableDashBoardManagement,
    setIsEnableDashBoardManagement,
    isEnableDashBoardManagementLocal,
    setIsEnablePricingManagement,
    isEnablePricingManagement,
    setIsEnableNotificationManagement,
    isEnableNotificationManagement,
    setIsEnableBookingManagement,
    isEnableBookingManagement,
    toggleBlockThisUser,
    dailyRiderStatic,
    totalRider,
    listUser,
    listUserExcel,
    listRole,
    dailyUserStatic,
    dailyBookingStatic,
    onPageChange,
    currentPageNumber,
    fetchUser,
    setCurrentPageNumber,
    totalListCount,
    email,
    password,
    phone,
    username,
    setemail,
    setpassword,
    setphone,
    setusername,
    setbrand,
    setplate,
    setcolor,
    createUser,
    setshouldShowRegisterModal,
    shouldShowRegisterModal,
    totalUser,
    fetchUserDetail,
    userDetail,
    editUserDetail,
    isEditUserData,
    setIsEditUserData,
    setIsEditUserCar,
    isEditUserCar,
    editCarDetail,
    deleteUser,
    setshouldShowAddroleModal,
    shouldShowAddroleModal,
    createNewRole,
    setNameRole,
    setDescriptionRole,
    deleteRole,
    fetchRoleDetail,
    roleDetail,
    isEnableUserManagement,
    setIsEnableUserManagement,
    onSaveRoleManager,
    isEnableBookingManagementLocal,
    isEnableNotificationManagementLocal,
    isEnableAnnouncementManagementLocal,
    isEnablePricingManagementLocal,
    isEnableSettingManagementLocal,
    isEnableStationManagementLocal,
    isEnableStockManagementLocal,
    isEnableCouponManagementLocal,
    isEnableTemplateManagementLocal,
    isEnableUserManagementLocal,
    setSubBookingManagement,
    setSubPricingManagement,
    setSubCouponManagement,
    setSubSettingManagement,
    setSubStationManagement,
    setSubStockManagement,
    setSubAnnouncementManagement,
    setSubTemplateManagement,
    setSubDashBoardManagement,
    setSubNotificationManagement,
    setSubUserManagement,
    subUserManagement,
    subPricingManagement,
    subNotificationManagement,
    subDashBoardManagement,
    subBookingManagement,
    isEnableCouponManagement,
    isEnableSettingManagement,
    isEnableStationManagement,
    setIsEnableSettingManagement,
    setIsEnableStationManagement,
    isEnableStockManagement,
    setIsEnableStockManagement,
    isEnableAnnouncementManagement,
    setIsEnableAnnouncementManagement,
    isEnableTemplateManagement,
    setIsEnableCouponManagement,
    setIsEnableTemplateManagement,
    subCouponManagement,
    subSettingManagement,
    subStationManagement,
    subStockManagement,
    subAnnouncementManagement,
    subTemplateManagement,

    isEnableCouponConditionManagement,
    setIsEnableCouponConditionManagement,
    isEnableCouponConditionManagementLocal,
    subCouponConditionManagement,
    setSubCouponConditionManagement,

    isEnableCouponDiscountTypeManagement,
    setIsEnableCouponDiscountTypeManagement,
    isEnableCouponDiscountTypeManagementLocal,
    subCouponDiscountTypeManagement,
    setSubCouponDiscountTypeManagement,

    isEnableUserGroupManagement,
    setIsEnableUserGroupManagement,
    isEnableUserGroupManagementLocal,
    subUserGroupManagement,
    setSubUserGroupManagement,

    isEnableOilTypeManagement,
    setIsEnableOilTypeManagement,
    isEnableOilTypeManagementLocal,
    subOilTypeManagement,
    setSubOilTypeManagement,

    isEnableProvinceManagement,
    setIsEnableProvinceManagement,
    isEnableProvinceManagementLocal,
    subProvinceManagement,
    setSubProvinceManagement,

    isEnableJoinGroupManagement,
    setIsEnableJoinGroupManagement,
    isEnableJoinGroupManagementLocal,
    subJoinGroupManagement,
    setSubJoinGroupManagement,

    isEnableCampaignManagement,
    setIsEnableCampaignManagement,
    // isEnableJoinGroupManagementLocal,
    subCampaignManagement,
    setSubCampaignManagement,

    isEnableTriggerManagement,
    setIsEnableTriggerManagement,
    // isEnableJoinGroupManagementLocal,
    subTriggerManagement,
    setSubTriggerManagement,
  } = useUserHandler();
  return (
    <Provider
      value={{
        setListAllUser,
        setListUser,
        setListUserExcel,
        listAllUser,
        fetchAllUser,
        nameRole,
        descriptionRole,
        isEnableDashBoardManagement,
        setIsEnableDashBoardManagement,
        isEnableDashBoardManagementLocal,
        isEnableBookingManagementLocal,
        isEnableNotificationManagementLocal,
        isEnableAnnouncementManagementLocal,
        isEnablePricingManagementLocal,
        isEnableSettingManagementLocal,
        isEnableStationManagementLocal,
        isEnableStockManagementLocal,
        isEnableCouponManagementLocal,
        isEnableUserManagementLocal,
        setIsEnablePricingManagement,
        isEnablePricingManagement,
        setIsEnableNotificationManagement,
        isEnableNotificationManagement,
        setIsEnableBookingManagement,
        isEnableBookingManagement,
        dailyRiderStatic,
        totalRider,
        toggleBlockThisUser,
        listUser,
        listUserExcel,
        listRole,
        dailyUserStatic,
        dailyBookingStatic,
        onPageChange,
        currentPageNumber,
        fetchUser,
        setCurrentPageNumber,
        totalListCount,
        email,
        password,
        phone,
        username,
        setemail,
        setpassword,
        setphone,
        setusername,
        setbrand,
        setplate,
        setcolor,
        createUser,
        setshouldShowRegisterModal,
        shouldShowRegisterModal,
        totalUser,
        fetchUserDetail,
        userDetail,
        editUserDetail,
        isEditUserData,
        setIsEditUserData,
        setIsEditUserCar,
        isEditUserCar,
        editCarDetail,
        deleteUser,
        setshouldShowAddroleModal,
        shouldShowAddroleModal,
        createNewRole,
        setNameRole,
        setDescriptionRole,
        deleteRole,
        fetchRoleDetail,
        roleDetail,
        isEnableUserManagement,
        setIsEnableUserManagement,
        onSaveRoleManager,
        setSubBookingManagement,
        setSubPricingManagement,
        setSubCouponManagement,
        setSubSettingManagement,
        setSubStationManagement,
        setSubStockManagement,
        setSubAnnouncementManagement,
        setSubDashBoardManagement,
        setSubNotificationManagement,
        setSubUserManagement,
        subUserManagement,
        subPricingManagement,
        subNotificationManagement,
        subDashBoardManagement,
        subBookingManagement,
        isEnableCouponManagement,
        setIsEnableCouponManagement,
        isEnableSettingManagement,
        setIsEnableSettingManagement,
        isEnableStationManagement,
        setIsEnableStationManagement,
        isEnableStockManagement,
        setIsEnableStockManagement,
        isEnableAnnouncementManagement,
        setIsEnableAnnouncementManagement,
        subCouponManagement,
        subStockManagement,
        subSettingManagement,
        subStationManagement,
        subAnnouncementManagement,
        isEnableTemplateManagementLocal,
        setSubTemplateManagement,
        isEnableTemplateManagement,
        setIsEnableTemplateManagement,
        subTemplateManagement,

        isEnableCouponConditionManagement,
        setIsEnableCouponConditionManagement,
        isEnableCouponConditionManagementLocal,
        subCouponConditionManagement,
        setSubCouponConditionManagement,

        isEnableCouponDiscountTypeManagement,
        setIsEnableCouponDiscountTypeManagement,
        isEnableCouponDiscountTypeManagementLocal,
        subCouponDiscountTypeManagement,
        setSubCouponDiscountTypeManagement,

        isEnableUserGroupManagement,
        setIsEnableUserGroupManagement,
        isEnableUserGroupManagementLocal,
        subUserGroupManagement,
        setSubUserGroupManagement,

        isEnableOilTypeManagement,
        setIsEnableOilTypeManagement,
        isEnableOilTypeManagementLocal,
        subOilTypeManagement,
        setSubOilTypeManagement,

        isEnableProvinceManagement,
        setIsEnableProvinceManagement,
        isEnableProvinceManagementLocal,
        subProvinceManagement,
        setSubProvinceManagement,

        isEnableJoinGroupManagement,
        setIsEnableJoinGroupManagement,
        isEnableJoinGroupManagementLocal,
        subJoinGroupManagement,
        setSubJoinGroupManagement,

        isEnableCampaignManagement,
        setIsEnableCampaignManagement,
        // isEnableJoinGroupManagementLocal,
        subCampaignManagement,
        setSubCampaignManagement,

        isEnableTriggerManagement,
        setIsEnableTriggerManagement,
        // isEnableJoinGroupManagementLocal,
        subTriggerManagement,
        setSubTriggerManagement,
      }}
    >
      {children}
    </Provider>
  );
};

export default UserProvider;
