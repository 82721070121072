import React, { useEffect } from "react"
import { DataStatusContext } from "../../logic/context/DataStatusContext";
import { DeleteMethod, GetMethod, GetMethodWithToken, PostMethodWithToken, PutMethod } from "../service/NetworkCall"

const useOilHandler = () => {
    const [listOils, setListOils] = React.useState([]);
    const [provinces, setProvinces] = React.useState([]);
    const [listLocations, setLocations] = React.useState([]);
    const dataStatusCtx = React.useContext(DataStatusContext);
    const [shouldShowAddGasModal, setShouldShowAddGasModal] = React.useState(false);
    const [showEditingPriceModal, setShowEditingPriceModal] = React.useState(false);
    const [oilDetail, setOilDetailData] = React.useState({});

    // Property for editing details
    const [price, setPrice] = React.useState(0);
    const [isEditingOilData, setIsEditingOilData] = React.useState(false);

    // Property for adding new elements (Gas oil type, Location, Gas)
    const [gasName, setGasName] = React.useState("");

    async function apiCallHandler(apicall) {
        try {
            dataStatusCtx.setLoading(true);
            let apiResponse = await apicall();
            dataStatusCtx.setLoading(false);
            return apiResponse;
        } catch (e) {
            console.log(e);
            dataStatusCtx.setTitle("Error");
            dataStatusCtx.setIsError(true);
            if (e.response?.data.error != undefined) {
                dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data.error);
            } else {
                dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data?.data[0]?.messages[0]?.message);
            }
            dataStatusCtx.setLoading(false);
            dataStatusCtx.setShouldShowPopup(true);
            throw e;
        }
    }

    const editOilDetail = async (id) => {
        let body = {
            price: price
        }
        let oilData = await apiCallHandler(PutMethod.bind(this, '/oils/' + id, body));
        console.log("editOilDetail > " + oilData);
        await fetchOilDetail(id);
        setIsEditingOilData(false);
    }

    const fetchOilDetail = async (id) => {
        let oilData = await apiCallHandler(GetMethodWithToken.bind(this, '/oils/' + id));
        setOilDetailData(oilData.data);
        setPrice(oilData.data.price);
        console.log("oilData : " + oilData.data);
    }

    async function fetchOils() {
        let oilsData = await apiCallHandler(GetMethodWithToken.bind(this, '/oils'));
        // let roleData = await apiCallHandler(GetMethod.bind(this, '/oils'));
        let data = oilsData.data;
        console.log(data);
        setListOils(data);
    }

    async function fetProvinces() {
        let oilsData = await apiCallHandler(GetMethod.bind(this, '/provinces'));
        // let roleData = await apiCallHandler(GetMethod.bind(this, '/oils'));
        let data = oilsData.data;
        console.log(data);
        setProvinces(data);
    }

    useEffect(() => {
        fetchOils();
        fetProvinces();
    }, []);


    return {
        listOils,
        listLocations,
        setShouldShowAddGasModal,
        shouldShowAddGasModal,
        oilDetail,
        provinces,
        setOilDetailData,
        editOilDetail,
        isEditingOilData,
        setIsEditingOilData,
        fetchOilDetail,
        price,
        setPrice,
        setGasName,
        gasName
    };
};

export default useOilHandler;
