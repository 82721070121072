import React, { useEffect } from "react"
import { postMethod } from "../service/NetworkCall"
const useDataStatusHandler = () => {
    const [isLoading, setLoading] = React.useState(false);
    const [shouldShowPopup, setShouldShowPopup] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [popupText, setPopupText] = React.useState("")
    const [title, setTitle] = React.useState("")
    const onSubmitLogin = async () => {

    }
    useEffect(() => {
    }, [popupText]);
    return {
        isError,
        setIsError,
        popupText,
        setPopupText,
        isLoading,
        setLoading,
        setShouldShowPopup,
        shouldShowPopup,
        title,
        setTitle
    };
};

export default useDataStatusHandler;
