import * as React from "react";
/** Utils */
import useDataStatusHandler from "../handler/DataStatusHandler";
import LoadingOverlay from 'react-loading-overlay';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
export const DataStatusContext = React.createContext({
    isLoading: false,
    shouldShowPopup: false,
    popupText: "",
    title: "Alert",
    setTitle: (text) => { },
    setPopupText: (text) => { },
    setShouldShowPopup: (status) => { },
    setLoading: (status) => { },
    isError: false,
    setIsError:(status)=>{}
});

const { Provider } = DataStatusContext;

const DataStatusProvider = ({
    children,
}) => {
    const {
        setIsError,
        isError,
        setPopupText,
        popupText,
        isLoading,
        setLoading,
        title,
        setTitle,
        shouldShowPopup,
        setShouldShowPopup
    } = useDataStatusHandler();

    // console.log("test" + popupText);
    // console.log("Test");
    // console.log(isLoading);
    // console.log("test"+popupText);
    return (
        <Provider
            value={{
                setIsError,
                isError,
                popupText,
                isLoading,
                setLoading,
                shouldShowPopup,
                setShouldShowPopup,
                setPopupText,
                title,
                setTitle,
            }}>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading..'>
                <Dialog
                    open={shouldShowPopup}
                    onClose={(e) => setShouldShowPopup(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{isError? "Error":"Success"}</DialogTitle>
                    <DialogContent>
                        {popupText}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => {
                            setShouldShowPopup(false);
                            setPopupText("");
                            setIsError(false);
                        }} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
                {children}
            </LoadingOverlay>
        </Provider>
    );
};

export default DataStatusProvider;
