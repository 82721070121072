import firebase from "firebase/app";
import "firebase/messaging";
import { config, WEB_PUSH_KEY} from "src/constants";
firebase.initializeApp(config);
const messaging = firebase.messaging();
export const getToken = () => {
  return messaging
    .getToken({
      vapidKey: WEB_PUSH_KEY,
    })
    .then(currentToken => {
      if (currentToken) {
        localStorage.setItem("fcmtoken", currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    })
    .catch(err => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      resolve(payload);
    });
  });
