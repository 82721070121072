import React from "react";
import { TheContent, TheSidebar, TheHeader } from "./index";

import UserProvider from "../logic/context/UserContext";
const TheLayout = () => {
  return (
    <div className="c-app c-default-layout">
      <UserProvider>
        <TheSidebar />
       </UserProvider>
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
      </div>
    </div>
  );
};

export default TheLayout;
