import React, { useEffect } from "react";
import { DeleteMethod, GetMethodWithToken, postMethod, PostMethodWithToken } from "../service/NetworkCall";
import { getToken, onMessageListener } from "../service/firebase";
import { DataStatusContext } from "../context/DataStatusContext";
const usePushNotificationHandler = () => {
  const [message, setMessage] = React.useState({});
  const [showToast, setShowToast] = React.useState(false);
  const [listToast, setListToast] = React.useState([]);
  const [listScheduleNotification, setListScheduleNotification] = React.useState([]);
  const [scheduleDetail, setscheduleDetail] = React.useState({});
  const dataStatusCtx = React.useContext(DataStatusContext);
  useEffect(() => {
    getToken();
  }, []);
  useEffect(
    () => {
    },
    [listToast]
  );
  onMessageListener()
    .then(payload => {
      setListToast([
        ...listToast,
        {
          title: payload.notification.title,
          body: payload.notification.body,
          type: payload.data.type,
        },
      ]);
    })
    .catch(err => console.log("Error: ", err));
  const createPush = async (
    time,
    title,
    body,
    targetGroup,
    listDestinationUser
  ) => {
    try {
      dataStatusCtx.setLoading(true);

      if (targetGroup == 2) {
        let bodyApi = {
          title: title,
          body: body,
          time: time,
          destinationUser: listDestinationUser
        };
        var scheduleData = await PostMethodWithToken(
          "/scheduleNotificationToSelectedCustomer",
          bodyApi
        );
      } else {
        let bodyApi = {
          title: title,
          body: body,
          time: time,
        };
        var scheduleData = await PostMethodWithToken(
          "/schedulenotificationtoallcustomer",
          bodyApi
        );
      }
      dataStatusCtx.setLoading(false);
    } catch (e) {
      dataStatusCtx.setTitle("Error");
      if (e.response?.data.error != undefined) {
        dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data.error);
    } else {
        dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data?.data[0]?.messages[0]?.message);
    }
      dataStatusCtx.setLoading(false);
      dataStatusCtx.setShouldShowPopup(true);
      throw e;
    }
    getListScheduleNotification();
  };
  const getListScheduleNotification = async () => {
    try {
      dataStatusCtx.setLoading(true);
      var scheduleData = await GetMethodWithToken(
        "/schedulenotifications?_sort=createdAt:DESC"
      );
      setListScheduleNotification(scheduleData.data)
      dataStatusCtx.setLoading(false);
    } catch (e) {
      dataStatusCtx.setTitle("Error");
      dataStatusCtx.setIsError(true);
      if (e.response?.data.error != undefined) {
        dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data.error);
    } else {
        dataStatusCtx.setPopupText(e.response == undefined ? "cant connect to server" : e.response?.data?.data[0]?.messages[0]?.message);
    }
      dataStatusCtx.setLoading(false);
      dataStatusCtx.setShouldShowPopup(true);
      throw e;
    }

  };
  const getScheduleNotificationDetail = async (id) => {
    var scheduleData = await GetMethodWithToken(
      "/schedulenotifications/" + id
    );
    setscheduleDetail(scheduleData.data);
  };
  const deleteScheduleNotification = async (id) => {
    try {
      var status = await DeleteMethod("/schedulenotifications/" + id);
    } catch (error) {
      getListScheduleNotification();
    }
  }
  return {
    message,
    showToast,
    setShowToast,
    listToast,
    setListToast,
    createPush,
    getListScheduleNotification,
    listScheduleNotification,
    getScheduleNotificationDetail,
    scheduleDetail,
    deleteScheduleNotification
  };
};

export default usePushNotificationHandler;
