// @ts-nocheck
const { CButton, CModal, CModalHeader, CModalBody, CModalFooter } = require("@coreui/react");
const { default: React, useEffect, useImperativeHandle, forwardRef } = require("react");
const { useState } = require("react");

const CustomDialog = forwardRef(({
    dialogContent,
    modalOnClick = () => { },
    modalButtonColor = '',
    modalButtonText = "modal",
    dialogTitle = "title",
    modalClassName = "",
    enableActionFooter = true,
    colorActionFooterLeft = "danger",
    titleActionFooterLeft = "left",
    onClickActionFooterLeft = () => { },
    colorActionFooterRight = "secondary",
    titleActionFooterRight = "right",
    onClickActionFooterRight = () => { }, }, ref) => {

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    const [modal, setModal] = useState(false);
    useImperativeHandle(ref, () => ({
        dismiss() {
            toggle();
        }
    }));

    const toggle = (e) => {
        setModal(!modal);
    }

    return (
        <>
            <CButton
                color={modalButtonColor}
                onClick={(e) => {
                    toggle(e);
                    modalOnClick();
                }}
                className={"mr-1 " + modalClassName}
            >{modalButtonText}</CButton>
            <CModal
                show={modal}
                onClose={toggle}
            >
                <CModalHeader closeButton>
                    <b>
                        <span className="text-dark h4">
                            {dialogTitle}
                        </span>
                    </b>
                </CModalHeader>
                <CModalBody>
                    {dialogContent}
                </CModalBody>
                {enableActionFooter ? <CModalFooter>
                    <CButton
                        color={colorActionFooterLeft}
                        onClick={(e) => {
                            toggle(e);
                            onClickActionFooterLeft();
                        }}
                    >{titleActionFooterLeft}</CButton>{' '}
                    <CButton
                        color={colorActionFooterRight}
                        onClick={(e) => {
                            toggle(e);
                            onClickActionFooterRight();
                        }}
                    >{titleActionFooterRight}</CButton>
                </CModalFooter> : <></>}
            </CModal>
        </>
    )
});

export default CustomDialog;


