function UserPermissionsFormatter(application, user) {
    let permission = {
        "permissions": {
            "application": {
                "controllers": application==null?{}:application
            },
            "users-permissions": {
                "controllers": user==null?{}:user
            }
        }
    }
    return permission;
}
export default UserPermissionsFormatter;