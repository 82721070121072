import React, { useEffect, useState } from "react";
import {
  CWidgetDropdown,
  CRow,
  CCol,
  CButton,
  CDataTable,
  CCard,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import ChartLineSimple from "../charts/ChartLineSimple";
import StaticProvider, { StaticContext } from "src/logic/context/StaticContext";
// @ts-ignore
import ReactExport from "react-export-excel";

import {
  convertCampaign,
  convertCouponList,
  convertTrigger,
  formatNumber,
} from "src/logic/service/Helper";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CouponStaticUI = () => {
  const staticCtx = React.useContext(StaticContext);
  const [enableCoupon, setEnableCoupon] = useState([]);
  const [inCoupon, setInCoupon] = useState([]);
  const [exCoupon, setExCoupon] = useState([]);
  const [inCampaign, setInCampaign] = useState([]);
  const [exCampaign, setExCampaign] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [couponTriggerList, setCouponTriggerList] = useState([]);
  const [triggerList, setTriggerList] = useState([]);

  const columnCoupon = [
    { key: "NO" },
    { key: "code", label: "Code" },
    { key: "totalAmount", label: "Total amount" },
    { key: "usedAmount", label: "Used amount" },
    { key: "leftAmount", label: "Left amount" },
  ];

  const columnCouponTable = [
    { key: "NO" },
    { key: "code", label: "Code" },
    { key: "start_date", label: "Start Date" },
    { key: "end_date", label: "End Date" },
  ];

  const columnCampaignTable = [
    { key: "NO" },
    { key: "name", label: "Campaign Name" },
    { key: "start_date", label: "Start Date" },
    { key: "end_date", label: "End Date" },
  ];

  const columnTrigger = [
    { key: "NO" },
    { key: "name", label: "Trigger name" },
    { key: "total", label: "Total used" },
  ];

  useEffect(() => {
    staticCtx.fetchCouponAllStatic();
    staticCtx.fetchCoupon();
    staticCtx.fetchCouponTrigger();
    staticCtx.fetchTrigger();
  }, []);

  useEffect(() => {
    let list = [];
    staticCtx.couponEnableList.export.forEach((value) => {
      list.push(convertCouponList(value));
    });
    setEnableCoupon(list);
  }, [staticCtx.couponEnableList.export]);

  useEffect(() => {
    let list = [];
    staticCtx.couponList.forEach((value) => {
      list.push(convertCouponList(value));
    });
    setCouponList(list);

    let listCouponTrigger = [];
    staticCtx.couponTriggerList.forEach((value) => {
      listCouponTrigger.push(convertCouponList(value));
    });
    setCouponTriggerList(listCouponTrigger);
  }, [staticCtx.couponList, staticCtx.couponTriggerList]);

  useEffect(() => {
    let list = [];
    staticCtx.couponInList.forEach((value) => {
      list.push(convertCouponList(value));
    });
    setInCoupon(list);

    let list1 = [];
    staticCtx.couponExList.forEach((value) => {
      list1.push(convertCouponList(value));
    });
    setExCoupon(list1);
  }, [staticCtx.couponInList, staticCtx.couponExList]);

  useEffect(() => {
    let list = [];
    staticCtx.campaignsInList.forEach((value) => {
      list.push(convertCampaign(value));
    });
    setInCampaign(list);

    let list1 = [];
    staticCtx.campaignsExList.forEach((value) => {
      list1.push(convertCampaign(value));
    });
    setExCampaign(list1);
  }, [staticCtx.campaignsInList, staticCtx.campaignsExList]);

  useEffect(() => {
    let list = [];
    staticCtx.triggerList.forEach((value) => {
      list.push(convertTrigger(value));
    });
    setTriggerList(list);
  }, [staticCtx.triggerList]);

  return (
    <CRow>
      <CCol lg="12">
        <CWidgetDropdown
          color="gradient-primary"
          header={staticCtx.couponEnableList.count.toString()}
          text="Coupon Enable"
          footerSlot={
            <ChartLineSimple
              className="mt-3"
              // @ts-ignore
              style={{ height: "70px" }}
              backgroundColor="rgba(255,255,255,.2)"
              dataPoints={[...staticCtx.couponEnableList.static]}
              options={{
                elements: { line: { borderWidth: 2.5, tension: 0.00001 } },
              }}
              pointHoverBackgroundColor="primary"
              label="new Coupon"
            />
          }
        >
          <ExcelFile
            filename={"All Enabled Coupons"}
            element={
              <CButton color="primary" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
            }
          >
            <ExcelSheet data={enableCoupon} name="test">
              <ExcelColumn
                label="ID"
                value={(col) => (col?.id ? col.id : "N/A")}
              />
              <ExcelColumn label="Coupon ID" value="id" />
              <ExcelColumn label="Coupon code" value="code" />
              <ExcelColumn label="Coupon condition" value="couponCondition" />
              <ExcelColumn
                label="Coupon discount type"
                value="couponDiscountType"
              />
              <ExcelColumn label="Oil type" value="oilTypes" />
              <ExcelColumn label="Minimum price" value="minPrice" />
              <ExcelColumn
                label="Amount of discount"
                value="amountOfDiscount"
              />
              <ExcelColumn label="Enable" value="enable" />
              <ExcelColumn label="Update At" value="updatedAt" />
              <ExcelColumn label="Created At" value="createdAt" />
            </ExcelSheet>
          </ExcelFile>
        </CWidgetDropdown>
      </CCol>

      <CCol lg="12">
        <CWidgetDropdown
          color="gradient-success"
          header={`Coupon Usage`}
          footerSlot={
            <CCol>
              <CCard>
                <CDataTable
                  items={couponList}
                  fields={columnCoupon}
                  hover
                  pagination
                  striped
                  scopedSlots={{
                    NO: (item, i) => <td>{i + 1}</td>,
                    Code: (item) => <td>{item.code}</td>,
                    totalAmount: (item) => (
                      <td>
                        {formatNumber(
                          Number(item.totalCoupon)
                            ? Number(item.totalCoupon)
                            : item.totalCoupon
                        )}
                      </td>
                    ),
                    usedAmount: (item) => (
                      <td>{formatNumber(Number(item.usedCoupon))}</td>
                    ),
                    leftAmount: (item) => (
                      <td>
                        {formatNumber(
                          Number(item.leftCoupon)
                            ? Number(item.leftCoupon)
                            : item.leftCoupon
                        )}
                      </td>
                    ),
                  }}
                />
              </CCard>
            </CCol>
          }
        >
          <ExcelFile
            filename={"Coupon Usage"}
            element={
              <CButton color="success" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
            }
          >
            <ExcelSheet data={couponList} name="test">
              <ExcelColumn
                label="ID"
                value={(col) => (col?.id ? col.id : "N/A")}
              />
              <ExcelColumn label="Coupon ID" value="id" />
              <ExcelColumn label="Coupon code" value="code" />
              <ExcelColumn label="Coupon condition" value="couponCondition" />
              <ExcelColumn
                label="Coupon discount type"
                value="couponDiscountType"
              />
              <ExcelColumn label="Oil type" value="oilTypes" />
              <ExcelColumn label="Minimum price" value="minPrice" />
              <ExcelColumn
                label="Amount of discount"
                value="amountOfDiscount"
              />
              <ExcelColumn
                label="Total coupon"
                value="totalCoupon"
              />
              <ExcelColumn
                label="Used coupon"
                value="usedCoupon"
              />
              <ExcelColumn
                label="Left coupon"
                value="leftCoupon"
              />
              <ExcelColumn label="Enable" value="enable" />
              <ExcelColumn label="Update At" value="updatedAt" />
              <ExcelColumn label="Created At" value="createdAt" />
            </ExcelSheet>
          </ExcelFile>
        </CWidgetDropdown>
      </CCol>


      <CCol lg="12">
        <CWidgetDropdown
          color="gradient-info"
          header={`Coupon Coming Soon`}
          footerSlot={
            <CCol>
              <CCard>
                <CDataTable
                  items={staticCtx.couponInList}
                  fields={columnCouponTable}
                  hover
                  pagination
                  striped
                  scopedSlots={{
                    NO: (item, i) => <td>{i + 1}</td>,
                    Code: (item) => <td>{item.code}</td>,
                    start_date: (item) => (
                      <td>{item.couponcondition.start_date.split("T")[0]}</td>
                    ),
                    end_date: (item) => (
                      <td>{item.couponcondition.end_date.split("T")[0]}</td>
                    ),
                  }}
                />
              </CCard>
            </CCol>
          }
        >
          <ExcelFile
            filename={"Coupon Coming Soon"}
            element={
              <CButton color="info" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
            }
          >
            <ExcelSheet data={inCoupon} name="test">
              <ExcelColumn
                label="ID"
                value={(col) => (col?.id ? col.id : "N/A")}
              />
              <ExcelColumn label="Coupon ID" value="id" />
              <ExcelColumn label="Coupon code" value="code" />
              <ExcelColumn label="Coupon condition" value="couponCondition" />
              <ExcelColumn
                label="Coupon discount type"
                value="couponDiscountType"
              />
              <ExcelColumn label="Oil type" value="oilTypes" />
              <ExcelColumn label="Minimum price" value="minPrice" />
              <ExcelColumn
                label="Amount of discount"
                value="amountOfDiscount"
              />
              <ExcelColumn label="Enable" value="enable" />
              <ExcelColumn label="Update At" value="updatedAt" />
              <ExcelColumn label="Created At" value="createdAt" />
            </ExcelSheet>
          </ExcelFile>
        </CWidgetDropdown>
      </CCol>

      <CCol lg="12">
        <CWidgetDropdown
          color="gradient-info"
          header={`Coupon Expire Soon`}
          footerSlot={
            <CCol>
              <CCard>
                <CDataTable
                  items={staticCtx.couponExList}
                  fields={columnCouponTable}
                  hover
                  pagination
                  striped
                  scopedSlots={{
                    NO: (item, i) => <td>{i + 1}</td>,
                    Code: (item) => <td>{item.code}</td>,
                    start_date: (item) => (
                      <td>{item.couponcondition.start_date.split("T")[0]}</td>
                    ),
                    end_date: (item) => (
                      <td>{item.couponcondition.end_date.split("T")[0]}</td>
                    ),
                  }}
                />
              </CCard>
            </CCol>
          }
        >
          <ExcelFile
            filename={"Coupon Expire Soon"}
            element={
              <CButton color="info" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
            }
          >
            <ExcelSheet data={exCoupon} name="test">
              <ExcelColumn
                label="ID"
                value={(col) => (col?.id ? col.id : "N/A")}
              />
              <ExcelColumn label="Coupon ID" value="id" />
              <ExcelColumn label="Coupon code" value="code" />
              <ExcelColumn label="Coupon condition" value="couponCondition" />
              <ExcelColumn
                label="Coupon discount type"
                value="couponDiscountType"
              />
              <ExcelColumn label="Oil type" value="oilTypes" />
              <ExcelColumn label="Minimum price" value="minPrice" />
              <ExcelColumn
                label="Amount of discount"
                value="amountOfDiscount"
              />
              <ExcelColumn label="Enable" value="enable" />
              <ExcelColumn label="Update At" value="updatedAt" />
              <ExcelColumn label="Created At" value="createdAt" />
            </ExcelSheet>
          </ExcelFile>
        </CWidgetDropdown>
      </CCol>

      <CCol lg="12">
        <CWidgetDropdown
          color="gradient-warning"
          header={`Campaign Coming Soon`}
          footerSlot={
            <CCol>
              <CCard>
                <CDataTable
                  items={staticCtx.campaignsInList}
                  fields={columnCampaignTable}
                  hover
                  pagination
                  striped
                  scopedSlots={{
                    NO: (item, i) => <td>{i + 1}</td>,
                    Code: (item) => <td>{item.code}</td>,
                    start_date: (item) => (
                      <td>{item.start_date.split("T")[0]}</td>
                    ),
                    end_date: (item) => <td>{item.end_date.split("T")[0]}</td>,
                  }}
                />
              </CCard>
            </CCol>
          }
        >
          <ExcelFile
            filename={"Campaign Coming Soon"}
            element={
              <CButton color="warning" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
            }
          >
            <ExcelSheet data={inCampaign} name="test">
              <ExcelColumn
                label="ID"
                value={(col) => (col?.id ? col.id : "N/A")}
              />
              <ExcelColumn label="Campaign ID" value="id" />
              <ExcelColumn label="Campaign Name" value="name" />
              <ExcelColumn label="Trigger" value="trigger" />
              <ExcelColumn label="Start Date" value="start_date" />
              <ExcelColumn label="End Date" value="end_date" />
              <ExcelColumn label="Enable" value="enable" />
            </ExcelSheet>
          </ExcelFile>
        </CWidgetDropdown>
      </CCol>

      <CCol lg="12">
        <CWidgetDropdown
          color="gradient-warning"
          header={`Campaign Expire Soon`}
          footerSlot={
            <CCol>
              <CCard>
                <CDataTable
                  items={staticCtx.campaignsExList}
                  fields={columnCampaignTable}
                  hover
                  pagination
                  striped
                  scopedSlots={{
                    NO: (item, i) => <td>{i + 1}</td>,
                    Code: (item) => <td>{item.code}</td>,
                    start_date: (item) => (
                      <td>{item.start_date.split("T")[0]}</td>
                    ),
                    end_date: (item) => <td>{item.end_date.split("T")[0]}</td>,
                  }}
                />
              </CCard>
            </CCol>
          }
        >
          <ExcelFile
            filename={"Campaign Expire Soon"}
            element={
              <CButton color="warning" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
            }
          >
            <ExcelSheet data={exCampaign} name="test">
              <ExcelColumn
                label="ID"
                value={(col) => (col?.id ? col.id : "N/A")}
              />
              <ExcelColumn label="Campaign ID" value="id" />
              <ExcelColumn label="Campaign Name" value="name" />
              <ExcelColumn label="Trigger" value="trigger" />
              <ExcelColumn label="Start Date" value="start_date" />
              <ExcelColumn label="End Date" value="end_date" />
              <ExcelColumn label="Enable" value="enable" />
            </ExcelSheet>
          </ExcelFile>
        </CWidgetDropdown>
      </CCol>

      <CCol lg="12">
        <CWidgetDropdown
          color="gradient-danger"
          header={`Trigger Usage`}
          footerSlot={
            <CCol>
              <CCard>
                <CDataTable
                  items={triggerList}
                  fields={columnTrigger}
                  hover
                  pagination
                  striped
                  scopedSlots={{
                    NO: (item, i) => <td>{i + 1}</td>,
                    name: (item) => <td>{item.name}</td>,
                    total: (item) => (
                      <td>
                        {formatNumber(
                          Number(item.total)
                        )}
                      </td>
                    ),
                  }}
                />
              </CCard>
            </CCol>
          }
        >
          <ExcelFile
            filename={"Trigger Usage"}
            element={
              <CButton color="danger" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
            }
          >
            <ExcelSheet data={triggerList} name="History">
              <ExcelColumn
                label="NO"
                value={(col, i) => triggerList.indexOf(col) + 1}
              />
              <ExcelColumn label="Trigger ID" value="id" />
              <ExcelColumn label="Trigger Name" value="name" />
              <ExcelColumn label="Coupon Code" value="coupon" />
              <ExcelColumn label="Minimum Price" value="min_price" />
              <ExcelColumn label="Minimum Booking" value="min_booking" />
              <ExcelColumn label="Minimum Litre" value="min_litre" />
              <ExcelColumn label="Total Used" value="total" />
            </ExcelSheet>
          </ExcelFile>
        </CWidgetDropdown>
      </CCol>

      <CCol lg="12">
        <CWidgetDropdown
          color="gradient-success"
          header={`Coupon Trigger Usage`}
          footerSlot={
            <CCol>
              <CCard>
                <CDataTable
                  items={couponTriggerList}
                  fields={columnCoupon}
                  hover
                  pagination
                  striped
                  scopedSlots={{
                    NO: (item, i) => <td>{i + 1}</td>,
                    Code: (item) => <td>{item.code}</td>,
                    totalAmount: (item) => (
                      <td>
                        {formatNumber(
                          Number(item.totalCoupon)
                            ? Number(item.totalCoupon)
                            : item.totalCoupon
                        )}
                      </td>
                    ),
                    usedAmount: (item) => (
                      <td>{formatNumber(Number(item.usedCoupon))}</td>
                    ),
                    leftAmount: (item) => (
                      <td>
                        {formatNumber(
                          Number(item.leftCoupon)
                            ? Number(item.leftCoupon)
                            : item.leftCoupon
                        )}
                      </td>
                    ),
                  }}
                />
              </CCard>
            </CCol>
          }
        >
          <ExcelFile
            filename={"Coupon Trigger Usage"}
            element={
              <CButton color="success" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
            }
          >
            <ExcelSheet data={couponTriggerList} name="test">
              <ExcelColumn
                label="ID"
                value={(col) => (col?.id ? col.id : "N/A")}
              />
              <ExcelColumn label="Coupon ID" value="id" />
              <ExcelColumn label="Coupon code" value="code" />
              <ExcelColumn label="Coupon condition" value="couponCondition" />
              <ExcelColumn
                label="Coupon discount type"
                value="couponDiscountType"
              />
              <ExcelColumn label="Oil type" value="oilTypes" />
              <ExcelColumn label="Minimum price" value="minPrice" />
              <ExcelColumn
                label="Amount of discount"
                value="amountOfDiscount"
              />
              <ExcelColumn
                label="Total coupon"
                value="totalCoupon"
              />
              <ExcelColumn
                label="Used coupon"
                value="usedCoupon"
              />
              <ExcelColumn
                label="Left coupon"
                value="leftCoupon"
              />
              <ExcelColumn label="Enable" value="enable" />
              <ExcelColumn label="Update At" value="updatedAt" />
              <ExcelColumn label="Created At" value="createdAt" />
            </ExcelSheet>
          </ExcelFile>
        </CWidgetDropdown>
      </CCol>
    </CRow>
  );
};

const CouponStatic = () => {
  return <StaticProvider>{<CouponStaticUI />}</StaticProvider>;
};

export default CouponStatic;
