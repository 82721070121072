export const USER_ITEM_PER_PAGE = 10;
export const config = {
  apiKey: "AIzaSyCk6impOpZk13R6Muoyd-Q818_10Ck_LTY",
  authDomain: "ntp-petrols.firebaseapp.com",
  databaseURL: 
    process.env.NODE_ENV === 'production' ?
      (process.env.REACT_APP_FIREBASE_DATABASE_URL || "https://ntp-petrols-default-rtdb.asia-southeast1.firebasedatabase.app") 
      : "https://ntp-petrols-dev-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ntp-petrols",
  storageBucket: "ntp-petrols.appspot.com",
  messagingSenderId: "639982968539",
  appId: "1:639982968539:web:7ecbc196c7df6d57d276fe"
};
export const WEB_PUSH_KEY = 'BL75jsfQcNS6xWBnIuoN_01wgTGSa0URaX1U5eO9v6Z3Awu63U_EanSjyfZriFeSppJpNsmlmnEgZC-Ls1Q0J9Q'
export const frequencyType = [
  { id: "DAY", name: "DAY" },
  { id: "WEEK", name: "WEEK" },
  { id: "MONTH", name: "MONTH" },
  { id: "YEAR", name: "YEAR" },
  { id: "ANY", name: "ANY" },
  { id: "FIRSTORDER", name: "FIRSTORDER" },
];
export const timeRange = [
  { id: "AM", name: "AM" },
  { id: "PM", name: "PM" },
  { id: "ANY", name: "ANY" },
];
export const dayRange = [
  { id: "WEEKDAY", name: "WEEKDAY" },
  { id: "WEEKEND", name: "WEEKEND" },
  { id: "ANY", name: "ANY" },
];
