import * as React from "react";
/** Utils */
import useStaticHandler from "../handler/StaticHandler";
export const StaticContext = React.createContext({
  bookings: {
    count: "",
    static: [],
    export: [],
  },
  users: {
    count: "",
    static: [],
    export: [],
  },
  riders: {
    count: "",
    static: [],
    export: [],
  },
  pricesInDay: {
    count: "",
    static: [],
    export: [],
  },
  pricesInWeek: {
    count: "",
    static: [],
    export: [],
  },
  pricesInMonth: {
    count: "",
    static: [],
    export: [],
  },
  incomeByType: {
    day: {
      cash: {
        amount: [], price: [],
      },
      banking: {
        amount: [], price: [],
      },
    },
    week: {
      cash: {
        amount: [], price: [],
      },
      banking: {
        amount: [], price: [],
      },
    },
    month: {
      cash: {
        amount: [], price: [],
      },
      banking: {
        amount: [], price: [],
      },
    },
    total: {
      cash: {
        amount: [], price: [],
      },
      banking: {
        amount: [], price: [],
      },
    },
  },
  fetAllStatic: () => { },
  fetchCouponAllStatic: () => { },
  couponEnableList: {
    count: "",
    static: [],
    export: [],
  },
  couponExList: [],
  couponInList: [],
  campaignsEnableList: {
    count: "",
    static: [],
    export: [],
  },
  campaignsExList: [],
  campaignsInList: [],
  couponList: [],
  couponTriggerList: [],
  fetchCoupon: () => {},
  fetchCouponTrigger: () => {},
  triggerList: [],
  fetchTrigger: () => {}
});

const { Provider } = StaticContext;

const StaticProvider = ({ children }) => {
  const {
    bookings,
    users,
    riders,
    pricesInDay,
    pricesInWeek,
    pricesInMonth,
    incomeByType,
    fetAllStatic,
    fetchCouponAllStatic,
    couponEnableList,
    couponExList,
    couponInList,
    campaignsEnableList,
    campaignsExList,
    campaignsInList,
    couponList,
    couponTriggerList,
    fetchCoupon,
    fetchCouponTrigger,
    triggerList,
  fetchTrigger

  } = useStaticHandler();
  return (
    <Provider
      value={{
        bookings,
        users,
        riders,
        pricesInDay,
        pricesInWeek,
        pricesInMonth,
        incomeByType,
        fetAllStatic,
        fetchCouponAllStatic,
        couponEnableList,
        couponExList,
        couponInList,
        campaignsEnableList,
        campaignsExList,
        campaignsInList,
        couponList,
        couponTriggerList,
        fetchCoupon,
        fetchCouponTrigger,
       triggerList,
  fetchTrigger
      }}
    >
      {children}
    </Provider>
  );
};

export default StaticProvider;
