import moment from "moment";

// This function seems to be universal so we will duplicate it and put it for admin side from strapi side
export const calculateDistance = (lat1, lng1, lat2, lng2) => {
  var R = 6371.071; // Radius of the Earth in kilometers
  var rlat1 = lat1 * (Math.PI / 180); // Convert degrees to radians
  var rlat2 = lat2 * (Math.PI / 180); // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (lng2 - lng1) * (Math.PI / 180); // Radian difference (longitudes)
  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );
  return d;
};
export function formatNumber(num) {
  return num.toLocaleString("it-IT", { maximumFractionDigits: 2 });
}
export function formatDate(text) {
  return moment(text).format("YYYY-MM-DD HH:MM:SS");
}

export function convertData(value) {
  const bookingModel = {
    id: "",
    createdAt: "",
    createdAtDate: "",
    customer: "",
    customer_number: "",
    enable: "",
    litre: "",
    prices: 0,
    amount_type: "",
    code: "",
    discount: 0,
    totalPrice: "",
    payment: "",
    oil_type: "",
    price: 0,
    province: "",
    rider: "",
    rider_number: "",
    status: "",
    plate: "",
    brand: "",
    color: "",
    create_by: "",
    detail: "",
    nid: "",
    service_charge: "",
  };
  bookingModel.id = value?.id ?? "N/A";
  bookingModel.createdAt = new Date(value?.createdAt).getTime().toString();
  bookingModel.createdAtDate = new Date(value?.createdAt).toLocaleString(
    "en-GB"
  );
  bookingModel.createdAtOnlyDate = new Date(
    value?.createdAt
  ).toLocaleDateString("en-GB");
  bookingModel.customer = value?.user?.username ?? "N/A";
  bookingModel.customer_number = value?.user?.phone ?? "N/A";
  bookingModel.enable = value?.user?.enable ?? "N/A";
  bookingModel.litre = value?.total_litre ?? "N/A";
  // bookingModel.prices = value?.amount ?? "N/A";
  bookingModel.prices =
    value?.amount_type?.name == "money"
      ? value.amount
      : value.amount * value?.oil?.price;
  bookingModel.amount_type = value?.amount_type?.name ?? "N/A";
  bookingModel.code = value?.coupons?.code ?? "N/A";
  bookingModel.discount = value?.discount_amount ?? 0;
  bookingModel.totalPrice = value?.total_price ?? "N/A";
  bookingModel.payment = value?.payment?.name ?? "N/A";
  bookingModel.oil_type = value?.oil?.oiltype?.name ?? "N/A";

  if (bookingModel.oil_type == "bensin") {
    bookingModel.oil_type = "Regular 91";
  } else if (bookingModel.oil_type == "Gasoline 95") {
    bookingModel.oil_type = "Super 95";
  } else if (bookingModel.oil_type == "diesel") {
    bookingModel.oil_type = "Diesel";
  }

  bookingModel.price = value?.price ?? "N/A";
  bookingModel.province = value?.oil?.province?.name ?? "N/A";
  bookingModel.rider = value?.rider?.username ?? "N/A";
  bookingModel.rider_number = value?.rider?.phone ?? "N/A";
  bookingModel.status = value?.status ?? "N/A";
  // bookingModel.rider_car_plate = value?.rider?.car?.plate ?? "N/A";
  bookingModel.plate = value?.car?.plate ?? "N/A";
  bookingModel.brand = value?.car?.brand ?? "N/A";
  bookingModel.color = value?.car?.color ?? "N/A";
  bookingModel.reason = value?.reason ?? "N/A";
  bookingModel.create_by = value?.assign_by?.username ?? "N/A";
  bookingModel.nid = value?.nid ?? "N/A";
  bookingModel.service_charge = value?.service_charge ?? 0;
  return bookingModel;
}

export function convertStockData(value) {
  const stcokModel = {
    id: "",
    amount: "",
    createdAt: "",
    createdAtDate: "",
    type: "",
    oil_type: "",
    rider: "",
    rider_number: "",
  };
  stcokModel.id = value?.id ?? "N/A";
  stcokModel.amount = value?.amount ?? "N/A";
  stcokModel.createdAt = new Date(value?.createdAt).getTime().toString();
  stcokModel.createdAtDate = new Date(value?.createdAt).toLocaleString("en-GB");
  stcokModel.type = value?.type ?? "N/A";
  stcokModel.oil_type = value?.oiltype?.name ?? "N/A";
  stcokModel.no_po = value?.no_po ?? "N/A";
  stcokModel.no_bill = value?.no_bill ?? "N/A";
  stcokModel.note = value?.note ?? "N/A";

  if (stcokModel.oil_type == "bensin") {
    stcokModel.oil_type = "Regular 91";
  } else if (stcokModel.oil_type == "Gasoline 95") {
    stcokModel.oil_type = "Super 95";
  } else if (stcokModel.oil_type == "diesel") {
    stcokModel.oil_type = "Diesel";
  }

  stcokModel.rider = value?.rider?.username ?? "N/A";
  stcokModel.rider_number = value?.rider?.phone ?? "N/A";
  stcokModel.date = new Date(value?.date).toLocaleString("en-GB");
  return stcokModel;
}

export function convertCouponData(value) {
  const couponModel = {
    id: "",
    createdAt: "",
    code: "",
    amount_of_discount: "",
    min_price: "",
    enable: false,
    coupon_condition: "",
    coupon_discount_type: "",
    couponcondition: {},
    coupondiscounttype: {},
    oiltypes: [],
  };
  couponModel.id = value?.id ?? "N/A";
  couponModel.createdAt =
    new Date(value?.createdAt).getTime().toString() ?? "N/A";
  couponModel.code = value?.code ?? "N/A";
  couponModel.amount_of_discount = value?.amount_of_discount ?? "N/A";
  couponModel.min_price = value?.min_price;
  couponModel.enable = value?.enable ?? false;
  couponModel.coupon_condition = value?.couponcondition ?? "N/A";
  couponModel.coupon_discount_type = value?.coupondiscounttype ?? "N/A";
  couponModel.couponcondition = value?.couponcondition ?? "N/A";
  couponModel.coupondiscounttype = value?.coupondiscounttype ?? "N/A";
  couponModel.oiltypes = value?.oiltypes ?? "N/A";

  if (couponModel.oiltypes == "bensin") {
    couponModel.oiltypes = "Regular 91";
  } else if (couponModel.oiltypes == "Gasoline 95") {
    couponModel.oiltypes = "Super 95";
  } else if (couponModel.oiltypes == "diesel") {
    couponModel.oiltypes = "Diesel";
  }

  return couponModel;
}

export function convertGroupList(value) {
  const groupModel = {
    id: "",
    group_name: "",
    createdAt: "",
  };
  groupModel.id = value?.id ?? "N/A";
  groupModel.createdAt = new Date(value?.createdAt).getTime().toString();
  groupModel.group_name = value?.group_name ?? "N/A";
  return groupModel;
}

export function convertConditionList(value) {
  const conditionModel = {
    id: "",
    name: "",
    amountOfCoupon: "",
    provinces: "",
    groups: "",
    startDate: "",
    endDate: "",
    dayRange: "",
    timeRanges: "",
    frequency: "",
    typeFrequency: "",
    createdAt: "",
  };
  var provinces = value?.provinces.map((item) => {
    return item["name"];
  });
  var groups = value?.usersgroups.map((item) => {
    return item["group_name"];
  });
  conditionModel.id = value?.id ?? "N/A";
  conditionModel.createdAt = new Date(value?.createdAt).getTime().toString();
  conditionModel.name = value?.name ?? "N/A";
  conditionModel.amountOfCoupon = value?.amount_of_coupon ?? "N/A";
  conditionModel.provinces = provinces.toString();
  conditionModel.groups = groups.toString();
  conditionModel.startDate = new Date(value?.start_date).toLocaleDateString();
  conditionModel.endDate = new Date(value?.end_date).toLocaleDateString();
  conditionModel.dayRange = value?.day_range ?? "N/A";
  conditionModel.frequency = value?.frequency ?? "N/A";
  conditionModel.timeRanges = value?.time_range ?? "N/A";
  conditionModel.typeFrequency = value?.type_frequency ?? "N/A";
  return conditionModel;
}

export function convertTemplateList(value) {
  const templateModel = {
    id: "",
    amountOfDiscount: "",
    couponCondition: "",
    couponDiscountType: "",
    minPrice: "",
    oilType: "",
    templateName: "",
  };
  var oil = value?.oiltypes.map((item) => {
    return item["name"];
  });
  templateModel.id = value?.id ?? "N/A";
  templateModel.templateName = value?.template_name ?? "N/A";
  templateModel.amountOfDiscount = value?.amount_of_discount ?? "N/A";
  templateModel.couponCondition = value?.couponcondition?.name ?? "N/A";
  templateModel.couponDiscountType = value?.coupondiscounttype?.type ?? "N/A";
  templateModel.minPrice = value?.min_price ?? "N/A";
  templateModel.oilType = oil.toString();

  if (templateModel.oiltypes == "bensin") {
    templateModel.oiltypes = "Regular 91";
  } else if (templateModel.oiltypes == "Gasoline 95") {
    templateModel.oiltypes = "Super 95";
  } else if (templateModel.oiltypes == "diesel") {
    templateModel.oiltypes = "Diesel";
  }

  return templateModel;
}

export function convertCouponList(value) {
  const couponModel = {
    id: "",
    code: "",
    amountOfDiscount: "",
    couponCondition: "",
    couponDiscountType: "",
    minPrice: "",
    oilTypes: "",
    totalCoupon: "",
    usedCoupon: 0,
    leftCoupon: "",
    enable: "",
    createdAt: "",
    published_at: "",
    updatedAt: "",
  };
  var oil = value?.oiltypes.map((item) => {
    return item["name"];
  });
  couponModel.id = value?.id ?? "N/A";
  couponModel.code = value?.code ?? "N/A";
  couponModel.amountOfDiscount = value?.amount_of_discount ?? "N/A";
  couponModel.couponCondition = value?.couponcondition.name ?? "N/A";
  couponModel.couponDiscountType = value?.coupondiscounttype?.type ?? "N/A";
  couponModel.minPrice = value?.min_price ?? "N/A";
  couponModel.oilTypes = oil.toString();
  couponModel.totalCoupon = value?.couponcondition?.amount_of_coupon ?? "N/A";
  couponModel.usedCoupon = value?.usedCoupon ?? 0;
  couponModel.leftCoupon =
    value?.couponcondition?.amount_of_coupon === "ANY"
      ? "ANY"
      : Number(
          Number(value?.couponcondition?.amount_of_coupon) - value?.usedCoupon
        ).toString();
  couponModel.enable = value?.enable ?? "N/A";
  couponModel.createdAt = new Date(value?.createdAt).toLocaleDateString();
  couponModel.published_at = new Date(value?.published_at).toLocaleDateString();
  couponModel.updatedAt = new Date(value?.updatedAt).toLocaleDateString();

  if (couponModel.oiltypes == "bensin") {
    couponModel.oiltypes = "Regular 91";
  } else if (couponModel.oiltypes == "Gasoline 95") {
    couponModel.oiltypes = "Super 95";
  } else if (couponModel.oiltypes == "diesel") {
    couponModel.oiltypes = "Diesel";
  }

  return couponModel;
}

export function convertCouponListWithCondition(value) {
  const couponModel = {
    id: "",
    code: "",
    couponCondition: {},
  };
  couponModel.id = value?.id ?? "N/A";
  couponModel.code = value?.code ?? "N/A";
  couponModel.couponCondition = value?.couponcondition ?? "N/A";

  return couponModel;
}

export function convertMember(value) {
  const joinGroupModel = {
    id: "",
    user_nid: "",
    user_user: "",
    user_email: "",
    user_phone: "",
  };
  joinGroupModel.id = value?.id ?? "N/A";
  joinGroupModel.user_nid = value?.users_permissions_user?.nid ?? "N/A";
  joinGroupModel.user_email = value?.users_permissions_user?.email ?? "N/A";
  joinGroupModel.user_user = value?.users_permissions_user?.username ?? "N/A";
  joinGroupModel.user_phone = value?.users_permissions_user?.phone ?? "N/A";
  return joinGroupModel;
}

export function convertUser(value) {
  const joinGroupModel = {
    id: "",
    nid: "",
    user: "",
    email: "",
    phone: "",
  };
  joinGroupModel.id = value?.id ?? "N/A";
  joinGroupModel.nid = value?.nid ?? "N/A";
  joinGroupModel.email = value?.email ?? "N/A";
  joinGroupModel.user = value?.username ?? "N/A";
  joinGroupModel.phone = value?.phone ?? "N/A";
  return joinGroupModel;
}

export function convertTrigger(value) {
  const triggerModel = {
    id: "",
    name: "",
    namelao: "",
    min_price: "",
    coupon_min_price: "",
    min_booking: "",
    min_litre: "",
    code: "",
    amount_of_discount: "",
    duration: "",
    coupondiscounttype: {},
    oiltypes: [],
    usergroups: [],
    total: 0,
    createdAt: "",
  };
  var oil = value?.oiltypes.map((item) => {
    return item["name"];
  });

  var group = value?.usergroups.map((item) => {
    return item["group_name"];
  });
  triggerModel.id = value?.id ?? "N/A";
  triggerModel.name = value?.name ?? "N/A";
  triggerModel.namelao = value?.namelao ?? "N/A";
  triggerModel.min_price = value?.min_price ?? "N/A";
  triggerModel.coupon_min_price = value?.coupon_min_price ?? "N/A";
  triggerModel.min_booking = value?.min_booking ?? "N/A";
  triggerModel.min_litre = value?.min_litre ?? "N/A";
  triggerModel.code = value?.code ?? "N/A";
  triggerModel.duration = value?.duration ?? "N/A";
  triggerModel.amount_of_discount = value?.amount_of_discount ?? "N/A";
  triggerModel.coupondiscounttype = value?.coupondiscounttype.type ?? "N/A";
  triggerModel.oiltypes = oil.toString();
  triggerModel.usergroups = group.toString();
  triggerModel.total = value?.total ?? 0;
  triggerModel.createAt = value?.createdAt ?? "N/A";

  if (triggerModel.oiltypes == "bensin") {
    triggerModel.oiltypes = "Regular 91";
  } else if (triggerModel.oiltypes == "Gasoline 95") {
    triggerModel.oiltypes = "Super 95";
  } else if (triggerModel.oiltypes == "diesel") {
    triggerModel.oiltypes = "Diesel";
  }

  return triggerModel;
}

export function convertCampaign(value) {
  const campaignModel = {
    id: "",
    name: "",
    namelao: "",
    coupon: "",
    start_date: "",
    end_date: "",
    enable: "",
  };
  campaignModel.id = value?.id ?? "N/A";
  campaignModel.name = value?.name ?? "N/A";
  campaignModel.namelao = value?.namelao ?? "N/A";
  campaignModel.coupon = value?.coupon?.code ?? "N/A";
  campaignModel.start_date = new Date(value?.start_date).toLocaleDateString();
  campaignModel.end_date = new Date(value?.end_date).toLocaleDateString();
  campaignModel.enable = value?.enable ?? "N/A";
  return campaignModel;
}

export function convertOiltype(value) {
  if (value == "bensin") {
    value = "Regular 91";
  } else if (value == "Gasoline 95") {
    value = "Super 95";
  } else if (value == "diesel") {
    value = "Diesel";
  } else {
    value = "N/A";
  }
  return value;
}
