import React, { useEffect } from "react";
import { GetMethodWithToken } from "../service/NetworkCall";
import { DataStatusContext } from "../../logic/context/DataStatusContext";
import { array } from "prop-types";
import moment from "moment";
import {
  convertCouponData,
  convertData,
  formatNumber,
} from "src/logic/service/Helper";

const timeStatus = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
};
const useStaticHandler = () => {
  const statisticModel = {
    count: "",
    static: [],
    export: [],
  };
  const amountPrice = { price: [], amount: [] };
  const [users, setUsers] = React.useState(statisticModel);
  const [riders, setRiders] = React.useState(statisticModel);
  const [bookings, setBookings] = React.useState(statisticModel);
  const [pricesInDay, setPricesInDay] = React.useState(statisticModel);
  const [pricesInWeek, setPricesInWeek] = React.useState(statisticModel);
  const [pricesInMonth, setPricesInMonth] = React.useState(statisticModel);

  const [incomeByType, setIncomeByType] = React.useState({
    day: {
      cash: { amount: [], price: [] },
      banking: { amount: [], price: [] },
    },
    week: {
      cash: { amount: [], price: [] },
      banking: { amount: [], price: [] },
    },
    month: {
      cash: { amount: [], price: [] },
      banking: { amount: [], price: [] },
    },
    total: {
      cash: { amount: [], price: [] },
      banking: { amount: [], price: [] },
    },
  });

  // coupon statuc
  const [couponList, setCouponList] = React.useState([]);
  const [couponTriggerList, setCouponTriggerList] = React.useState([]);
  const [couponEnableList, setCouponEnableList] =
    React.useState(statisticModel);
  const [couponExList, setCouponExList] = React.useState([]);
  const [couponInList, setCouponInList] = React.useState([]);

  const [campaignsEnableList, setCampaignsEnableList] =
    React.useState(statisticModel);
  const [campaignsExList, setCampaignsExList] = React.useState([]);
  const [campaignsInList, setCampaignsInList] = React.useState([]);
  const [triggerList, setTriggerList] = React.useState([]);

  const dataStatusCtx = React.useContext(DataStatusContext);

  async function fetAllStatic() {
    dataStatusCtx.setLoading(true);
    let resultUsers = await fetchUserStatic();
    let resultRiders = await fetchRiderStatic();
    let resultBookings = await fetchBookingStatic();
    let resultPricesInDay = await benefitAnalyze(timeStatus.DAY);
    let resultPricesInWeek = await benefitAnalyze(timeStatus.WEEK);
    let resultPricesInMonth = await benefitAnalyze(timeStatus.MONTH);
    let resultPricesAllTime = await totalBenefitAnalyze();

    setUsers(resultUsers);
    setBookings(resultBookings);
    setRiders(resultRiders);
    setPricesInDay({
      count: resultPricesInDay.count,
      export: resultPricesInDay.export,
      static: resultPricesInDay.static,
    });
    setPricesInWeek({
      count: resultPricesInWeek.count,
      export: resultPricesInWeek.export,
      static: resultPricesInWeek.static,
    });
    setPricesInMonth({
      count: resultPricesInMonth.count,
      export: resultPricesInMonth.export,
      static: resultPricesInMonth.static,
    });

    setIncomeByType({
      day: {
        cash: {
          amount: resultPricesInDay.listIncomeByCash.amount,
          price: resultPricesInDay.listIncomeByCash.price,
        },
        banking: {
          amount: resultPricesInDay.listIncomeByBanking.amount,
          price: resultPricesInDay.listIncomeByBanking.price,
        },
      },
      week: {
        cash: {
          amount: resultPricesInWeek.listIncomeByCash.amount,
          price: resultPricesInWeek.listIncomeByCash.price,
        },
        banking: {
          amount: resultPricesInWeek.listIncomeByBanking.amount,
          price: resultPricesInWeek.listIncomeByBanking.price,
        },
      },
      month: {
        cash: {
          amount: resultPricesInMonth.listIncomeByCash.amount,
          price: resultPricesInMonth.listIncomeByCash.price,
        },
        banking: {
          amount: resultPricesInMonth.listIncomeByBanking.amount,
          price: resultPricesInMonth.listIncomeByBanking.price,
        },
      },
      total: {
        cash: {
          amount: resultPricesAllTime.listIncomeByCash.amount,
          price: resultPricesAllTime.listIncomeByCash.price,
        },
        banking: {
          amount: resultPricesAllTime.listIncomeByBanking.amount,
          price: resultPricesAllTime.listIncomeByBanking.price,
        },
      },
    });
    dataStatusCtx.setLoading(false);
  }

  async function apiCallHandler(apicall) {
    try {
      let apiResponse = await apicall();
      return apiResponse;
    } catch (e) {
      dataStatusCtx.setIsError(true);
      if (e.response?.data.error != undefined) {
        dataStatusCtx.setPopupText(
          e.response == undefined
            ? "cant connect to server"
            : e.response?.data.error
        );
      } else {
        dataStatusCtx.setPopupText(
          e.response == undefined
            ? "cant connect to server"
            : e.response?.data?.data[0]?.messages[0]?.message
        );
      }
      dataStatusCtx.setLoading(false);
      dataStatusCtx.setShouldShowPopup(true);
      throw e;
    }
  }

  const fetchRiderStatic = async () => {
    return await dataAnalyzeToState(
      "/users?role.id=5ff53ab8edf90b05ae3f9520&_limit=-1",
      false,
      true
    );
  };
  const fetchUserStatic = async () => {
    return await dataAnalyzeToState(
      "/users?role.id=5ff40883784493127aa71ef4&_limit=-1",
      false,
      true
    );
  };
  const fetchBookingStatic = async () => {
    // var date = new Date();
    // date.setDate(date.getDate() - 7);
    // var n = date.toISOString();
    // return await dataAnalyzeToState("/bookings?createdAt_gte=" + n, true);
    return await dataAnalyzeToState(
      "/bookings?status=complete&status=walking&_limit=-1",
      true
    );
  };

  const benefitAnalyze = async (status) => {
    let time;
    if (status == timeStatus.DAY) {
      time = moment();
    } else if (status == timeStatus.WEEK) {
      time = moment().startOf("week");
    } else if (status == timeStatus.MONTH) {
      time = moment().clone().startOf("month");
    } else {
      time = moment();
    }
    time = time.format("YYYY-MM-DD");
    let response = await apiCallHandler(
      GetMethodWithToken.bind(
        this,
        `/bookings?status=complete&status=walking&_limit=-1&createdAt_gte=${time}`
        //`/bookings/getBookingByStatus/complete?createdAt_gte=${time}`
      )
    );

    let orderCount = response.data.length;

    let data = response.data;
    let excelData = [];
    let totalPrice;
    let totalAmount;
    let listPrices = [];
    let listAmount = [];
    let listIncomeByCash = {
      price: [],
      amount: [],
    };
    let listIncomeByBanking = {
      price: [],
      amount: [],
    };

    //group by time
    const groups = data.reduce((groups, user) => {
      const date = user.createdAt.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      excelData.push(convertData(user));
      groups[date].push(user);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        user: groups[date],
      };
    });

    groupArrays.forEach((value) => {
      value.user.forEach((element) => {
        listPrices.push(element?.total_price);
        listAmount.push(element?.total_litre);
        if (element.payment.name == "cash") {
          listIncomeByCash.price.push(element?.total_price);
          listIncomeByCash.amount.push(element?.total_litre);
        } else {
          listIncomeByBanking.price.push(element?.total_price);
          listIncomeByBanking.amount.push(element?.total_litre);
        }
      });
      // listTest.push(value)
    });


    totalPrice = listPrices.reduce(function (a, b) {
      return a + b;
    }, 0);

    totalAmount = listAmount.reduce(function (a, b) {
      return a + b;
    }, 0);

    return {
      count: totalPrice,
      static: listPrices,
      export: excelData,
      listIncomeByCash: listIncomeByCash,
      listIncomeByBanking: listIncomeByBanking,
    };
  };

  const totalBenefitAnalyze = async () => {
    let response = await apiCallHandler(
      GetMethodWithToken.bind(
        this,
        `/bookings?status=complete&status=walking&_limit=-1`
      )
    );

    let data = response.data;
    let totalPrice;
    let listPrices = [];
    let listAmount = [];
    let listIncomeByCash = {
      price: [],
      amount: [],
    };
    let listIncomeByBanking = {
      price: [],
      amount: [],
    };

    //group by time
    const groups = data.reduce((groups, user) => {
      const date = user.createdAt.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(user);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        user: groups[date],
      };
    });

    groupArrays.forEach((value) => {
      value.user.forEach((element) => {
        listPrices.push(element?.total_price);
        listAmount.push(element?.total_litre);
        if (element.payment.name == "cash") {
          if (element.total_litre == null) {
            // console.log(`order id: ${JSON.stringify(element)}`);
          }
          listIncomeByCash.price.push(element?.total_price);
          listIncomeByCash.amount.push(element?.total_litre);
        } else {
          listIncomeByBanking.price.push(element?.total_price);
          listIncomeByBanking.amount.push(element?.total_litre);
        }
      });
      // listTest.push(value)
    });
    // console.log(
    //   `benefit cash and banking total: ${JSON.stringify(
    //     listIncomeByCash
    //   )} / ${JSON.stringify(listIncomeByBanking)}`
    // );

    totalPrice = listPrices.reduce(function (a, b) {
      return a + b;
    }, 0);

    return {
      count: totalPrice,
      static: listPrices,
      export: data,
      listIncomeByCash: listIncomeByCash,
      listIncomeByBanking: listIncomeByBanking,
    };
  };

  const dataAnalyzeToState = async (endpoint, isBooking, isUser = false) => {
    let response = await apiCallHandler(
      GetMethodWithToken.bind(this, endpoint)
    );
    let userList = response.data.user?.filter((user) => user.enable == true);
    let listData;
    let count;
    if (isBooking) {
      count = response.data.length;
      listData = response.data;
    } else {
      count = userList.length ?? response.data.length;
      listData = userList ?? response.data.user;
    }

    let excelData = [];

    //group by time
    const groups = listData.reduce((groups, user) => {
      const date = user.createdAt.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      excelData.push(convertData(user));
      groups[date].push(user);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        user: groups[date],
      };
    });

    let listStaticData = [];
    groupArrays.forEach((value) => {
      listStaticData.push(value.user.length);
    });

    //return { count: count, export: groupArrays, static: listStaticData };
    return {
      count: count,
      export: isUser ? listData : isBooking ? excelData : groupArrays,
      static: listStaticData,
    };
  };

  // Coupon data static
  const fetchCoupon = async () => {
    //don't show generic conpon in static
    let couponList = await apiCallHandler(
      GetMethodWithToken.bind(this, `/coupons?_sort=updatedAt:DESC&couponcondition.id_ne=617a8508d863f81b3019cdfa`)
    );

    let validateNull = couponList.data.filter(data => data != null);
    couponList.data = validateNull;

    var i = 0;
    //need to refactor this shit, move to backend is better
    for (const value of couponList.data) {
      var usedCoupon = await apiCallHandler(GetMethodWithToken.bind(this, `/bookings/count?status=complete&coupons.code=` + value.code));
      couponList.data[i].usedCoupon = usedCoupon.data

      i++;
    }

    setCouponList(couponList.data);
  };

  const fetchCouponTrigger = async () => {
    //limited 100
    let couponTriggerList = await apiCallHandler(
      GetMethodWithToken.bind(this, `/coupons?_sort=updatedAt:DESC&couponcondition.id=617a8508d863f81b3019cdfa`)
    );
    
    // filter null data within strapi filter data
    let validateNull = couponTriggerList.data.filter(data => data != null);

    couponTriggerList.data = validateNull;

    var i = 0;
    //need to refactor this shit, move to backend is better
    for (const value of couponTriggerList.data) {
      var usedCoupon = await apiCallHandler(GetMethodWithToken.bind(this, `/bookings/count?status=complete&coupons.code=` + value.code));
      couponTriggerList.data[i].usedCoupon = usedCoupon.data
      i++;
    }

    setCouponTriggerList(couponTriggerList.data);
  };

  const fetchCouponAllStatic = async () => {
    dataStatusCtx.setLoading(true);
    let resultCouponEnable = await fetchCouponEnable();
    let resultCampaignEnale = await fetchCamapaingEnable();

    setCouponEnableList(resultCouponEnable);
    setCampaignsEnableList(resultCampaignEnale);

    dataStatusCtx.setLoading(false);
  };

  const fetchCouponEnable = async () => {
    const result = await couponDynamicStaticConvertor(`/coupons?enable=true&_limit=-1`);
    const listCoupon = result.export;

    let couponExpireList = [];
    let couponComingList = [];

    listCoupon.forEach((coupon) => {
      const now = new Date();
      const start_date = new Date(coupon?.couponcondition?.start_date);
      const end_date = new Date(coupon?.couponcondition?.end_date);

      if (now < start_date) {
        couponComingList.push(coupon);
      }

      if (now < end_date) {
        couponExpireList.push(coupon);
      }
    });

    setCouponExList(couponExpireList);
    setCouponInList(couponComingList);

    return result;
  };

  const fetchCamapaingEnable = async () => {
    const result = await couponDynamicStaticConvertor(`/campaigns?enable=true&_limit=-1`);
    const listcampaigns = result.export;

    let campaignsExpireList = [];
    let campaignsComingList = [];

    listcampaigns.forEach((campaign) => {
      const now = new Date();
      const start_date = new Date(campaign?.start_date);
      const end_date = new Date(campaign?.end_date);

      if (now < start_date) {
        campaignsComingList.push(campaign);
      }

      if (now < end_date) {
        campaignsExpireList.push(campaign);
      }
    });

    setCampaignsExList(campaignsExpireList);
    setCampaignsInList(campaignsComingList);

    return result;
  };

  const couponDynamicStaticConvertor = async (endpoint) => {
    let response = await apiCallHandler(
      GetMethodWithToken.bind(this, endpoint)
    );
    let count = response.data.length;
    let listData = response.data;

    //let excelData = [];

    //group by time
    const groups = listData.reduce((groups, coupon) => {
      const date = coupon.createdAt.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      //excelData.push(convertCouponData(coupon));
      groups[date].push(coupon);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        data: groups[date],
      };
    });

    let listStaticData = [];
    groupArrays.forEach((value) => {
      listStaticData.push(value.data.length);
    });

    return {
      count: count,
      export: listData,
      static: listStaticData,
    };
  };

  const fetchTrigger = async (param = "") => {
    const triggerResponse = await apiCallHandler(
      GetMethodWithToken.bind(this, `/triggers?_sort=updatedAt:DESC&_limit=10`)
    );

    var i = 0;
    var usedCoupon = await apiCallHandler(GetMethodWithToken.bind(this, `/usersstatics`));

    for (const value of triggerResponse.data) {
      var a = 0;
      usedCoupon.data.forEach(v => {
        if (v.triggers?.some(e => e.id === value.id)) {
          a++;
        }
      })
      triggerResponse.data[i].total = a
      i++;
    }
    setTriggerList(triggerResponse.data);
  };

  return {
    bookings,
    users,
    riders,
    pricesInDay,
    pricesInWeek,
    pricesInMonth,
    incomeByType,
    fetAllStatic,
    fetchCouponAllStatic,
    couponEnableList,
    couponExList,
    couponInList,
    campaignsEnableList,
    campaignsExList,
    campaignsInList,
    couponList,
    couponTriggerList,
    fetchCoupon,
    fetchCouponTrigger,
    triggerList, fetchTrigger
  };
};

export default useStaticHandler;
