
import React, { useEffect } from 'react'
import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CFormGroup, CFormText, CInput, CLabel, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import OilProvider, { OilContext } from 'src/logic/context/OilContext'
import { useLocation } from 'react-router-dom'
import { convertOiltype } from "src/logic/service/Helper";


const OilDetailUI = (match) => {
    const location = useLocation()
    const oilCtx = React.useContext(OilContext);
    const price = oilCtx.price;
    const isEditingOilData = oilCtx.isEditingOilData;
    const oilDetail = oilCtx.oilDetail;

    useEffect(() => {
        oilCtx.fetchOilDetail(match.match.match.params.id);
    }, []);
    useEffect(() => {
        // console.log(isEditUserCar);
    }, [isEditingOilData]);
    const toggleEditOilData = () => {
        oilCtx.setIsEditingOilData(!isEditingOilData);
    }

    return (
        <CRow>
            <CCol lg={6}>
                <CCard>
                    <CCardHeader>
                        Gas : <b>{convertOiltype(oilDetail?.oiltype?.name)}</b> <br />
                       Province : <b>{oilDetail?.province?.name}</b>
                    </CCardHeader>
                    <CCardBody>
                        <CForm action="" method="post">
                            <CFormGroup>
                                <CLabel htmlFor="nf-text">Price of gas</CLabel>
                                <CInput
                                    type="text"
                                    id="nf-text"
                                    name="nf-text"
                                    placeholder="Enter your price"
                                    defaultValue={oilDetail?.price}
                                    disabled={!isEditingOilData}
                                    onChange={(e) => oilCtx.setPrice(e.currentTarget.value)}
                                />
                                <CFormText className="help-block">Pleaser enter the price for your gas</CFormText>
                            </CFormGroup>
                        </CForm>
                    </CCardBody>
                    <CCardFooter>
                        {isEditingOilData ? <><CButton onClick={(e) => oilCtx.editOilDetail(match.params.id)} color="success">Save</CButton><CButton className="m-2" onClick={toggleEditOilData} color="danger">Cancel</CButton></> : <CButton onClick={toggleEditOilData} color="info">Edit</CButton>}
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow >
    )
}
const OilDetail = (match) => {
    return <OilProvider >
        {<OilDetailUI match={match} />}
    </OilProvider>
}
export default OilDetail
