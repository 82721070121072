import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { AuthenticatorContext } from './logic/context/AuthenticatorContext'
import Login from './views/pages/login/Login';
import Page404 from './views/pages/page404/Page404';
import Page500 from './views/pages/page500/Page500';
import TheLayout from './containers/TheLayout';
import "react-datepicker/dist/react-datepicker.css";
// Containers

// Pages
export default function App() {
  const authenticatorCtx = React.useContext(AuthenticatorContext);
  if (!authenticatorCtx.isSigned) {
    return <Login />
  }

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
        <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
      </Switch>
    </HashRouter>
  );
}

